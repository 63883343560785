import { Button, Col, Row } from "antd";
import React from "react";
import i18n from "../../../../language/i18n";
import H5 from "../../../base/text/h5";
import styles from './index.module.css';



function ModalFooter(props: { instructions: string , closeModal: any, email: string, email_body: string}) {


    return (
        <Row gutter={[0, 16]} >
            <Col style={{ width: "100%" }}>
                <Row gutter={[0, 16]} justify="end" >
                    <Col className={styles.buttonContainer}>
                        <Button className={styles.buttonCancel} key="back" onClick={props.closeModal}> {i18n.t("section.modal.cancel")} </Button>
                    </Col>
                    <Col className={styles.buttonContainer} >
                        <a href={`mailto:${props.email}?subject=Reserva&body=${props.email_body}`}>
                            <Button className={styles.buttonAccept} key="contact" > {i18n.t("section.modal.contact")} </Button>
                        </a>
                    </Col>
                </Row>

                {
                    props.instructions !== undefined && (

                        <>
                            <Row justify="start">
                                <div className= {styles.divider} />
                            </Row>
                            <Row justify="start">
                                <Col >
                                    <H5 className={styles.instructionsTitle}  >Instruções</H5>
                                </Col>
                            </Row>
                            <Row justify="start">
                                <Col>
                                    <div className={styles.instructions} dangerouslySetInnerHTML={{ __html: props.instructions }} />
                                </Col>
                            </Row>
                        </>
                    )
                }
            </Col>
        </Row>
    )
}

export default ModalFooter;
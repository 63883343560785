import { Col } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router";
import Paragraph from "../../../../base/text/paragraph";
import { OmniType } from "../../../../../model/omni/OmniType";
import Button from "../../../../base/button-v2";
import Section from "../core";
import styles from './index.module.css';
import i18n from "../../../../../language/i18n";

export type ItemProps = {
    items: any[]
    total: number
    query: string,
    defaultSize: number
}

function TeamSection({ total, items, query }: ItemProps) {
    const history = useHistory()

    const translateTeamType = (type?: string) => {
        switch (type) {
            case "practice":
                return i18n.t("omnisearch.results.text.practice");
            case "management":
                return i18n.t("omnisearch.results.text.management");
            case "governance":
                return i18n.t("omnisearch.results.text.governance");
            default:
                return "";
        }
    }

    const displayNoResultsMessage = total <= 5
    return (
        <Section
            type={OmniType.TEAMS}
            footer={(
                <Button className={styles.button} onClick={() => history.push(`/equipas`)}>
                    {i18n.t("omnisearch.results.text.goToTeams")}
                </Button>
            )}
            items={(
                <>
                    {items.map((item: any) => {
                        return (
                            <Col className={styles.teamItem} onClick={() => history.push(`/equipas?departamento=${item.name}`)}>
                                <Highlighter
                                    className={styles.teamItemTitle}
                                    highlightClassName={styles.highlight}
                                    searchWords={[query]}
                                    autoEscape={true}
                                    textToHighlight={item.name}
                                />
                                <Highlighter
                                    className={styles.teamItemDepartment}
                                    highlightClassName={styles.highlight}
                                    searchWords={[query]}
                                    autoEscape={true}
                                    textToHighlight={translateTeamType(item.type)}
                                />
                            </Col>
                        )
                    })}
                    {displayNoResultsMessage &&
                        <Paragraph className={styles.noResultsMessage}>{total === 0 ?
                            `${i18n.t("omnisearch.results.text.noResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inTeams")}` :
                            `${i18n.t("omnisearch.results.text.noMoreResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inTeams")}`}
                        </Paragraph>
                    }
                </>
            )}
        />
    )
}

export default TeamSection;
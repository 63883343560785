import { Department } from "../model/department/Department"
import { firestore } from "./persistence"

function mapCollections(snapshot: any) {
    return snapshot.docs.map((doc: any) => (
        {
            id: doc.id,
            data: doc.data()
        })
    )
}

export const departmentsManagementService = {
    getAll: async function (): Promise<Department[]> {
        const getDepartments = firestore.collection("departmentsManagement").get().then((snapshot) => {
            return mapCollections(snapshot)
        })

        let departments;

        try {
            departments = await getDepartments

        } catch (error) {
            console.log("error", error)
        }
        return departments
    }
}

export const departmentsPracticeService = {
    getAll: async function (): Promise<Department[]> {
        const getDepartments = firestore.collection("departmentsPractice").get().then((snapshot) => {
            return mapCollections(snapshot)
        })

        let departments;

        try {
            departments = await getDepartments

        } catch (error) {
            console.log("error", error)
        }
        return departments
    }
}

export const departmentsGovernanceService = {
    getAll: async function (): Promise<Department[]> {
        const getDepartments = firestore.collection("departmentsGovernance").get().then((snapshot) => {
            return mapCollections(snapshot)
        })

        let departments;

        try {
            departments = await getDepartments

        } catch (error) {
            console.log("error", error)
        }
        return departments
    }
}
import React from 'react';
import { ReactComponent as Link } from '../../../../assets/images/svg/link.svg';
import { IconProps } from '../interface';

function LinkIcon({ onClick, className }: IconProps) {
    return (
        <Link onClick={onClick} className={className} />
    )
}

export default LinkIcon;
import { Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../../base/pageTitle";
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.css';
import CategoryTitle from "../../../base/categoryTitle";
import i18n from "../../../../language/i18n";
import ReactPlayer from "react-player";
import ShareButton from "../../../base/shareButton";
import B2 from "../../../base/text/b2";
import { useWindowSize } from "../../../../hooks/useWindowSize";

interface IntroProps {
    title: string;
    content: { link: string, type?: string, embed?: string, thumbnail?: string };
    tag?: string;
    type?: string;
    updatedDate?: string;
    className?: string;
    id?: string;
}

export default function Intro({ className, title, type, tag, updatedDate, content, id }: IntroProps) {
    const [currentHeight, setCurrentHeight] = useState<number>(0)
    const divRef = useRef<HTMLDivElement | null>(null);
    const { width } = useWindowSize();

    // For screen rotation
    useEffect(() => {
        setCurrentHeight(divRef.current ? (divRef.current.offsetWidth / 16) * 9 : 0);
    }, [width])

    const translateType = (type?: string) => {
        return i18n.t(`post.type.${type}`);
    }

    const renderVideo = () => {
        if (content.type === "spotify") {
            return (
                <iframe
                    src={content.embed}
                    style={{ border: "none" }}
                    allowFullScreen
                    title={title}
                    className={styles.spotifyFrame}
                />

            )
        }

        if (content.type === "microsoft_stream") {
            return (
                <iframe
                    src={content.embed}
                    className={styles.msFrame}
                    style={{ border: "none", height: currentHeight }}
                    allowFullScreen
                    title={title}
                />
            )
        }

        if (content.type === "sharepoint") {
            return (
                <iframe
                    src={content.embed}
                    className={styles.msFrame}
                    style={{ border: "none", height: currentHeight }}
                    allowFullScreen
                    title={title}
                />
            )
        }


        return (
            <div className={styles.wrapper}>
                <ReactPlayer
                    className={styles.player}
                    url={content.link}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 1, controls: 1 }
                        },
                    }}
                    width={'100%'}
                    height={'100%'}
                />
            </div>
        )
    }

    const onClickShare = () => {
        const currentPath = window.location.host;
        navigator.clipboard.writeText(`${currentPath}/broadcast/${id}`);

        message.info({ content: (<B2>Link copiado com sucesso</B2>) });
    }


    return (
        <Row className={[styles.introContainer, className].join(" ")}>
            <Col ref={divRef} style={{ width: "100%" }}>
                <Row>
                    <Col>
                        <CategoryTitle>
                            {type === undefined ? tag : `${translateType(type)} | ${tag}`}
                        </CategoryTitle>
                    </Col>
                </Row>
            </Col>
            <Col style={{ width: "100%" }}>
                <Row>
                    <Col>
                        <PageTitle className={styles.title}>
                            {ReactHtmlParser(title || "")}
                        </PageTitle>
                    </Col>
                </Row>
            </Col>
            <Col style={{ width: "100%" }}>
                <Row align="middle" justify="space-between">
                    <Col>
                        <CategoryTitle className={styles.lastUpdate}>
                            {`${i18n.t("post.sections.intro.lastUpdate")}: ${updatedDate}`}
                        </CategoryTitle>
                    </Col>
                    <Col>
                        <ShareButton onClick={onClickShare} />
                    </Col>
                </Row>
            </Col>
            <Col className={styles.videoContainer}>
                {renderVideo()}
            </Col>
        </Row>
    )

}
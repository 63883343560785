import { useCallback, useEffect, useState } from "react";
import PartnershipService from "../services/partnershipService";
import { useDebouncedSearch } from "./useDebouncedSearch";

const defaultSize = 8;
export function usePartnerships(initialQuery?: string | null, initialCategories?: string[] | null) {
    const [data, setData] = useState([]);
    const [queriedPartnershipsTotal, setQueriedPartnershipsTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState<object | undefined>(undefined);
    const [discount, setDiscount] = useState<object | undefined>(undefined);
    const [categories, setCategories] = useState<Array<string>>(initialCategories || []);

    const getPartnerships = useCallback(async (query: string = "", from: number = 0, size: number = defaultSize, sort?: object, discount?: { greaterThanOrEqual: number, lessThanOrEqual: number } | undefined, categories: Array<string> = []) => {
        const criteria = {
            ...(query !== "" ? { query } : {}),
            ...(sort !== undefined ? { sort } : {}),
            ...(discount !== undefined && (discount.greaterThanOrEqual !== 0 || discount.lessThanOrEqual !== 100) ? { discount } : {}),
            ...(categories.length ? { categories } : {}),
            from,
            size
        }

        try {
            const response = await PartnershipService.getAll(criteria);
            if (queriedPartnershipsTotal === 0) {
                setQueriedPartnershipsTotal(response.data.total)
            }
            return response.data;
        } catch (e) {
            return { response: [] };
        }
    }, [queriedPartnershipsTotal]);


    const useGetPartnership = () => useDebouncedSearch(getPartnerships, defaultSize, initialQuery === null ? undefined : initialQuery, sort, discount, categories);

    const {
        setSize,
        query,
        setQuery,
        setFrom,
        searchResults
    } = useGetPartnership();

    useEffect(() => {
        setLoading(searchResults.loading)

        if (searchResults.result === undefined) {
            return
        }

        setData(searchResults.result.response)
    }, [searchResults])

    return {
        loading,
        data,
        setSize,
        categories,
        query,
        setSort,
        setQuery,
        setFrom,
        setDiscount,
        setCategories,
        queriedPartnershipsTotal,
        searchResults,
        defaultSize
    }
}
import React from 'react'
import { Button as ButtonAnt } from 'antd'

import styles from './index.module.css';

export interface ButtonProps {
    className?: string;
    filling?: 'default' | 'primary' | 'text' | 'none';
    type?: 'enabled' | 'danger' | 'disabled';
    children: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => any;
    icon?: React.ReactNode;
};

export default function Button({ className, filling = 'default', type, children, disabled, onClick, loading, icon }: ButtonProps) {

    function generateClasses() {
        const classes = [className, styles.button];

        if (!filling) filling = 'default';
        if (!type) type = 'enabled';

        switch (type) {
            case 'danger':
                getFilling(filling, classes, type)
                break;
            case 'disabled':
                if (filling === 'primary') classes.push(styles['primary-disabled'])
                else if (filling === 'text') classes.push(styles['text-disabled'])
                else classes.push(styles['default-disabled'])
                break;
            default:
                if (filling === 'primary') classes.push(styles['primary-enabled'])
                else if (filling === 'text') classes.push(styles['text-enabled'])
                else if (filling === 'default') classes.push(styles['default-enabled'])
                break;
        }

        return classes;
    };

    function getFilling(filling: string, classes: (string | undefined)[], type: string) {
        if (filling === 'primary') classes.push(styles[`primary-${type}`])
        else if (filling === 'text') classes.push(styles[`text-${type}`])
        else classes.push(styles[`default-${type}`])
    }


    return (
        <ButtonAnt
            className={generateClasses().join(' ')}
            disabled={disabled}
            onClick={onClick}
            loading={loading}
            icon={icon}
        >
            {children}
        </ButtonAnt>
    )
};
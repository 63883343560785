import React from 'react';
import { ReactComponent as Close } from '../../../../assets/images/svg/close.svg';

export interface IconProps {
    onClick?: () => any;
    className?: string;
}

function CloseIcon({ onClick, className }: IconProps) {
    return (
        <Close onClick={onClick} className={className} />
    )
}

export default CloseIcon;
import moment from "moment";
import { Event } from "../../model/event/event";
import { CalendarData } from "../../model/fullCalendarData/fullCalendarData";

export function eventsListMapper(eventsList: Event[]) {
    return eventsList.map((item: Event): CalendarData => {
        const isAllDay = !moment(item.endDate).startOf('day').isSame(moment(item.startDate).startOf('day'))
        return {
            title: item.title,
            start: item.startDate,
            end: item.endDate || item.startDate,
            allDay: isAllDay,
            groupId: item.tags && item.tags.length ? item.tags[0].color : ""
        }
    });
}
import React from 'react';
import { Modal as AntModal, ModalProps } from 'antd';
import styles from './index.module.css';
import CloseModalIcon from '../icon/closeModal';


export interface Props extends ModalProps {
    children: any;
}

function Modal(props: Props) {
    return (
        <AntModal
            {...props}
            closeIcon={<CloseModalIcon />}
            className={[styles.modal, props.className].join(" ")}
            wrapClassName={[styles.wrapper, props.wrapClassName].join(" ")}
        >
            {props.children}
        </AntModal>
    )
}

export default Modal;
import React from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.css';
import PageTitle from "../../../base/pageTitle";
import { ReactComponent as BackgroundLeftDesktop } from './../../../../assets/images/svg/backgrounds/cutouts/left2.svg'
import { ReactComponent as BackgroundRight } from './../../../../assets/images/svg/backgrounds/cutouts/right4.svg'
import i18n from '../../../../language/i18n';

function Intro(props: any) {

    return (
        <Row className={styles.intro}>
            <Col span={24} className={styles.introHeader}>
                <PageTitle>{i18n.t("omnisearch.results.text.title")}</PageTitle>
            </Col>
            <Col span={24} className={styles.iconContainer}>
                <BackgroundLeftDesktop />
                <BackgroundRight />
            </Col>
            <Col span={24}>
                {props.children}
            </Col>
        </Row>
    )
}

export default Intro;
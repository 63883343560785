import { Col, Row, message } from 'antd';
import React, { useContext, useState } from 'react'
import { useWindowSize } from '../../../../hooks/useWindowSize';
import i18n from '../../../../language/i18n';
import CardItem from '../../../base/cardItem';
import TickIcon from '../../../base/icon/tick';
import B2 from '../../../base/text/b2';
import H5 from '../../../base/text/h5';
import Overline from '../../../base/text/overline';
import { Event } from '../../../../model/event/event';
import { HighlightedEvent, isHighlightedEvent } from '../../../../model/highlightedEvent/highlightedEvent';
import PlaceholderPhoto from "../../../../assets/images/jpeg/placeholder.jpeg"
import Button from '../../../base/button-v2';

import styles from './index.module.css';
import { formatDate } from '../../../../helpers/agenda/formatEventDate';
import { getCategoryColor } from '../../../../helpers/agenda/getCategoryColor';
import { SubscribeEvent } from '../../../../helpers/subscribeEvent';
import AuthenticationContext from '../../../auth/authenticationContext';
import moment from 'moment';

export interface HighlightCardProps {
    event: HighlightedEvent | Event;
    isSubscribed: boolean | undefined | null;
    isPrivate: boolean;
    onEventClick?: (event: Event) => void;
    getColor?: (event: Event) => string;
    refreshEvents: () => void
}

function HighlightCard({ event, isSubscribed, isPrivate, onEventClick, getColor, refreshEvents }: HighlightCardProps) {
    const { width } = useWindowSize();
    const context = useContext(AuthenticationContext);
    const userId = context?.auth?.uid
    const [loadingSave, setLoadingSave] = useState<boolean>(false)


    async function subscribeEvent() {
        setLoadingSave(true)

        try {
            await SubscribeEvent(isHighlightedEvent(event) ? event.eventId : event.id, userId)
            await new Promise(resolve => setTimeout(resolve, 8000)).then(() => {
                refreshEvents();
            });
        } catch (error: any) {
            let messageString: string
            switch (error.status) {
                case 'error.event-request.unknown-error':
                default:
                    messageString = 'Ocorreu um erro, tente mais tarde.'
                    message.error({ content: (<B2>{messageString}</B2>) })
                    break;
            }
        }
        refreshEvents();
        setLoadingSave(false)
    }

    function renderCardBottom() {
        if (!isSubscribed && width >= 768 && !isPrivate && moment().isSameOrBefore(event.startDate)) {
            return (
                <Col className={styles.subscription} span={24}>
                    <Button filling="default" loading={loadingSave} onClick={() => subscribeEvent()}>{i18n.t("agenda.section.highlighted.subscribe")}</Button>
                </Col>
            )
        }

        if (isSubscribed) {
            return (
                <Col className={[styles.subscription, styles.userAdded].join(" ")} span={24}>
                    <div>
                        <TickIcon />
                    </div>
                    <Overline>{i18n.t("agenda.section.highlighted.subscribed")}</Overline>
                </Col>
            )
        }

        return undefined
    }

    return (
        <CardItem
            className={styles.card}
            useBackground
            cover={event.images && event.images.length > 0 ? event.images[0] : PlaceholderPhoto}
            coverClassName={styles.cover}
            onCoverClick={() => onEventClick && onEventClick(event)}

        >
            <Row className={styles.bodyContainer}>
                <div onClick={() => onEventClick && onEventClick(event)}>
                    <Col span={24} className={styles.infoContainer}>
                        <Row>
                            <Col className={styles.category}>
                                <div style={{ backgroundColor: getColor ? getColor(event) : getCategoryColor(event) }}></div>
                                <Overline>{event.tags[0].name}</Overline>
                            </Col>
                        </Row>
                        <H5>
                            {event.title}
                        </H5>
                        {event.subTitle && <B2>
                            {event.subTitle}
                        </B2>}
                        <B2>
                            {formatDate(event)}
                        </B2>
                    </Col>
                </div>
                {
                    isSubscribed &&
                    <Col className={styles.upperTick}>
                        <TickIcon />
                    </Col>
                }
                {
                    renderCardBottom()
                }
            </Row>
        </CardItem>
    )
}

export default HighlightCard;
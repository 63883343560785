import { Col } from "antd";
import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router";
import Paragraph from "../../../../base/text/paragraph";
import { OmniType } from "../../../../../model/omni/OmniType";
import Button from "../../../../base/button-v2";
import CategoryTitle from "../../../../base/categoryTitle";
import Section from "../core";
import styles from './index.module.css';
import i18n from "../../../../../language/i18n";

export type ItemProps = {
    items: any[]
    total: number
    query: string,
    defaultSize: number,
    loadNextPage: () => Promise<void>
    translateType: (type: string) => string
}

function OthersSection({ total, items, query, loadNextPage, translateType }: ItemProps) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)

    async function onNextPageClick() {
        setLoading(true)

        try {
            await loadNextPage()
        } catch (error) {
            console.log('error ', error)
        }

        setLoading(false)
    }

    const displayNoResultsMessage = total <= 5
    return (
        <Section
            type={OmniType.OTHERS}
            itemsContainerClassName={[loading && styles.loading].join(' ')}
            footer={(
                <>
                    {items.length < total &&
                        <Button className={styles.button} onClick={onNextPageClick} loading={loading}>
                            {i18n.t("omnisearch.results.text.seeMoreResults")}
                        </Button>
                    }
                </>
            )}
            items={(
                <>
                    {items.map((item: any) => {
                        const hasTag = item.tags && item.tags[0] && item.tags[0].name
                        return (
                            <Col className={styles.otherItem} onClick={() => history.push(`/post/${item.id}`)}>
                                <CategoryTitle className={styles.otherItemCategory}>{hasTag ? `${translateType(item.type)} | ${item.tags[0].name}` : translateType(item.type)}</CategoryTitle>
                                <Highlighter
                                    className={styles.otherItemTitle}
                                    highlightClassName={styles.highlight}
                                    searchWords={[query]}
                                    autoEscape={true}
                                    textToHighlight={item.title}
                                />
                            </Col>
                        )
                    })}
                    {displayNoResultsMessage &&
                        <Paragraph className={styles.noResultsMessage}>{total === 0 ?
                            `${i18n.t("omnisearch.results.text.noResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inOthers")}` :
                            `${i18n.t("omnisearch.results.text.noMoreResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inOthers")}`}
                        </Paragraph>
                    }
                </>
            )}
        />
    )
}

export default OthersSection;
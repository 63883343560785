import moment from "moment";
import { Notification } from "../../model/notification/notification";

export function formatEventDate(notification: Notification) {
    if (!notification) return;

    const startDate = moment(notification.postStartDate!.seconds * 1000);
    const startDateObject = {
        startWeekDay: startDate.format('ddd').split('-')[0],
        startMonthDay: startDate.format('DD/MM'),
        startTime: startDate.format('kk:mm')
    }
    const { startWeekDay, startMonthDay, startTime } = startDateObject;

    const endDate = moment(notification.postEndDate!.seconds * 1000);

    const startEqualsEnd = startDate.startOf('day').isSame(endDate.startOf('day'));

    if (!notification.postEndDate || startEqualsEnd) return `${startWeekDay}, ${startMonthDay} às ${startTime}`

    const endDateObject = {
        endWeekDay: endDate.format('ddd').split('-')[0],
        endMonthDay: endDate.format('DD/MM'),
    }

    const { endWeekDay, endMonthDay } = endDateObject;

    return `${startWeekDay}, ${startMonthDay} - ${endWeekDay}, ${endMonthDay}`
}
import React from 'react';
import { ReactComponent as Filter } from '../../../../assets/images/svg/filter.svg';
import { IconProps } from '../interface';

function FilterIcon({ onClick, className }: IconProps) {
    return (
        <Filter onClick={onClick} className={className} />
    )
}

export default FilterIcon;
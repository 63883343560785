import React, { useState } from 'react';
import { AuthUser } from '../../../model/auth/AuthUser';
import ProfilePictureService from '../../../services/profilePictureService';
import styles from './index.module.css';

export interface ProfileProps {
    onClick?: (e: any) => any;
    className?: string;
    user?: AuthUser;
}


const Profile: React.FC<ProfileProps> = (props: ProfileProps) => {
    const [profilePicture, setProfilePicture] = useState<string | undefined>(undefined);

    if (props.user) {
        ProfilePictureService.getProfilePicture(props.user).then(
            (value) => {
                setProfilePicture(value)
            }
        )
    }

    if (profilePicture) {
        return (
            <div className={[styles.profilePicture, props.className].join(" ")} onClick={props.onClick}>
                <img src={profilePicture} alt="profilePicture" height={32} width={32} />
            </div>
        )
    }

    if (props.user && props.user.auth) {
        const firstLetter = props.user.auth.displayName?.charAt(0)

        return (
            <div className={[styles.profile, props.className].join(" ")} onClick={props.onClick}>
                <span>{firstLetter}</span>
            </div>
        )
    }

    return <div />


}

export default Profile
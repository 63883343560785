import React, { useEffect, useState } from "react";
import PageContainer from "../../components/base/pageContainer";
import Intro from "../../components/sections/who-is-who/intro";
import styles from './index.module.css';
import SearchBar from "../../components/base/searchBar";
import SearchIcon from "../../components/base/icon/search";
import Pagination from "../../components/base/pagination";
import { useWindowSize } from "../../hooks/useWindowSize";
import UserFiltersService from "../../services/userFiltersService";
import Filters from "../../components/sections/who-is-who/filters";
import UserList from "../../components/sections/who-is-who/userList";
import { useUsers } from "../../hooks/useUsers";
import FiltersList, { FiltersObject } from "../../components/sections/who-is-who/filtersList";
import { useQueryString } from "../../hooks/useQueryString";
import { useLocation } from "react-router-dom";
import settingsService from "../../services/settingsService";
import cutout from '../../assets/images/svg/backgrounds/cutouts/home1.svg';
import WhoIsWhoService from "../../services/whoiswhoService";

export default function WhoIsWho() {
    const [filtersData, setFiltersData] = useState<any>(null);
    const [page, setPage] = useState(1)
    const [title, setTitle] = useState<string|undefined>(undefined)
    const [subTitle, setSubTitle] = useState<string|undefined>(undefined)
    const { isPhone } = useWindowSize();
    const { search } = useLocation()
    const initialQueryString = new URLSearchParams(search);
    const { query, setQuery, setSize, loading, defaultSize, queriedUsersTotal, data, resetTokens, nextPage, setCity, setDepartment, setJobTitle, city, jobTitle, department } = useUsers(initialQueryString.get('texto'))
    useQueryString(
        { name: "texto", query: query },
        { name: "area-de-pratica", query: department },
        { name: "estatuto", query: jobTitle },
        { name: "cidade", query: city },
    );

    useEffect(() => {

        getPageData()
        
        let mainSection = document.getElementById("mainSection");
        if(mainSection){
            mainSection.style.marginBottom = "0px";
        }

        UserFiltersService.getFilters().then((data) => {
            const queryString = new URLSearchParams(search);

            const cityQuery = queryString.get('cidade');
            const cityExists = data?.cities.find((item: any) => item.data.name === cityQuery);
            cityQuery && cityExists && setCity(cityQuery);

            const departmentQuery = queryString.get('area-de-pratica');
            const departmentExists = data?.departments?.find((item: any) => item.data.name === departmentQuery);
            departmentQuery && departmentExists && setDepartment(departmentQuery);

            const jobTitleQuery = queryString.get('estatuto');
            const jobTitleExists = data?.jobTitles.find((item: any) => item.data.name === jobTitleQuery);
            jobTitleQuery && jobTitleExists && setJobTitle(jobTitleQuery);

            getJobTitlesOrder().then((order: any[] | undefined) => {
                if (order) {
                    const orderedDepartments = data?.departments.sort(function (a: { data: { name: string; }; }, b: { data: { name: any; }; }) {
                        return a.data.name.localeCompare(b.data.name);
                    });

                    const orderedCities = data?.cities.sort(function (a: { data: { name: string; }; }, b: { data: { name: any; }; }) {
                        return a.data.name.localeCompare(b.data.name);
                    });

                    const orderedJobTitles = mapOrder(data?.jobTitles, order);
                    setFiltersData({
                        departments: orderedDepartments,
                        jobTitles: orderedJobTitles,
                        cities: orderedCities
                    })
                }
            });

        })
    }, [search, setCity, setDepartment, setJobTitle]);


    async function getPageData(){
        try {
            const data = await WhoIsWhoService.getWhoIsWho()
            setTitle(data?.title)
            setSubTitle(data?.subtitle)
        } catch (error) {
            console.log("error", error)
        }
    }


    async function getJobTitlesOrder() {
        try {
            const pluralJobTitlesOrder = await settingsService.getJobTitles()
            return pluralJobTitlesOrder;
        } catch (error) {
            console.log("error", error)
        }
    }

    function mapOrder(array: any[], order: any[]) {
        let ordered: any[] = [];
        let unordered: any[] = array;

        order.forEach((entry: string) => {
            unordered.forEach((item: any, index: number) => {
                if (item.data.name.toLowerCase().trim() === entry.toLowerCase().trim()) {
                    ordered.push(item);
                    unordered.splice(index, 1);
                }
            })
        })

        return ordered;
    }

    function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setPage(1)
        resetTokens()
        setSize(defaultSize)
        setQuery(value);

    }

    function handlePageChange(page: any) {
        nextPage(page)
        setPage(page)
    }

    function handleReachBottom() {
        setSize((prevSize: any) => prevSize === undefined ? defaultSize * 2 : prevSize * 2)
    }

    function updateCriteria(newFilters: FiltersObject, clearQuery: boolean) {
        setCity(newFilters.city)
        setDepartment(newFilters.department)
        setJobTitle(newFilters.jobTitle)
        setPage(1)
        resetTokens()
        clearQuery && setQuery("")
    }

    function updateJobTitle(jobTitle: string | undefined) {
        setJobTitle(jobTitle)
        setPage(1)
        resetTokens()
    }

    function updateCity(city: string | undefined) {
        setCity(city)
        setPage(1)
        resetTokens()
    }

    function updateDepartment(department: string | undefined) {
        setDepartment(department)
        setPage(1)
        resetTokens()
    }

    return (
        <div className={styles.background}>
            <PageContainer className={styles.container}>
                <img className={styles.cutout} src={cutout} alt="cutout" />
                <Intro className={styles.intro} title={title} subTitle={subTitle} />
                <SearchBar
                    className={styles.searchBar}
                    icon={<SearchIcon />}
                    value={query}
                    onChange={handleQueryChange}
                />
                <Filters
                    filtersData={filtersData}
                    className={styles.filtersContainer}
                    setJobTitle={updateJobTitle}
                    setCity={updateCity}
                    setDepartment={updateDepartment}
                    city={city}
                    jobTitle={jobTitle}
                    department={department}
                    disabled={loading}
                />
                <FiltersList
                    totalQueriedUsers={queriedUsersTotal}
                    selectedFilters={{ department, jobTitle, city }}
                    updateCriteria={updateCriteria}
                    query={query}
                    divider
                />
                <UserList users={data} loading={loading} departments={filtersData?.departments} />
                {queriedUsersTotal > 0 &&
                    <Pagination
                        disabled={data.length === 0 || loading}
                        pageSize={defaultSize}
                        onChange={handlePageChange}
                        total={queriedUsersTotal}
                        current={page}
                        onReachBottom={handleReachBottom}
                        hasMore={queriedUsersTotal === 0 ? true : queriedUsersTotal !== data.length}
                        phonePagination={isPhone}
                        className={styles.paginationWho}
                    />}
            </PageContainer>
        </div>
    )
}
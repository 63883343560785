import { App } from '../model/app/App';
import { KioskItem } from '../model/kioskItem/KioskItem';
import AppsService from './appsService';
import kioskItemsService from './kioskItemsService';
import { firestore } from './persistence';

const HomepageService = {
    
    getHomepage: async function () {
        const querySnapshot = await firestore.collection("pages_custom").doc("homepage").get();
        const homepageData = querySnapshot.data();

        if (homepageData !== undefined && Array.isArray(homepageData?.headerApps) && homepageData.headerApps.length > 0) {
            const headerApps = await Promise.all(homepageData.headerApps.map((app: App) => AppsService.get(app.id))) as Array<App>;

            Object.assign(homepageData, { headerApps: headerApps });
        }

        if (homepageData !== undefined && Array.isArray(homepageData?.homepageApps) && homepageData.homepageApps.length > 0) {
            const homepageApps = await Promise.all(homepageData.homepageApps.map((app: App) => AppsService.get(app.id))) as Array<App>;

            Object.assign(homepageData, { homepageApps: homepageApps });
        }

        if (homepageData !== undefined && Array.isArray(homepageData?.kioskItems) && homepageData.kioskItems.length > 0) {
            const kioskItems = await Promise.all(homepageData.kioskItems.map((app: App) => kioskItemsService.get(app.id))) as Array<KioskItem>;

            Object.assign(homepageData, { kioskItems: kioskItems });
        }

        return homepageData;
    },

}

export default HomepageService;
import React from 'react';
import { Card } from 'antd';
import styles from './index.module.css';
import { useRef } from 'react';

export interface CardItemProps {
    className?: string;
    coverClassName?: string;
    cover?: any;
    useBackground?: boolean
    children: any;
    bodyStyle?: object;
    setDefaultCardHeight?: any;
    onClick?: () => void;
    onCoverClick?: () => void;
}

function CardItem({ className, cover, children, bodyStyle, coverClassName, setDefaultCardHeight, useBackground, onClick, onCoverClick }: CardItemProps) {
    const imgRef = useRef<any>(null)
    const coverImage = cover && (useBackground ?
        <div
            className={[styles.cover, coverClassName].join(' ')}
            style={{ backgroundImage: `url(${cover})` }}
            onLoad={() => { setDefaultCardHeight && setDefaultCardHeight(imgRef) }}
            onClick={onCoverClick}
        /> :
        <img
            src={cover}
            alt="Cover"
            className={coverClassName}
            onLoad={() => { setDefaultCardHeight && setDefaultCardHeight(imgRef) }}
            ref={imgRef}
            onClick={onCoverClick}
        />
    );

    return (
        <Card
            className={[styles.card, className].join(" ")}
            cover={coverImage}
            bodyStyle={bodyStyle}
            onClick={onClick}
            bordered={false}
        >
            {children}
        </Card>
    )
}

export default CardItem;

import { Carousel, Col, Row } from "antd";
import React, { useState } from "react";
import styles from './index.module.css';
import H2 from "../../../base/text/h2";
import { ReactComponent as ArrowSmall } from '../../../../assets/images/svg/arrow-small.svg';
import H5 from "../../../base/text/h5";
import B2 from "../../../base/text/b2";
import ChevronLeft from "../../../base/icon/chevronLeft";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useHistory } from "react-router";

export type KnowledgeProps = {
    content: Array<object>
}

function Knowledge({ content }: KnowledgeProps) {
    const history = useHistory()
    const [currentSlide, setCurrentSlide] = useState(0);

    const { isPhone, isTablet } = useWindowSize();


    function renderHeader() {
        return (
            <Row className={styles.knowledgeHeader} align="middle">
                <Col><H2 className={styles.knowledgeHeaderTitle}>Knowledge</H2></Col>
            </Row>

        )
    }

    function renderContent() {
        const itemsLength = content.length;
        return (

            <Carousel
                className={styles.homepageCarousel}
                slidesToShow={isPhone ? 1 : isTablet ? 2 : 4}
                slidesToScroll={isPhone ? 1 : isTablet ? 2 : 4}
                infinite={false}
                arrows={true}
                dots={false}
                draggable={true}
                afterChange={(currentSlide: number) => setCurrentSlide(currentSlide)}
                nextArrow={(isTablet ? 1 + currentSlide !== itemsLength : 4 + currentSlide !== itemsLength) ? <div id={isTablet ? "mobileCarouselArrowRight" : "desktopCarouselArrowRight"}><ChevronLeft className={styles.arrowNext} /></div> : <div />}
                prevArrow={(currentSlide !== 0) ? <div id={isTablet ? "mobileCarouselArrowLeft" : "desktopCarouselArrowLeft"}><ChevronLeft className={styles.arrowPrev} /></div> : <div />}
            >
                {content.map((item: any) => (
                    <Col xs={24} md={22} lg={20} className={[styles.contentsContainer, styles.knowledgeContentLink].join(' ')} onClick={() => history.push({ pathname: item.link })}>
                        <Row align={"middle"} className={styles.knowledgeContentRow}>
                            <Col md={20} lg={20}>
                                <H5 className={styles.knowledgeContentTitle} title={item.title}>{item.title}</H5>
                            </Col>
                            <Col md={4} lg={4}>
                                <ArrowSmall className={styles.knowledgeTitleArrow} />
                            </Col>
                        </Row>
                        <B2 className={styles.knowledgeContentText}>{item.description}</B2>
                    </Col>
                ))}
            </Carousel>


        )
    }

    return (
        <Row className={styles.knowledgeContainer} >
            <div className={styles.background} />
            <Col className={styles.allContainer}>
                {renderHeader()}
                {renderContent()}
            </Col>
        </Row>
    )
}

export default Knowledge;

import React from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.css';
import B2 from '../../../base/text/b2';
import i18n from '../../../../language/i18n';
import { ReactComponent as BackgroundLeft } from './../../../../assets/images/svg/backgrounds/agenda/agenda-left.svg'
import { ReactComponent as BackgroundRight } from './../../../../assets/images/svg/backgrounds/agenda/agenda-right.svg'
import PageTitle from '../../../base/pageTitle';

interface IntroProps {
    title: string | undefined;
    subTitle: string | undefined;
    background: string | undefined;
    loading: boolean
}


function Intro({ title, subTitle, background, loading }: IntroProps) {
    return (
        <Row className={[styles.intro, loading ? styles.loading: ""].join(" ")}
            style={
                background === undefined
                    ? {}
                    : {
                        backgroundImage: `url(${background})`,
                    }
            }
        >
            <Col span={24}>
                <PageTitle>{title || i18n.t("agenda.section.intro.title")}</PageTitle>
            </Col>
            <Col span={24} className={styles.iconContainer}>
                <BackgroundLeft />
                <BackgroundRight />
            </Col>
            <Col span={24}>
                <B2 className={styles.description}>
                    {subTitle || i18n.t("agenda.section.intro.descrioption-1")}
                </B2>
                <B2 className={styles.description}>
                    {i18n.t("agenda.section.intro.descrioption-2")}
                </B2>
            </Col>
        </Row>
    )
}

export default Intro;
import { Row } from "antd";
import React from "react";
import H2 from "../text/h2";
import styles from './index.module.css';

export type SectionTitleProps = {
    className?: string;
    marginTop?: number;
    children?: any;
}

const SectionTitle: React.FC<SectionTitleProps> = (props: SectionTitleProps) => {
    let marginString;
    if(props.marginTop){
        marginString = props.marginTop + "px"
    } else{
        marginString = "80px"
    }
    
    return (
        <>
            <div className={styles.overline} style={{marginTop: marginString}}/>
            <Row className={styles.wrapper}>
                <H2 className={[styles.title, props.className].join(" ")}>{props.children}</H2>
            </Row>
        </>
    );
}

export default SectionTitle;
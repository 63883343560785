import React from 'react';
import { ReactComponent as Burger } from '../../../../assets/images/svg/homepage/burger.svg';
import { IconProps } from '../interface';

function BurgerIcon({ onClick, className }: IconProps) {
    return (
        <Burger onClick={onClick} className={className} />
    )
}

export default BurgerIcon;
import { firestore } from './persistence';

const JobTitleService = {

    getAll: async function () {
        const querySnapshot = await firestore.collection("job_titles").get()

        return querySnapshot.docs.map(snapshot => snapshot.data())
    },
}

export default JobTitleService;
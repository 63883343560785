import React from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.css';
import H1 from '../../../base/text/h1';
import B2 from '../../../base/text/b2';
import i18n from '../../../../language/i18n';

function Intro() {
    return (
        <Row className={styles.intro}>
            <Col span={24}>
                <H1>{i18n.t("categories.section.intro.title")}</H1>
            </Col>
            <Col span={24} className={styles.iconContainer}>
            </Col>
            <Col span={24}>
                <B2 className={styles.description}>{i18n.t("categories.section.intro.description")}</B2>
            </Col>
        </Row>
    )
}

export default Intro;
import React from "react";
import { List } from "antd";
import styles from './index.module.css';
import { App } from "../../../../model/app/App";

export interface AppListProps {
    apps?: Array<App>
}

const AppList: React.FC<AppListProps> = (props: AppListProps) => {
    const getApps = () => {
        return props.apps || [];
    }

    return (
        <List
            className={styles.listWrap}
            grid={{
                gutter: 10,
                lg: 3,
                xl: 3,
                xxl: 3,
            }}
            dataSource={getApps()}
            locale={{ emptyText: "Não há apps configuradas" }}
            renderItem={(item: any, index: number) => {
                return (
                    <List.Item
                        className={styles.cardContainer} style={{ borderTop: (index > 2) ? "1px solid #d5d5d5" : "unset", paddingTop: (index > 2) ? "25px" : "unset" }}>
                        <div onClick={() => window.open(item.link, '_blank')} className={styles.appItemWrap}>
                            <img src={item.cover} alt="app" className={styles.cover} />
                            <span>{item.title}</span>
                        </div>
                    </List.Item>
                )
            }}
        />
    )



}

export default AppList;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import styles from './index.module.css';
import Overline from '../../../../base/text/overline';
import i18n from '../../../../../language/i18n';
import H3 from '../../../../base/text/h3';
import CloseModalIcon from '../../../../base/icon/closeModal';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Tag } from '../../../../../model/tag/tag';
import { useHistory, useLocation } from 'react-router';

export interface EventTypeListProps {
    totalQueriedEvents: number;
    label: (e: CheckboxChangeEvent) => string | undefined;
    checkedList: Tag[];
    eventTypes: Tag[];
    setCheckedList: Dispatch<SetStateAction<Tag[]>>;
    setEventTypes: Dispatch<SetStateAction<Tag[]>>;
    clearFilters: () => void;
    className?: string;
    divider?: boolean;
    activeFilters: string[];
    setActiveFilters: Dispatch<SetStateAction<string[]>>;
}

function EventTypeList({ totalQueriedEvents, label, checkedList, eventTypes, setCheckedList, setEventTypes, clearFilters, className, divider, activeFilters, setActiveFilters }: EventTypeListProps) {
    const [showResults, setShowResults] = useState<boolean>(false)
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        totalQueriedEvents && setShowResults(true);
    }, [totalQueriedEvents])

    function getLabel() {
        if (!checkedList.length) return i18n.t("plus.section.filters.all");
        if (checkedList.length >= 1) {
            return label;
        }
    }

    function removeFilter(index: number) {
        const filteredCheckedList = checkedList.filter((item) => item.id !== checkedList[index].id)
        const filteredActiveFilters = activeFilters.filter((item) => item !== activeFilters[index])

        let queries = location.search.split("&")
        const queryText = queries.find((item) => item.includes("query"))?.split("?").join("") || ""

        history.push({
            pathname: "/agenda/resultados",
            search: filteredActiveFilters.length ? `filtros=${filteredActiveFilters.join(",")}&${queryText}` : queryText
        })

        setActiveFilters(filteredActiveFilters)
        setCheckedList(filteredCheckedList)

    }

    function renderLabels() {
        const activeeventType = eventTypes.filter((item: Tag) => {
            const tag = checkedList.find((o) => o.id === item.id)
            return tag ? true : false
        });

        const labels = activeeventType.map((item: Tag, index: number) => (
            <Col className={styles.labelContainer} onClick={() => removeFilter(index)} id={item.name} key={item.id} >
                <Overline className={styles.bar}>|</Overline>
                <Overline >{item.name} </Overline>
                <CloseModalIcon className={styles.removeIcon} />
            </Col>
        ))
        return labels
    }


    function renderClearAll() {
        if (checkedList.length) {
            return (
                <Col className={styles.labelContainer} onClick={clearFilters}>
                    <Overline className={styles.bar}>|</Overline>
                    <Overline>{i18n.t("plus.section.filters.clear")}</Overline>
                </Col>)
        }
    }

    return (
        <Row className={[styles.container, className].join(" ")}>
            <Col>
                <Row className={styles.mainLabel}>
                    <Col>
                        <H3 className={styles.mainLabelText}>{getLabel()}</H3>
                    </Col>
                </Row>
                <Row>
                    {
                        showResults && (
                            <Col className={styles.resultsContainer}>
                                <Overline>{totalQueriedEvents + " " + i18n.t("plus.section.filters.results")}</Overline>
                            </Col>)
                    }
                    {renderLabels()}
                    {renderClearAll()}
                </Row>
                {
                    divider ?
                        <div className={styles.divider} /> : undefined
                }
            </Col>
        </Row>
    )
}

export default EventTypeList;
import React from "react";
import { Col, Row } from "antd";
import styles from './index.module.css';
import H5 from "../../../../base/text/h5";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

export interface MenuWithLinksProps {
    data?: Array<any> 
}

export default function MenuWithLinks (props: MenuWithLinksProps) {
    const { isTablet } = useWindowSize();
    
        return (
            <Row className={styles.menuRow}>
                {props.data?.map((item, index) => {
                    return (
                        <Col onClick={() => window.open(item.link, "_blank")} className={styles.menuContainer} span={isTablet ? 24 : 7} key={index}>
                            <H5 className={styles.menuItem}>{item.text}</H5>
                        </Col>
                    )
                })}
            </Row>
        );
}
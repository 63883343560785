import React from "react";
import { Row, Col } from "antd";
import SectionTitle from "../sectionTitle";
import LinkIcon from "../../../base/icon/link";
import styles from './index.module.css';
import LinkTitle from "../linkTitle";
import i18n from "../../../../language/i18n";


export type UsefulLinksProps = {
    className?: string;
    data: Array<string>;
}

export default function UsefulLinks(props: UsefulLinksProps) {
    return (
        <Row gutter={[0, 24]} className={props.className}>
            <Col span={24}>
                <SectionTitle>{i18n.t("post.sections.links.title")}</SectionTitle>
                <Row gutter={[ 0,12 ]}>
                    <Col>
                        {props.data.map((link: string) => {
                            return (
                                <a href={link} target="_blank" rel="noreferrer" className={styles.link}>
                                    <Row gutter={[ 10,0 ]}> 
                                        <Col>
                                            <LinkIcon className={styles.icon} />
                                        </Col>
                                        <Col>
                                            <LinkTitle className={styles.linkText}> {link} </LinkTitle>
                                        </Col>
                                    </Row>
                                </a>
                            )
                        })}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
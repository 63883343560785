import { Row } from "antd";
import React from "react";
import { prependHttp } from "../../../../../helpers/prependHttp";
import styles from './index.module.css';

export interface ImageProps {
  data?: Array<any>;
}

export default function Image(props: ImageProps) {
  if (props.data === undefined || props.data.length === 0) {
    return null;
  }

  const data = props.data[0];
  
  return (
    data.src && (
      <Row className={styles.wrapper} style={{justifyContent:data.align}}>
        {data.link ?
        
          <a href={prependHttp(data.link)} target="_blank"rel="noopener noreferrer"><img alt={""} src={data.src} style={{width:data.width + "%"}}/></a>
        :
          <img alt={""} src={data.src} style={{width:data.width + "%"}}/>
        }
      </Row>
    )
  );
}

import React from 'react'
import { Typography } from 'antd';

import { textProps } from '../text-interface'
import styles from './index.module.css';

const { Title } = Typography

export default function H5({ children, className, style, onClick, title }: textProps) {

    function getClasses() {
        const classes = [styles.title, className || ''];
        return classes.join(' ')
    }

    return (
        <Title
            className={getClasses()}
            style={style}
            onClick={onClick}
            title={title}
        >
            {children}
        </Title>)
}
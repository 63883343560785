import { Event } from "../../model/event/event";

export function filterEvents(eventsByStart: Event[], eventsByEnd: Event[]): Event[] {
    const filteredEvents: Event[] = eventsByStart;

    for (const el of eventsByEnd) {
        const isDuplicate = filteredEvents.find((item) => el.id === item.id);
        if (!isDuplicate) filteredEvents.push(el);
    }

    return filteredEvents;
}
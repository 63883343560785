import React from "react";

export interface SpacingProps {
  data?: Array<any>;
}

export default function Spacing(props: SpacingProps) {
  if (props.data === undefined || props.data.length === 0) {
    return null;
  }

  const data = props.data[0];

  return (
    data.value && (
      <div style={{height:data.value + "px"}} />
    )
  );
}

import React, { useEffect, useState } from 'react'
import { Col, Row, Skeleton, Typography } from 'antd';
import H2 from '../../../base/text/h2';
import H5 from '../../../base/text/h5';
import Overline from '../../../base/text/overline';
import Button from '../../../base/button-v2';
import moment from 'moment';
import PostsService from '../../../../services/postsService';
import styles from './index.module.css';
import i18n from '../../../../language/i18n';
import { Link } from 'react-router-dom';
import Agenda from '../agenda';
import ChevronLeft from '../../../base/icon/chevronLeft';

function LatestNews() {
    const [data, setData] = useState<any[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true)

    function getPosts() {
        PostsService.getAll({
            sort: [{ field: 'creationDate', order: 'desc' }],
            types: ["news"],
            size: 4
        })
            .then(res => {
                const { response } = res.data;
                setData(response);
                setLoading(false);
            });
    }

    function formatTags(tags: any[]) {
        if (!tags || !tags[0]) {
            return null
        }

        return <span>{`${tags[0].name}`}</span>
    }

    useEffect(getPosts, []);

    if (loading) return <div style={{ marginTop: 80, marginBottom: 80 }}><Skeleton title paragraph /></div>;

    return (
        <Row className={styles.container}>
            <Col lg={13}>
                <Row style={{ marginBottom: "60px" }}>
                    <Col span={24} className={styles.subtitle}>
                        <Link className={"noDecoration"} to="/page/noticias">
                            <H2 className={styles.mainTitle}>{i18n.t("section.latest.title")}</H2>
                            <ChevronLeft className={styles.chevron} />
                        </Link>
                    </Col>
                </Row>
                <Row gutter={[{}, 30]} justify="space-between">
                    {data?.map(item => (
                        <Col span={24} md={11} className={styles.item}>
                            <Link className={"noDecoration"} to={"post/" + item.id}>
                                <Overline className={styles.overline}><span className={styles.faded}>{formatTags(item.tags)}</span></Overline>
                                <H5 className={styles.title}>{item.title}</H5>
                                <Typography.Paragraph 
                                    ellipsis={{rows:4, expandable:false }} 
                                    className={styles.itemBody}>
                                        {item.subTitle}
                                </Typography.Paragraph>
                                <Overline className={styles.faded}>{moment(item.creationDate).format("DD/MM/YYYY")}</Overline>
                            </Link>
                        </Col>
                    ))}
                </Row>
                <Row className={styles.buttonRow}>
                    <Col>
                        <Link to="/page/noticias">
                            <Button className={styles.button}>
                                {i18n.t("section.latest.button")}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Col>
            <Agenda />
        </Row>
    )
}

export default LatestNews;
import React, { Component } from 'react';
import { Redirect } from 'react-router';

import AuthenticationContext from '../authenticationContext';
import { Route } from '../route';

/**
 * The AnonymousRoute is one type of Route that checks if the user is not authenticated. Only
 * in that specific situation this route will allow the processing to continue. If the user IS
 * already authenticated, then a redirect to the route path will have to be performed.
 */
export class AnonymousRoute extends Component<any> {
    render() {
        if (!this.context) {
            return null;
        }

        if (this.context.auth && !this.context.auth.isAnonymous) {
            return <Redirect to={{ pathname: "/" }} />
        }

        return (
            <Route {...this.props} />
        );
    }
}

AnonymousRoute.contextType = AuthenticationContext;
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Col, Divider, Dropdown, Radio, RadioChangeEvent, Row } from 'antd';
import i18n from '../../../../language/i18n';
import styles from './index.module.css';
import { Event } from '../../../../model/event/event';
import Overline from '../../../base/text/overline';
import { formatEventListDate } from '../../../../helpers/agenda/formatEventListDate';
import moment from 'moment';
import Button from '../../../base/button-v2';
import ChevronDown from '../../../base/icon/chevronDown';
import { Dictionary } from 'underscore';
import { Tag } from '../../../../model/tag/tag';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import Spinner from '../../../base/spinner';
import EventService from '../../../../services/eventService';
import CalendarMonth from '../calendarMonth';
import CalendarWeek from '../calendarWeek';
import { CalendarData } from '../../../../model/fullCalendarData/fullCalendarData';

import { DatesSetArg } from '@fullcalendar/react'
import EventSmallCard from '../../../base/eventSmallCard';
import { eventsListMapper } from '../../../../helpers/agenda/eventsListMapper';
import { groupByDay } from '../../../../helpers/agenda/groupByDay';
import B1 from '../../../base/text/b1';
import Legend from '../legend';

interface AllEventsProps {
    isSubscribed: (event: Event | null) => boolean | null | undefined;
    onEventClick: (event: Event) => void;
    handleFilterClick: (event: RadioChangeEvent) => void;
    activeFilter: Tag | null;
    data: Dictionary<Event[]> | null;
    filterOptions: Tag[] | undefined;
    handleSeeMore: () => void;
    totalQueried: number;
    size: number;
    handleVisibleChange: (flag: boolean) => void;
    dropdownVisible: boolean;
    seeMoreLoading: boolean;
    loading: boolean;
    onMonthChange: (start: string, end: string) => void;
    onViewChange: (arg: "week" | "month") => void;
    eventsByDaySetter: Dispatch<SetStateAction<null | Dictionary<Event[]>>>;
    setStartDate?: Dispatch<SetStateAction<string>>;
    setEndDate?: Dispatch<SetStateAction<string>>;
    startDateCalendar?: string
    setTotalQueried?: Dispatch<SetStateAction<number>>;
    startDate?: string
    endDate?: string
}

function AllEvents({
    isSubscribed,
    onEventClick,
    handleFilterClick,
    activeFilter,
    data,
    filterOptions,
    handleSeeMore,
    totalQueried,
    size,
    handleVisibleChange,
    dropdownVisible,
    seeMoreLoading,
    loading,
    onMonthChange,
    onViewChange,
    eventsByDaySetter,
    setStartDate,
    setEndDate,
    startDateCalendar,
    setTotalQueried,
    startDate,
    endDate
}: AllEventsProps) {
    const { isPhone } = useWindowSize();
    const [calendarView, setCalendarView] = useState<"week" | "month">("month");
    const [viewMenuVisible, setViewMenuVisible] = useState<boolean>(false);
    const [calendarData, setCalendarData] = useState<CalendarData[] | null>(null);
    const [calendarDataMonth, setCalendarDataMonth] = useState<CalendarData[] | null>(null);
    const [requestSize, setRequestSize] = useState<number>(50);
    const [calendarLoading, setCalendarLoading] = useState<boolean>(false);


    useEffect(() => {
        EventService.getAll({
            size: requestSize,
            sort: [
                {
                    field: 'startDate',
                    order: 'asc',
                }],
            range: [
                {
                    field: 'startDate',
                    range: "gte",
                    value: startDate!
                },
                {
                    field: 'startDate',
                    range: "lte",
                    value: endDate!
                },
            ],
            tags: activeFilter ? [activeFilter.id] : [],
        }).then((res) => {
            const { response, total } = res.data;
            if (total > requestSize) {
                setRequestSize(total);
                return;
            }

            const events = eventsListMapper(response);
            const eventsMonth = handleMonthCalendar(events);
            setCalendarData(events)
            setCalendarDataMonth(eventsMonth)
        })
    }, [requestSize, activeFilter, startDate, endDate]);

    const eventsKeys = data && Object.keys(data).sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    });

    function handleViewClick(e: RadioChangeEvent) {
        const { value } = e.target;
        setCalendarView(value)
        setViewMenuVisible(false);
        onViewChange(value);
    }

    function handleViewMenuVisibleChange(flag: boolean) {
        setViewMenuVisible(flag);
    }

    const filterMenu = (
        <Radio.Group className={styles.radioGroup} defaultValue={1} onChange={handleFilterClick}>
            <Radio >{i18n.t('agenda.section.categoryFilter.all')}</Radio>
            {filterOptions?.map((item: Tag, index: number) => {
                return <Radio value={item} key={index} >{item.name}</Radio>
            })}
        </Radio.Group>
    )

    const viewMenu = (
        <Radio.Group className={styles.radioGroup} defaultValue="dayGridMonth" onChange={handleViewClick}>
            <Radio value="week">{i18n.t('agenda.section.calendar.week')}</Radio>
            <Radio value="month">{i18n.t('agenda.section.calendar.month')}</Radio>
        </Radio.Group>
    )

    function renderSeeMoreButton() {
        if (size < totalQueried) {
            return (
                <Col className={styles.seeMore} span={24}>
                    {
                        !seeMoreLoading ?
                            <Button onClick={handleSeeMore}>{i18n.t("agenda.section.upcoming.seeMore")}</Button> :
                            <Spinner />
                    }
                </Col>
            )
        }

        return (isPhone && totalQueried > 0 &&
            <Col className={styles.listEnd} span={24}>
                {
                    seeMoreLoading &&
                        <Spinner />
                }
            </Col>
        )
    }

    function renderNoResults() {
        return (
            <Col className={styles.noResults}>
                <div >
                    <B1>{i18n.t("agenda.section.calendar.noResults")}</B1>
                </div>
            </Col>
        )
    }

    async function handleRangeChange(range: DatesSetArg) {
        setCalendarLoading(true);
        setCalendarData([])
        const { startStr, endStr } = range;

        calendarView === 'month' && onMonthChange(startStr, endStr);

        const startDate = moment(startStr).toISOString();
        const endDate = moment(endStr).toISOString();

        setStartDate && setStartDate(startDate);
        setEndDate && setEndDate(endDate);

        const getEventsByStart = EventService.getAll({
            size: 100,
            sort: [
                {
                    field: 'startDate',
                    order: 'asc',
                }],
            range: [
                {
                    field: 'startDate',
                    range: "gte",
                    value: startDate
                },
                {
                    field: 'startDate',
                    range: "lte",
                    value: endDate
                },
            ],
            tags: activeFilter ? [activeFilter.id] : [],
        });

        const getEventsByEnd = EventService.getAll({
            size: 100,
            sort: [
                {
                    field: 'startDate',
                    order: 'asc',
                }],
            range: [
                {
                    field: 'endDate',
                    range: "gte",
                    value: startDate
                },
                {
                    field: 'endDate',
                    range: "lte",
                    value: endDate
                },
                {
                    field: 'startDate',
                    range: "lt",
                    value: startDate
                },
            ],
            tags: activeFilter ? [activeFilter.id] : [],
        });

        Promise.all([getEventsByStart, getEventsByEnd])
            .then((res) => {
                const [eventsByStart, eventsByEnd] = res;

                const joinedEventsArr = eventsByStart.data.response.concat(eventsByEnd.data.response)
                const total = eventsByStart.data.total + eventsByEnd.data.total;

                const events = eventsListMapper(joinedEventsArr);
                const eventsMonth = handleMonthCalendar(events);
                setCalendarData(events);
                setCalendarDataMonth(eventsMonth);

                setTotalQueried && setTotalQueried(total)
                setCalendarLoading(false);
                if (calendarView === "week") {
                    const groupedByDay = groupByDay(eventsByStart.data.response);
                    eventsByDaySetter(groupedByDay);
                }
            });
    }

    function handleMonthCalendar(calendarData: CalendarData[] | null) {
        if (calendarData === null) {
            return [] as CalendarData[];
        }

        if (calendarData.length === 0) {
            return [] as CalendarData[];
        }

        const monthData: CalendarData[] = [];
        for (var i = 0; i < calendarData.length; i++) {
            const event = calendarData[i]

            const isMultipleDays = !(moment(event.end).startOf('day').isSame(moment(event.start).startOf('day')));
            const date = moment(event.end).clone().add(1, 'days').startOf('day').toDate();

            if (isMultipleDays) {
                event.end = date;
            }

            monthData.push(event);
        }

        return monthData;
    }

    return (
        <Row className={styles.container}>
            <Col xs={24} md={11} lg={8}>
            <Col>
                <Dropdown
                    overlay={filterMenu}
                    overlayClassName={styles.overlay}
                    className={styles.dropdown}
                    trigger={['click']}
                    onVisibleChange={handleVisibleChange}
                    visible={dropdownVisible}
                >
                    <div>
                        <span className={styles.dropdownTitle}>{activeFilter ? activeFilter.name : i18n.t('agenda.section.categoryFilter.all')}</span>
                        <ChevronDown />
                    </div>
                </Dropdown>
            </Col>
            <Col className={[styles.eventsList, loading || seeMoreLoading ? styles.containerLoading : ""].join(" ")} >
                {eventsKeys?.length ? eventsKeys.map((day: string) => {
                    return (
                        <div className={styles.dayContainer} key={day}>
                            <Overline>{formatEventListDate(day)}</Overline>
                            {data && data[day].map((event: Event) => {
                                return (
                                    <EventSmallCard
                                        event={event}
                                        userSubscribed={isSubscribed(event)}
                                        key={event.id}
                                        onClick={() => onEventClick(event)}
                                    />
                                )
                            })}
                        </div>
                    )
                }) :
                    renderNoResults()
                }
                {renderSeeMoreButton()}
            </Col>
            {!isPhone &&
                <Col className={styles.divider}>
                    <Divider />
                </Col>
            }
            </Col> 
            <Col xs={24} md={12} lg={15} offset={isPhone ? 0 : 1} >
                <Col className={styles.calendarMenu}>
                <Dropdown
                    overlay={viewMenu}
                    overlayClassName={styles.overlay}
                    trigger={['click']}
                    onVisibleChange={handleViewMenuVisibleChange}
                    visible={viewMenuVisible}
                >
                    <div>
                        <span className={styles.dropdownTitle}>{calendarView === 'month' ? i18n.t('agenda.section.calendar.month') : i18n.t('agenda.section.calendar.week')}</span>
                        <ChevronDown />
                    </div>
                </Dropdown>
                </Col>

            <Col className={styles.calendarContainer}>
                {
                    calendarData && calendarView === 'month' &&
                    <CalendarMonth
                        calendarData={calendarDataMonth}
                        handleMonthChange={handleRangeChange}
                        isLoading={calendarLoading}
                    />
                }
                {
                    calendarData && calendarView === 'week' &&
                    <CalendarWeek
                        calendarData={calendarData}
                        handleWeekChange={handleRangeChange}
                        isLoading={calendarLoading}
                    />
                }
            </Col>
            <Legend eventTags={filterOptions!}/>
            </Col>
        </Row>

    )
}

export default AllEvents;

import { Col, Input, List, message, Row, Tooltip } from "antd";
import React, { Fragment, useContext, useState } from "react";
import { useQueryString } from "../../../../hooks/useQueryString";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import i18n from "../../../../language/i18n";
import { NotificationAction } from "../../../../model/notification/NotificationAction";
import { Tag } from "../../../../model/tag/tag";
import NotificationsService from "../../../../services/NotificationService";
import AuthenticationContext from "../../../auth/authenticationContext";
import NotificationIcon from "../../../base/icon/notification";
import SearchIcon from "../../../base/icon/search";
import Pagination from "../../../base/pagination";
import B2 from "../../../base/text/b2";
import Overline from "../../../base/text/overline";
import styles from './index.module.css';

export interface CategoriesListProps {
    categoriesData: any
    subscriptions: string[] | undefined
    refreshSubscriptions: () => Promise<void>
}

export default function CategoriesList({ categoriesData, subscriptions, refreshSubscriptions }: CategoriesListProps) {
    const [page, setPage] = useState(1)
    const context = useContext(AuthenticationContext);
    const [loadingSubscribe, setLoadingSubscribe] = useState(false)
    const { isPhone } = useWindowSize();
    const {
        loading,
        data,
        setSize,
        query,
        setQuery,
        setFrom,
        total,
        searchResults,
        defaultSize,
    } = categoriesData;
    useQueryString({ name: "texto", query: query });

    function handlePageChange(page: number) {
        setPage(page)
        setFrom(defaultSize * (page - 1))
    }

    function handleReachBottom() {
        setSize((prevSize: any) => prevSize === undefined ? defaultSize * 2 : prevSize * 2)
    }

    async function onCategoryClick(tag: Tag, action: NotificationAction) {

        if (context === null || context.auth === null) {
            return
        }

        setLoadingSubscribe(true)

        try {

            await NotificationsService.subscribeAction(context.auth.uid, tag.id, action)
            await refreshSubscriptions()

            const messageContent = action === NotificationAction.SUBSCRIBE ? i18n.t("categories.successSubscribeMessage") : i18n.t("categories.successUnsubscribeMessage")
            message.success({ content: (<B2>{`${messageContent} "${tag.name}"`}</B2>) });

        } catch (error) {
            message.error({ content: (<B2>{i18n.t("categories.errorMessage")}</B2>) });
        }

        setLoadingSubscribe(false)
    }

    function renderPaginationSection() {
        if (total === 0) {
            return undefined
        }

        return (
            <Pagination
                className={styles.pagination}
                disabled={searchResults.result === undefined || loading}
                pageSize={defaultSize}
                onChange={handlePageChange}
                total={total}
                current={page}
                hasMore={total === 0 ? true : total !== data.length}
                phonePagination={isPhone}
                onReachBottom={handleReachBottom}
            />
        )
    }

    async function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
        setPage(1)
        setFrom(0);
        setQuery(event.target.value);
        setSize(defaultSize);
    }

    function renderContent() {
        const contentLoading = subscriptions === undefined || loading || loadingSubscribe
        const content = (
            <Fragment>
                <Row>
                    <Col className={styles.searchInputContainer}>
                        <Input
                            className={styles.searchInput}
                            prefix={<SearchIcon className={styles.searchInputIcon} />}
                            placeholder={i18n.t("categories.filters.search")}
                            value={query}
                            onChange={handleSearch}
                            disabled={loadingSubscribe}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.resultsContainer}>
                        <Overline>{`${total} ${i18n.t("categories.filters.results")}`}</Overline>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ width: '100%' }}>
                        <div className={styles.divider} />
                    </Col>
                </Row>
                <Row justify={"space-between"} className={styles.listRow}>

                    < List
                        grid={{
                            gutter: 20,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 4,
                            xxl: 4,
                        }
                        }
                        className={[styles.listContainer].join(" ")}
                        dataSource={data}
                        loading={contentLoading}
                        locale={{ emptyText: i18n.t("categories.list.noResults") }}
                        renderItem={(item: Tag) => {
                            const isSubscribed = subscriptions && subscriptions.some(tagId => tagId === item.id)
                            const tooltipText = isSubscribed ? i18n.t("categories.tooltip.unsubscribe") : i18n.t("categories.tooltip.subscribe")
                            return (
                                <div onClick={() => onCategoryClick(item, isSubscribed ? NotificationAction.UNSUBSCRIBE : NotificationAction.SUBSCRIBE)}>
                                    <Tooltip
                                        overlayClassName={styles.tooltip}
                                        placement="bottom"
                                        arrowPointAtCenter
                                        title={tooltipText}
                                    >
                                        <List.Item className={styles.listItem}>
                                            <Col span={22}><B2 className={styles.listItemText}>{item.name}</B2></Col>
                                            <Col span={2}><NotificationIcon selected={isSubscribed} /></Col>
                                        </List.Item>
                                    </Tooltip>
                                </div>
                            )
                        }}
                    />
                </Row>

                {renderPaginationSection()}

            </Fragment>
        )
        return content
    }

    return (renderContent())
}
import React, { useEffect, useState, useCallback } from "react";
import { Col, Dropdown, Menu, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { AuthUser } from "../../../model/auth/AuthUser";
import H3 from "../text/h3";
import { ReactComponent as Close } from "../../../assets/images/svg/close.svg";
import { ReactComponent as Pushpin } from "../../../assets/images/svg/pushpin.svg";
import { useWindowSize } from "../../../hooks/useWindowSize";
import headerTabsService from "../../../services/headerTabsService";
import styles from "./index.module.css";

export interface HeaderTabsProps {
    onClick?: (e: any) => any;
    className?: string;
    user?: AuthUser;
}

function HeaderTabs({ user }: HeaderTabsProps) {
    /* SIMULATION */
    /* const tabsListSim = [
        { name: "Equipas", uri: "/equipas" },
        { name: "2 Quem é quem", uri: "/quem-e-quem" },
        { name: "1 Equipas", uri: "/equipas2" },
        { name: "2 Quem é quem", uri: "/quem-e-quem2" },
        { name: "1 Equipas", uri: "/equipas3" },
        { name: "2 Quem é quem", uri: "/quem-e-quem3" },
        { name: "1 Equipas", uri: "/equipas4" },
        { name: "2 Quem é quem", uri: "/quem-e-quem4" },
        { name: "1 Equipas", uri: "/equipas5" },
        { name: "2 Quem é quem", uri: "/quem-e-quem5" },
        { name: "1 Equipas", uri: "/equipas6" },
        { name: "2 Quem é quem", uri: "/quem-e-quem6" },
    ]; */

    const history = useHistory();
    const { isPhone, width } = useWindowSize();
    const [numberOfTabs, setNumberOfTabs] = useState(0);
    const [hoveringAction, setHoveringAction] = useState(false);
    const [tabsList, setTabsList] = useState([]);
    const [currentTab, setCurrentTab] = useState({ name: "", uri: "" });
    const [isCurrentTabSaved, setIsCurrentTabSaved] = useState(false);
    
    const getTabsList = useCallback(() => {
        if (user?.auth?.uid) {
            headerTabsService
                .getTabsList(user?.auth?.uid)
                .then((result: any) => {

                    if (!result) {
                        return;
                    }
                    if (result.toString() !== tabsList.toString()){
                        setTabsList(result);
                    }

                    // Count number of tabs to display
                    let tabCount = Math.floor((width - 250) / 150);
                    if (tabCount > 6) {
                        tabCount = 6;
                    }
                    setNumberOfTabs(tabCount - 2);

                    // Set current tab
                    setCurrentTab({
                        name: getPageTitle(),
                        uri: window.location.pathname,
                    });

                    // Set true or false if currentTab is present in the tabsList
                    if (tabsList) {
                        const findIfCurrentTabIsSaved = tabsList.find(
                            (element: { name: string; uri: string }) => {
                                if (element.uri === currentTab.uri) {
                                    return true;
                                }
                                return false;
                            }
                        );
                        setIsCurrentTabSaved(
                            findIfCurrentTabIsSaved ? true : false
                        );
                    }
                });
        }
    }, [currentTab.uri, tabsList, user?.auth?.uid, width]);

    useEffect(() => {
        // Get tabs list
        getTabsList();
    }, [currentTab.uri, getTabsList, tabsList, user?.auth?.uid, width]);


    function renderTab(
        name: string,
        pin: boolean,
        link: string,
        key: number | string
    ) {
        const pathName = window.location.pathname;
        return (
            <Col
                onClick={() => !hoveringAction && history.push(link)}
                key={key}
            >
                <div
                    className={[
                        styles.tab2,
                        (pin || pathName === link) ? styles.tabWhite : styles.tabBlack,
                    ].join(" ")}
                >
                    <H3 className={styles.title}>{name}</H3>
                    <div
                        className={[styles.iconWrap, pin ? styles.pinWrap : styles.closeWrap2].join(' ')}
                        onClick={() =>
                            pin ? handlePin() : handleClose(name,link)
                        }
                        onMouseEnter={() => setHoveringAction(true)}
                        onMouseLeave={() => setHoveringAction(false)}
                    >
                        {pin ? <Pushpin /> : <Close />}
                    </div>
                </div>
            </Col>
        );
    }

    function renderDropdown(data: Array<{ name: string; uri: string }>) {
        if(numberOfTabs < tabsList.length){
        const options = data.map(
            (item: { name: string; uri: string }, index: number) => {
                if (index < numberOfTabs) {
                    return null;
                } else {
                    return (
                        <Menu.Item key={"dropdownHeaderTabMenuItem" + index}>
                            {renderTab(
                                item.name,
                                false,
                                item.uri,
                                "dropdownHeaderTabItem" + index
                            )}
                        </Menu.Item>
                    );
                }
            }
        );
        const menu = <Menu className={styles.menu}>{options}</Menu>;

        return (
            <Dropdown
                overlay={menu}
                className={[styles.tab2, styles.tabBlack, styles.dropdown].join(" ")}
            >
                <div
                    className={styles.title}
                    onClick={(e) => e.preventDefault()}
                >
                    <DownOutlined />
                </div>
            </Dropdown>
        );} else{
            return null
        }
    }

    async function handlePin() {
        if(user?.auth?.uid){
           await headerTabsService.addTab(user?.auth?.uid, {name: currentTab.name, uri: currentTab.uri}).then(()=>{
               getTabsList();
               renderDropdown(tabsList);
           })
        }
    }

    async function handleClose(name:string, uri: string) {
        if(user?.auth?.uid){
            await headerTabsService.removeTab(user?.auth?.uid, {name: name, uri: uri}).then(()=>{
                getTabsList();
                renderDropdown(tabsList);
            })
        }
    }

    function getPageTitle() {
        const pathName = window.location.pathname;

        if (pathName === "/") {
            return "Home";
        } else {
            const currentPageTitle = document.title;
            if (!currentPageTitle) {
                return "...";
            }
            return currentPageTitle.replace("InTouch PLMJ | ", "");
        }
    }

    if (user && user.auth && tabsList && !isPhone) {
        /* const tabsListPROTO = user.auth.displayName?.charAt(0); */

        // Checks if headerTabs needs a re-render with currentTab update
        if (
            currentTab.uri !== window.location.pathname ||
            (window.location.pathname !== "/" &&
                currentTab.name !==
                    document.title.replace("InTouch PLMJ | ", ""))
        ) {
            setCurrentTab({
                name: getPageTitle(),
                uri: window.location.pathname,
            });
        }


        return (
            <Row
                justify="start"
                gutter={5}
                wrap={false}
            >
                {tabsList.map(
                    (tab: { name: string; uri: string }, index: number) => {
                        if (index < numberOfTabs) {
                            return renderTab(
                                tab.name,
                                false,
                                tab.uri,
                                "headerTabItem" + index
                            );
                        } else {
                            return null;
                        }
                    }
                )}
                {!isCurrentTabSaved &&
                    renderTab(
                        currentTab.name,
                        true,
                        currentTab.uri,
                        "currentHeaderTabItem"
                    )}
                {renderDropdown(tabsList)}
            </Row>
        );
    }

    return <div />;
}

export default HeaderTabs;

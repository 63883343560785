import React from 'react';
import { ReactComponent as Chevron } from '../../../../assets/images/svg/chevron-down.svg';
import { IconProps } from '../interface';

function ChevronDown({ onClick, className }: IconProps) {
    return (
        <Chevron onClick={onClick} className={className} />
    )
}

export default ChevronDown;

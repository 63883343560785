import React from 'react'
import { Typography } from 'antd';

import { textProps } from '../text-interface'
import styles from './index.module.css';

const { Text } = Typography

export default function B1({ children, className }: textProps) {

    function getClasses() {
        const classes = [styles.body, className || '']
        return classes.join(' ');
    }

    return (
        <Text className={getClasses()}>
            {children}
        </Text>)
}
import React from "react";
import { Col, Row, Typography } from "antd";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import PageTitle from "../../../../base/pageTitle";
import styles from './index.module.css';
import i18n from "../../../../../language/i18n";
import { Link } from 'react-router-dom';
import TagService from "../../../../../services/tagService";
import Pagination from "../../../../base/pagination";
import { SpotlightComponentProps } from "..";
import { Post } from "../../../../../model/post/Post";

export const SpotlightSectionMobile = ({ handleMainCarouselSelection, data, handlePageChange, currentSlide, autoRotate } : SpotlightComponentProps) => {
    const pagination = (
        <Row justify="end" style={{ width: '100%' }}> 
            <Col>
                <Pagination
                    disabled={data.length === 0 || data === null}
                    pageSize={1}
                    onChange={handlePageChange}
                    total={data.length}
                    current={currentSlide + 1}
                    phonePagination={false}
                    className={styles.paginationSpot}
                />
            </Col>
        </Row>
    )

    return (
        <>
            <Carousel
                dynamicHeight={true}
                infiniteLoop={true}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                selectedItem={currentSlide}
                onChange={handleMainCarouselSelection}
                className={styles.spotlightMobile}
                centerMode={false}
                autoPlay={autoRotate}
                interval={5000}
            >
                {data && data.map((p: Post) => ((
                    <Row>
                        <Col span={24}>
                            <Row justify="start">
                                <Col span={24}>
                                    <span className={[styles.label, styles.labelBlog, styles.titleShadow].join(' ')}>
                                        {p.type ? `${i18n.t("post.type." + p.type)}` : `${i18n.t("post.type.news")}`}
                                    </span>
                                    <span className={[styles.label, styles.labelCategory, styles.titleShadow].join(' ')}>
                                        {p.tags ? TagService.formatTags(p.tags) : ""}
                                    </span>
                                    <PageTitle className={[styles.title, styles.titleShadow].join(' ')}>
                                        <Link to={"post/" + p.id}>
                                            {p.title}
                                        </Link>
                                    </PageTitle>
                                    <Typography.Paragraph className={[styles.subtitle, styles.titleShadow].join(' ')} ellipsis={{ rows: 4, expandable: false }}>
                                        {p.subTitle}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                            <Row className={styles.imageRow}>
                                <Col span={24} style={{ minHeight: 1 }}>
                                    <div 
                                        style={{ backgroundImage: (p.images !== undefined && p.images.length > 0) ? "url('" + p.images[0] + "')" : "" }} 
                                        className={styles.image}
                                    />
                                    
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )))}
            </Carousel>
            {pagination}
        </>
    );
}

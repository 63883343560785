import React, { useState, useRef } from "react";
import Spinner from "../../../base/spinner";
import styles from './index.module.css';
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { SpotlightSectionMobile } from "./mobile";
import { SpotlightSectionDesktop } from "./desktop";
import { Post } from "../../../../model/post/Post";

export type SpotlightComponentProps = {
    autoRotate?: boolean,
    mainCarouselRef: React.MutableRefObject<any>,
    secondaryCarouselRef?: React.MutableRefObject<any>,
    handleMainCarouselSelection: (slide: number) => void,
    handleSecondaryCarouselSelection?: (slide: number) => void,
    handlePageChange: (page: number) => void,
    currentSlide: number,
    data: Array<Post>
}

const SpotlightSection = (props: { data: Array<Post>, autoRotate?: boolean }) => {
    const { data } = props;

    const [currentSlide, setCurrentSlide] = useState(0);

    const { isTablet } = useWindowSize();

    const mainCarouselRef = useRef<any>();
    const secondaryCarouselRef = useRef<any>();

    if (data === null) {
        return <Spinner />
    }

    function handlePageChange(page: number) {
        mainCarouselRef.current.goTo(page - 1, false);
    }

    function handleMainCarouselSelection(slide: number) {
        setCurrentSlide(slide);
        if (!isTablet && secondaryCarouselRef.current) {
            secondaryCarouselRef.current.goTo(slide, false);
        }
    }

    function handleSecondaryCarouselSelection(index: number) {
        mainCarouselRef.current.goTo(index, false);
        secondaryCarouselRef.current.goTo(index, false)
    }


    if (isTablet === true) {
        return (
            <div className={styles.spotlightContainer}>
                <SpotlightSectionMobile
                    autoRotate={props.autoRotate}
                    mainCarouselRef={mainCarouselRef}
                    handleMainCarouselSelection={handleMainCarouselSelection}
                    handlePageChange={(page: number) => setCurrentSlide(page - 1)}
                    currentSlide={currentSlide}
                    data={data}
                />
            </div>
        )
    }

    return (
        <div className={styles.spotlightContainer}>
            <SpotlightSectionDesktop
                autoRotate={props.autoRotate}
                mainCarouselRef={mainCarouselRef}
                secondaryCarouselRef={secondaryCarouselRef}
                currentSlide={currentSlide}
                handleMainCarouselSelection={handleMainCarouselSelection}
                handleSecondaryCarouselSelection={handleSecondaryCarouselSelection}
                handlePageChange={handlePageChange}
                data={data}
            />
        </div>
    );
}

export default SpotlightSection;
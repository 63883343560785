import { Col, Row } from "antd";
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import styles from './index.module.css';

export interface TextTextProps {
    data?: Array<any>;
}

export default function TextText(props: TextTextProps) {
    const { isTablet } = useWindowSize();

    if (props.data === undefined || props.data.length === 0) {
        return null;
    }

    const data = props.data;

    const canRender = data[0].body || data[1].body
    return (
        canRender && (
            <Row className={styles.wrapper}>
                <Row justify="space-between" gutter={isTablet ? 0 : 30}>
                    <Col span={isTablet ? 24 : 12}>{ReactHtmlParser(data[0].body || "")}</Col>
                    <Col span={isTablet ? 24 : 12}>{ReactHtmlParser(data[1].body || "")}</Col>
                </Row>
            </Row>
        )
    );
}

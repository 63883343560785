import { Col, Row } from 'antd';
import React from 'react';
import { ReactComponent as Share } from '../../../assets/images/svg/share.svg';
import i18n from '../../../language/i18n';
import B2 from '../text/b2';
import styles from './index.module.css';

export interface ShareButtonProps {
    onClick?: () => void;
    className?: string;

}

function ShareButton({ onClick, className }: ShareButtonProps) {
    return (
        <Row align='middle' className={[styles.shareButtonContainer, className].join(" ")} onClick={onClick} >
            <Col>
                <Share className={className} />
            </Col>
            <Col>
                <B2> {i18n.t("base.button.share")} </B2>
            </Col>
        </Row>
    )
}

export default ShareButton;
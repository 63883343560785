export enum OmniType {
    ALL = "all",
    PARTNERSHIPS = "partnerships",
    EVENTS = "events",
    NEWS = "news",
    PAGES = "pages",
    TEAMS = "teams",
    BROADCASTS = "broadcasts",
    USERS = "users",
    CATEGORIES = 'categories',
    OTHERS = 'others',
    APPLICATIONS = "applications"
}
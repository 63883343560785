import React from 'react'
import { Typography } from 'antd';

import { textProps } from '../text-interface'
import styles from './index.module.css';

const { Text } = Typography

export default function Overline({ children, className, title }: textProps) {

    function getClasses() {
        const classes = [styles.overline, className || '']
        return classes.join(' ');
    }

    return (
        <Text className={getClasses()} title={title}>
            {children}
        </Text>)
}
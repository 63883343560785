import i18n from "../language/i18n";
import { Tag } from "../model/tag/tag";

export class GetLabel {
    getDropdownCheckboxLabel(checkedList: Tag[], optionsList: Tag[]) {
        if (checkedList.length > 1) {
            return `${i18n.t("plus.section.filters.several")} (${checkedList.length})`;
        }

        if (checkedList.length === 1) {
            const { id } = checkedList[0]
            const option = optionsList.find((o: any) => o.id === id)

            return option?.name;
        }

        return i18n.t("plus.section.filters.select");
    }
}
import { Col } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router";
import i18n from "../../../../../language/i18n";
import { OmniType } from "../../../../../model/omni/OmniType";
import Button from "../../../../base/button-v2";
import Paragraph from "../../../../base/text/paragraph";
import Section from "../core";
import styles from './index.module.css';

export type ItemProps = {
    items: any[]
    total: number
    query: string,
    defaultSize: number
}

function CategorySection({ total, items, query, defaultSize }: ItemProps) {
    const history = useHistory()

    const displayNoResultsMessage = total <= 5
    return (
        <Section
            type={OmniType.CATEGORIES}
            footer={(
                <>
                    {total === 0 ?
                        <Button className={styles.button} onClick={() => history.push(`/categorias`)}>
                            {i18n.t("omnisearch.results.text.goToCategories")}
                        </Button> :
                        <Button className={styles.button} onClick={() => history.push(`/categorias?texto=${query}`)}>
                            {total <= defaultSize ?
                                i18n.t("omnisearch.results.text.seeAllResultsCategories") :
                                `${i18n.t("omnisearch.results.text.seeMoreBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inCategories")}`}
                        </Button>
                    }
                </>
            )}
            items={(
                <>
                    {items.map((item: any) => (
                        <Col className={styles.categoryItem} onClick={() => history.push(`/categorias?texto=${item.name}`)}>
                            <Highlighter
                                className={styles.categoryItemTitle}
                                highlightClassName={styles.highlight}
                                searchWords={[query]}
                                autoEscape={true}
                                textToHighlight={item.name}
                            />
                        </Col>
                    ))}
                    {displayNoResultsMessage &&
                        <Paragraph className={styles.noResultsMessage}>{total === 0 ?
                            `${i18n.t("omnisearch.results.text.noResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inCategories")}` :
                            `${i18n.t("omnisearch.results.text.noMoreResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inCategories")}`}</Paragraph>
                    }
                </>
            )}
        />
    )
}

export default CategorySection;
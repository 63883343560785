import React, { useState } from 'react'
import { Col, Dropdown, Radio, Row } from 'antd';
import styles from './index.module.css';
import ChevronDown from '../../../base/icon/chevronDown';
import H5 from '../../../base/text/h5';
import i18n from '../../../../language/i18n';
import { useEffect } from 'react';

type DropdownText = {
    text: string
    isSelected: boolean
}

interface FiltersProps {
    filtersData: any;
    className?: string;
    setJobTitle: (jobTitle: string | undefined) => void;
    setCity: (city: string | undefined) => void;
    setDepartment: (department: string | undefined) => void;
    city: string | undefined;
    department: string | undefined;
    jobTitle: string | undefined;
    disabled?: boolean
}

function Filters({ filtersData, className, setCity, setDepartment, setJobTitle, jobTitle, department, city, disabled }: FiltersProps) {
    const [openFilter, setOpenFilter] = useState<string | null>(null);
    const [departmentText, setDepartmentText] = useState<DropdownText>({ text: i18n.t("whoIsWho.section.filters.departments"), isSelected: false })
    const [jobTitleText, setJobTitleText] = useState<DropdownText>({ text: i18n.t("whoIsWho.section.filters.jobTitles"), isSelected: false })
    const [cityText, setCityText] = useState<DropdownText>({ text: i18n.t("whoIsWho.section.filters.cities"), isSelected: false })

    useEffect(() => {
        const newDepartmentText = department === undefined ? { text: i18n.t('whoIsWho.section.filters.departments'), isSelected: false } : { text: department, isSelected: true }
        const newJobTitleText = jobTitle === undefined ? { text: i18n.t('whoIsWho.section.filters.jobTitles'), isSelected: false } : { text: jobTitle, isSelected: true }
        const newCityText = city === undefined ? { text: i18n.t('whoIsWho.section.filters.cities'), isSelected: false } : { text: city, isSelected: true }

        setDepartmentText(newDepartmentText)
        setJobTitleText(newJobTitleText)
        setCityText(newCityText)

    }, [jobTitle, department, city])

    function handleInputChange(event: any) {
        const { value, name } = event.target;
        const valuetoSet = value === "" ? undefined : value
        switch (name) {
            case "departments":
                value ? setDepartmentText({ text: value, isSelected: true }) : setDepartmentText({ text: i18n.t('whoIsWho.section.filters.departments'), isSelected: false })
                setDepartment(valuetoSet);
                break;
            case "jobTitles":
                value ? setJobTitleText({ text: value, isSelected: true }) : setJobTitleText({ text: i18n.t('whoIsWho.section.filters.jobTitles'), isSelected: false })
                setJobTitle(valuetoSet);
                break;
            case "cities":
                value ? setCityText({ text: value, isSelected: true }) : setCityText({ text: i18n.t('whoIsWho.section.filters.cities'), isSelected: false })
                setCity(valuetoSet);
                break;
        }
        setOpenFilter(null);
    }

    function renderRadioGroup(group: any, name: string) {
        return (
            <Radio.Group onChange={handleInputChange} name={name} optionType="button">
                <Radio value="">{i18n.t('whoIsWho.section.filters.all')}</Radio>
                {
                    group?.map(({ data, id }: any) => {
                        return <Radio value={data.name} key={id}>{data.name}</Radio>
                    })
                }
            </Radio.Group>)
    }

    function handleVisibleChange(flag: boolean) {
        if (!flag) setOpenFilter(null);
    };

    function openDropdown(name: string) {
        setOpenFilter(name)
    }

    return (
        <Row className={[styles.container, className].join(" ")} gutter={[{}, 16]} justify="space-between">
            {filtersData ? Object.values(filtersData).map((item: any, index: number) => {
                const keyNames = Object.keys(filtersData);

                const titles = [departmentText, jobTitleText, cityText]
                const unselectedTitleStyle = titles[index]?.isSelected ? undefined : styles.titleUnselected
                const disabledRowStyle = disabled ? styles.dropdownRowDisabled : undefined

                return (
                    <Col span={24} md={7} key={index} >
                        <Dropdown
                            overlay={() => renderRadioGroup(item, keyNames[index])}
                            trigger={['click']}
                            overlayClassName={styles.overlay}
                            visible={openFilter === keyNames[index]}
                            onVisibleChange={handleVisibleChange}
                            placement="bottomLeft"
                        >
                            <Row justify="space-between" align="middle" className={[disabledRowStyle, styles.dropdownRow].join(' ')} onClick={() => openDropdown(keyNames[index])}>
                                <Col>
                                    <H5 className={[styles.title, unselectedTitleStyle].join(' ')}>{titles[index]?.text || ""}</H5>
                                </Col>
                                <Col>
                                    <ChevronDown />
                                </Col>
                            </Row>
                        </Dropdown>
                    </Col>
                )
            }) : undefined}
        </Row>
    )
}

export default Filters;
import React from 'react';
import AuthenticationContext from '../../components/auth/authenticationContext';
import authentication from '../../services/authentication';
import i18n from '../../language/i18n';
import styles from './index.module.css';
import { Card, Col, Row } from 'antd';
import LockIcon from '../../components/base/icon/lock';
import MicrosoftIcon from '../../components/base/icon/microsoft';
import Button from '../../components/base/button-v2';
import { useState } from 'react';
import { useContext } from 'react';


export default function LoginPage() {
    const [loading, setLoading] = useState(false)
    const context = useContext(AuthenticationContext)


    async function authenticate() {
        setLoading(true)
        try {
            await authentication.authenticateUser();
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }




    return (
        <div className={styles.page}>
            <Card className={styles.card} >
                <Row justify={'center'}>
                    <Col>
                        <div className={styles.circle} >
                            <LockIcon className={styles.lockIcon} />
                        </div>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col>
                        <Button
                            loading={loading || context?.loading}
                            disabled={loading || context?.loading}
                            icon={<MicrosoftIcon className={styles.microsoftIcon} />}
                            onClick={authenticate}
                            className={styles.loginButton}
                        >{i18n.t('loginPage.loginMicrosoft')}
                        </Button>
                    </Col>
                </Row>
            </Card>
        </div>
    )

}

LoginPage.contextType = AuthenticationContext


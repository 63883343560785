import React, { useEffect, useState } from "react";
import { Card, Col, Row, List } from "antd";
import i18n from "../../../../language/i18n";
import ChevronDown from "../../../base/icon/chevronDown";
import MsgTeamsIcon from "../../../base/icon/msgTeams";
import B2 from "../../../base/text/b2";
import H5 from "../../../base/text/h5";
import Overline from "../../../base/text/overline";
import styles from './index.module.css';
import { User } from "../../../../model/user/user";
import { useHistory } from "react-router";
import Button from "../../../base/button-v2";
import settingsService from "../../../../services/settingsService";
import JobTitleService from "../../../../services/jobTitleService";

export interface userListProps {
    users: User[];
    loading?: boolean;
}
export default function UserList({ users, loading }: userListProps) {
    const [userListByJobs, setUserListByJobs] = useState<null | any>(null);
    const [expandedGroup, setExpandedGroup] = useState<null | string>(null);
    const loadingStyle = loading === true ? styles.userListContainerLoading : undefined;
    const fallbackGroupName = i18n.t("teams.section.usersList.others")
    const history = useHistory();

    useEffect(() => {
        solveUserList();
        // eslint-disable-next-line
    }, [])

    async function solveUserList() {

        // Sort users alphabetically
        if (users) {
            users.sort(function (a, b) {
                if (!a.displayName && !b.displayName) {
                    return a.givenName.localeCompare(b.givenName);
                }
                if (a.displayName && b.displayName) {
                    return a.displayName.localeCompare(b.displayName);
                } else if (a.givenName && b.givenName) {
                    return a.givenName.localeCompare(b.givenName);
                } else {
                    return a.mailNickname.localeCompare(b.mailNickname);
                }
            });
        }

        let userList: any = {};

        // Get names of groups of departments and its order
        const order = await getJobTitlesOrder()

        // Get job titles' collection of plural groups with their single names
        const jobTitleGroups = await getJobTitlesGroups()

        if (order && jobTitleGroups && Array.isArray(order) && Array.isArray(jobTitleGroups)) {

            // Structure userList with the ordered job titles
            order.forEach((jobTitleGroup: string) => {
                userList[jobTitleGroup] = [];
            })

            // For each user, compare jobTitle with Groups data, if true, then store in userStructure Object
            users.forEach((user: any) => {
                let found = false
                jobTitleGroups.forEach((group: any) => {
                    const { values, name } = group;
                    values.forEach((value: string) => {
                        if (user.jobTitle === value) {
                            found = true
                            if (userList[name]) {
                                userList[name] = [...userList[name], user];
                            } else {
                                userList[name] = [user];
                            }
                        }
                    })
                })
                if (!found) {
                    userList[fallbackGroupName] = userList[fallbackGroupName] ? [...userList[fallbackGroupName], user] : [user]
                }
            })

            setUserListByJobs(userList);
        }
    }

    async function getJobTitlesGroups() {
        try {
            const jobTitles = await JobTitleService.getAll()
            return jobTitles
        } catch (error) {
            console.log("error", error)
        }
    }

    async function getJobTitlesOrder() {
        try {
            const pluralJobTitlesOrder = await settingsService.getJobTitles()
            return pluralJobTitlesOrder;
        } catch (error) {
            console.log("error", error)
        }
    }

    function handleUserClick(user: string) {
        history.push(`/quem-e-quem?texto=${user}`);
    }

    function formatPhoneNumber(phoneNumber: string) {
        return phoneNumber.split(" ").join("");
    }

    const renderUsers: any = () => {
        if (userListByJobs) {
            const userListObjectKeys = Object.keys(userListByJobs);

            let toRender: any[] = [];

            userListObjectKeys.forEach((group, index) => {
                const groupUsers = userListByJobs[group];
                if (groupUsers.length === 0) {
                    return
                }
                toRender.push(
                    <Col span={24} key={index}>
                        <Row className={styles.titleContainer}>
                            <Col>
                                <B2>{group || i18n.t("teams.section.usersList.others")}</B2>
                            </Col>
                        </Row>

                        <List
                            grid={{
                                gutter: 40,
                                xs: 1,
                                sm: 1,
                                md: 2,
                                lg: 3,
                                xl: 3,
                                xxl: 3,
                            }}
                            dataSource={groupUsers}
                            locale={{ emptyText: "Sem Resultados" }}
                            loadMore={groupUsers.length > 3 && group !== expandedGroup && <Button className={styles.seeMoreButton} filling="default" onClick={() => setExpandedGroup(group)}>{i18n.t("teams.section.usersList.seeMore")}</Button>}
                            renderItem={(item: any, index: number) => {

                                if (index > 2 && group !== expandedGroup) {
                                    return
                                }

                                return (
                                    <List.Item
                                        className={styles.cardContainer}>
                                        <Card className={styles.card}>
                                            <Row justify={'space-between'}>
                                                <Col className={styles.infoContainer} span={22}>
                                                    <div style={{ backgroundImage: item.photo && 'url(' + item.photo + ')' }} className={item.photo ? styles.thumbnail : [styles.thumbnail, styles.imgPlaceholder].join(" ")} />
                                                    <div >
                                                        <H5 className={styles.name} onClick={() => handleUserClick(item.mailNickname)}>{item.name}</H5>
                                                        <Row className={styles.info}>
                                                            <Col span={24} className={styles.emailContainer}>
                                                                <Overline className={styles.label}>{i18n.t("teams.section.usersList.phone")} </Overline>
                                                                {item.businessPhones.length !== 0 ? item.businessPhones.map((phone: string) => <a className={"noDecoration"} key={phone} href={`tel:${phone}`}><Overline>{formatPhoneNumber(phone)}</Overline></a>) : <Overline>N/A</Overline>}
                                                            </Col>
                                                        </Row>
                                                        {
                                                            item.email &&
                                                            <Row className={styles.info}>
                                                                <Col span={24} className={styles.emailContainer}>
                                                                    <Overline className={styles.label}>{i18n.t("teams.section.usersList.email")} </Overline>
                                                                    <a className={"noDecoration"} href={`mailto:${item.email}`}>
                                                                        <Overline >{item.email}</Overline>
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        }

                                                    </div>
                                                </Col>
                                                <Col span={2}>
                                                    <ChevronDown className={styles.chevron} onClick={() => handleUserClick(item.mailNickname)} />
                                                </Col>
                                            </Row>
                                            <a href={`https://teams.microsoft.com/l/chat/0/0?users=${item.email}`}>
                                                <Row justify="space-between" className={styles.teamsContainer}>
                                                    <Col span={20}>
                                                        <B2 className={styles.microsoftLink}>{i18n.t("whoIsWho.section.card.microsoft")}</B2>
                                                    </Col>
                                                    <Col span={4} className={styles.chatIcon}>
                                                        <MsgTeamsIcon />
                                                    </Col>
                                                </Row>
                                            </a>
                                        </Card>
                                    </List.Item>
                                )
                            }}
                        />
                    </Col>
                )

            })

            return toRender;
        }
    }

    return (
        <Row gutter={[{}, 30]} className={[styles.userListContainer, loadingStyle].join(" ")} justify={'space-between'}>
            {renderUsers()}
        </Row>
    )
}
import React, { useEffect } from 'react'
import FullCalendar, { DatesSetArg, EventContentArg, EventMountArg } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { useWindowSize } from '../../../../hooks/useWindowSize';
import calendarSVG from '../../../../assets/images/svg/calendar.svg';
import { CalendarData } from '../../../../model/fullCalendarData/fullCalendarData';
import './calendar.css'
import moment from 'moment';

interface CalendarMonthProps {
    calendarData: CalendarData[] | null;
    handleMonthChange: (range: DatesSetArg) => Promise<void>;
    isLoading: boolean;
}

function CalendarMonth({ calendarData, handleMonthChange, isLoading }: CalendarMonthProps) {
    const { isPhone } = useWindowSize();

    useEffect(() => {
        if (!isPhone) {
            const titleNode = document.querySelector('.fc-toolbar-title');
            const parentNode = titleNode?.parentNode;
            if (parentNode && parentNode?.childElementCount < 2) {
                const calendarIcon = document.createElement('img');
                calendarIcon.src = calendarSVG;
                parentNode?.prepend(calendarIcon)
            }
        }

    }, [isPhone]);

    useEffect(() => {
        let layer: Element;
        if (isLoading) {
            const calendarNode = document.querySelector('.viewMonth');
            layer = document.createElement('div');
            layer.className = "loading";
            calendarNode && calendarNode.appendChild(layer);
        } else {
            const layerNode = document.querySelector(".loading");
            layerNode?.remove()
        }
    }, [isLoading])

    function handleEventMount(eventContent: EventMountArg) {
        const tableRows = document.querySelectorAll('tr');
        handleMultipleDaysEventColor(tableRows);
        handleSingleDayEventMargin(eventContent.el);
    }

    function handleSingleDayEventMargin(element: HTMLElement) {
        const visibleEls = element.parentElement?.parentElement?.querySelectorAll(".visible");
        visibleEls?.forEach((childEl, index) => {

            if (index > 4 && childEl.parentNode?.isSameNode(element)) {
                element.style.display = 'none';
            }

            if (childEl.parentNode?.isSameNode(element)) {
                element.style.marginLeft = `${index * 7}px`
            }
        });
    }

    function renderEventContent(eventContent: EventContentArg) {
        let color = '';
        switch (eventContent.event.groupId.toLowerCase()) {
            case '#8ad6af':
                color = 'internalEvent';
                break;
            case '#fac362':
                color = 'externalEvent';
                break;
            case '#aea2cd':
                color = 'training';
                break;
        }

        const startsAfterMonthBegins = moment(eventContent.event.start).isSame(moment(eventContent.view.activeStart).add("10", "days"), 'month');
        const isMultipleDays = !(moment(eventContent.event._instance?.range.end).startOf('day').isSame(moment(eventContent.event._instance?.range.start).startOf('day')))
        const visibility = isMultipleDays ? "hidden" : "visible";
        const hasOpacity = !startsAfterMonthBegins ? "hasOpacity" : "";
        const singleDayOpacity = !isMultipleDays && !startsAfterMonthBegins ? "single-day-opacity" : ""

        return <div className={[color, visibility, hasOpacity, singleDayOpacity].join(" ")} />
    }

    function handleMultipleDaysEventColor(nodeList: NodeListOf<HTMLTableRowElement>) {    
        
        nodeList.forEach((element) => {
            const eventRanges = element.querySelectorAll('.fc-daygrid-event-harness-abs');
            let rangeCount = 0;

            eventRanges.forEach((event) => {
                if (event.children[0].className.includes("fc-daygrid-block-event")) {
                    if (event.querySelector('.internalEvent')) {
                        event.classList.add('borderInternalEvent')
                    }

                    if (event.querySelector('.externalEvent')) {
                        event.classList.add('borderExternalEvent')
                    }

                    if (event.querySelector('.training')) {
                        event.classList.add('borderTraining')
                    }

                    const eventWithOpacity = event.querySelector('.hasOpacity');
                    eventWithOpacity?.parentElement?.parentElement?.parentElement?.classList.add("opacity-half")

                    if (event.className.includes("range-")) {
                        const rangeNumber = event.className.split('range-')[1][0];
                        event.classList.remove(`range-${rangeNumber}`)
                    }

                    event.classList.add(`range-${rangeCount}`)
                    rangeCount++;
                }
            });
        })
    }


    return (
        <FullCalendar
            headerToolbar={isPhone ? { start: 'prev', center: 'title', end: 'next' } : { start: 'prev, next', center: 'title', end: '' }}
            plugins={[dayGridPlugin]}
            initialView={'dayGridMonth'}
            dayHeaderFormat={{ weekday: 'narrow' }}
            locale="pt-pt"
            events={calendarData || []}
            eventContent={renderEventContent || []}
            eventDidMount={handleEventMount}
            contentHeight={525}
            viewClassNames="viewMonth"
            fixedWeekCount={true}
            datesSet={handleMonthChange}
        />
    )
}

export default CalendarMonth;
import { Pagination as AntdPagination, Row } from 'antd';
import React from 'react';
import { ReactComponent as ArrowLeft } from '../../../assets/images/svg/pagination-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/svg/pagination-arrow-right.svg';
import i18n from '../../../language/i18n';
import Button from '../button-v2';
import styles from './index.module.css';

export interface PaginationProps {
    onChange: (page: number, pageSize?: number) => void;
    onReachBottom?: () => void;
    className?: string;
    pageSize?: number;
    total?: number;
    disabled?: boolean;
    current?: number;
    phonePagination?: boolean
    hasMore?: boolean
};

type itemRenderType = "page" | "prev" | "next" | "jump-prev" | "jump-next"

export default function Pagination({ disabled, pageSize, onChange, total, current, phonePagination, hasMore, onReachBottom, className }: PaginationProps) {

    function itemRender(page: number, type: itemRenderType, originalElement: React.ReactElement) {
        if (type === 'prev') {
            return <ArrowLeft />;
        }
        if (type === 'next') {
            return <ArrowRight />;
        }

        return originalElement
    }


    if (phonePagination) {
        const buttonType = disabled ? 'disabled' : 'enabled'
        return (
            <>
                {hasMore &&
                    <Row className={styles.phonePaginationContainer}>
                        <Button type={buttonType} disabled={disabled} onClick={onReachBottom} className={styles.seeMoreButton}>{i18n.t("pagination.seeMore")}</Button>
                    </Row>
                }
            </>
        )
    } else {
        return (
            <Row className={[styles.paginationContainer, className].join(" ")}>
                <AntdPagination
                    disabled={disabled}
                    pageSize={pageSize}
                    onChange={onChange}
                    total={total}
                    current={current}
                    itemRender={itemRender}
                    simple
                />
            </Row>
        )
    }
};
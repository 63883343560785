import moment from "moment";
import { Event } from "../../model/event/event";

export function formatDate(event: Event | null, isModal?: boolean) {

    if (!event) return;

    const startDate = moment(event.startDate);
    const startDateObject = {
        startWeekDay: isModal ? startDate.format('dddd').split('-')[0] : startDate.format('ddd').split('-')[0],
        startMonthDay: isModal ? startDate.format('DD [de] MMMM') : startDate.format('DD/MM'),
        startTime: startDate.format('kk:mm')
    }
    const { startWeekDay, startMonthDay, startTime } = startDateObject;

    const endDate = moment(event.endDate);

    const startEqualsEnd = startDate.startOf('day').isSame(endDate.startOf('day'));

    if (!event.endDate || startEqualsEnd) return `${startWeekDay}, ${startMonthDay} às ${startTime}`

    const endDateObject = {
        endWeekDay: isModal ? endDate.format('dddd').split('-')[0] : endDate.format('ddd').split('-')[0],
        endMonthDay: isModal ? endDate.format('DD [de] MMMM') : endDate.format('DD/MM'),
    }

    const { endWeekDay, endMonthDay } = endDateObject;

    return `${startWeekDay}, ${startMonthDay} - ${endWeekDay}, ${endMonthDay}`
}
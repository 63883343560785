import { useCallback, useEffect, useState } from "react";
import UsersService from "../services/usersService";
import { useDebouncedSearch } from "./useDebouncedSearch";

const defaultSize = 8;
export function useUsers(initialQuery?: string | null, initialSize?: number | undefined, initialDepartment?: string | null) {
    // Array that maps a token to each page for the current queried search
    const [fromTokens, setFromTokens] = useState<Array<string | undefined>>([undefined]);
    const [data, setData] = useState<any[]>([]);
    const [queriedUsersTotal, setQueriedUsersTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [city, setCity] = useState<string | undefined>(undefined);
    const [department, setDepartment] = useState<string | undefined>(initialDepartment === null || initialDepartment === undefined ? undefined : initialDepartment);
    const [jobTitle, setJobTitle] = useState<string | undefined>(undefined);

    const getUsers = useCallback(async (
        query: string = "",
        from: string | undefined = undefined,
        size: number = defaultSize,
        city?: string,
        department?: string,
        jobTitle?: string
    ) => {
        const criteria = {
            ...(query !== "" ? { query } : {}),
            ...(city !== undefined ? { city } : {}),
            ...(department !== undefined ? { department } : {}),
            ...(jobTitle !== undefined ? { jobTitle } : {}),
            from,
            size
        }

        try {
            const { data } = await UsersService.getAll(criteria);

            if (queriedUsersTotal === 0) {
                setQueriedUsersTotal(data.total)
            }

            // For every search, try to add the token for the next page to the list of current tokens, if not already in it
            setFromTokens((prevfromTokens) => {
                if (data.from !== null && prevfromTokens.findIndex(token => token === data.from) === -1) {
                    prevfromTokens.push(data.from)
                }
                return prevfromTokens
            })

            return data;
        } catch (e) {
            return { response: [] };
        }
    }, [queriedUsersTotal]);

    const useGetUsers = () => useDebouncedSearch(getUsers, initialSize || defaultSize, initialQuery === null ? undefined : initialQuery, city, department, jobTitle);

    const {
        setFrom,
        setSize,
        query,
        setQuery,
        searchResults
    } = useGetUsers();

    // Set the token according to the next page to be fetched
    const nextPage = useCallback((page: number) => {
        setFrom(fromTokens[page - 1] || 0)
    }, [fromTokens, setFrom])

    const resetTokens = useCallback(() => {
        setFromTokens([undefined])
        setFrom(0)
    }, [setFrom])

    useEffect(() => {
        setLoading(searchResults.loading)

        if (searchResults.result === undefined) {
            return
        }

        setData(searchResults.result.response)
    }, [searchResults])

    return {
        loading,
        data,
        setSize,
        query,
        setQuery,
        setDepartment,
        setJobTitle,
        setCity,
        city,
        department,
        jobTitle,
        queriedUsersTotal,
        searchResults,
        defaultSize,
        nextPage,
        resetTokens
    }
}
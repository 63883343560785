import { OmniType } from "../../model/omni/OmniType";

export function translateSearchbarOptions(type: OmniType) {
    switch (type) {
        case OmniType.USERS:
            return "Pessoas";
        case OmniType.BROADCASTS:
            return "Vídeos/Podcasts";
        case OmniType.NEWS:
            return "Notícias";
        case OmniType.EVENTS:
            return "Eventos";
        case OmniType.CATEGORIES:
            return "Categorias";
        case OmniType.PAGES:
            return "Páginas";
        case OmniType.TEAMS:
            return "Equipas";
        case OmniType.PARTNERSHIPS:
            return "Parcerias";
        case OmniType.APPLICATIONS:
            return "Aplicações";
        case OmniType.ALL:
            return "Pesquisa geral";
        case OmniType.OTHERS:
            return "Outros";
        default:
            return type
    }
}
import React, { useContext, useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd';
import H2 from '../../../base/text/h2';
import styles from './index.module.css';
import ChevronLeft from '../../../base/icon/chevronLeft';
import { Link, useHistory } from 'react-router-dom';
import { Event } from '../../../../model/event/event';
import EventService from '../../../../services/eventService';
import moment from 'moment';
import H5 from '../../../base/text/h5';
import Overline from '../../../base/text/overline';
import { formatDate } from '../../../../helpers/homepage/agendaDateFormatter';
import { formatEventListDate } from '../../../../helpers/agenda/formatEventListDate';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import Button from '../../../base/button-v2';
import { groupByDay } from '../../../../helpers/agenda/groupByDay';
import { Dictionary } from 'underscore';
import AuthenticationContext from '../../../auth/authenticationContext';
import EventSmallCard from '../../../base/eventSmallCard';
import i18n from '../../../../language/i18n';
import EventModal from '../../agenda/eventModal';
import { Tag } from '../../../../model/tag/tag';
import TickIcon from '../../../base/icon/tick';
import B2 from '../../../base/text/b2';

function Agenda() {
    const [nextEvent, setNextEvent] = useState<Event | null>(null);
    const [eventsByDay, setEventsByDay] = useState<null | Dictionary<Event[]>>(null);
    const [modalData, setModalData] = useState<Event | null>(null);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [eventTags, setEventTags] = useState<Tag[]>([]);

    const history = useHistory();
    const context = useContext(AuthenticationContext);
    const userId = context?.claims?.user_id;

    const { width } = useWindowSize();

    useEffect(() => {
        EventService.getAll({
            range: [{
                field: "startDate",
                range: "gte",
                value: moment().toISOString()
            }],
            size: 4,
            sort: [{
                field: "startDate",
                order: "asc"
            }]
        }).then(res => {
            const { response } = res.data;

            let tagRes: Array<Tag> = [];
            response.forEach((element: { tags: Array<Tag>; }) => {
                tagRes.push(element.tags[0]);
            });
            setEventTags(tagRes);
            const groupedByDay = groupByDay(response);
            setNextEvent(response[0]);
            setEventsByDay(groupedByDay);

        });
    }, [])

    async function refreshEvent() {
        const events = await EventService.getAll({
            range: [{
                field: "startDate",
                range: "gte",
                value: moment().toISOString()
            }],
            size: 4,
            sort: [{
                field: "startDate",
                order: "asc"
            }]
        })
        const newEvent = events.data.response.find((event: { id: string | undefined; }) => event.id === modalData?.id)
        setModalData(newEvent ? newEvent : null)
        setNextEvent(events.data.response[0])
        setEventsByDay(groupByDay(events.data.response))
    }

    function handleEventClick(event: Event) {
        setModalIsOpen(true);
        setModalData(event);
    }

    function handleModalVisibleChange() {
        if (!modalIsOpen) setModalData(null);
    }

    function handleModalClose() {
        setModalIsOpen(false)
    }

    function isSubscribed(event: Event | null) {
        if (!userId) return null;
        const subscription = event?.attendees?.includes(userId);
        return subscription;
    }

    function getColor(event: Event) {
        const tag = eventTags?.find((item) => item.name.toLowerCase() === (event.tags && event?.tags[0]?.name.toLowerCase()));
        return tag?.color || "";
    }

    function renderNextEvent() {
        return nextEvent ?
            <Col xs={24} className={styles.nextEvent} onClick={() => handleEventClick(nextEvent)}>
                <div className={styles.categoryContainer}>
                    <div
                        className={styles.category}
                        style={{ backgroundColor: getColor(nextEvent) }}
                    />
                    <Overline >{nextEvent.tags[0].name}</Overline>
                </div>
                <H5 className={styles.nextEventTitle}>{nextEvent.title}</H5>
                <Overline className={styles.highlightEventDate}>{formatDate(nextEvent, width)}</Overline>

                {isSubscribed(nextEvent) ?
                    <Row className={styles.subscriptionRow} >
                        <Col className={styles.subscription} span={2}>
                            <div className={styles.tickIcon}>
                                <TickIcon />
                            </div>
                        </Col>
                        <Col>
                            <Overline>{i18n.t("agenda.section.modal.subscribed")}</Overline>
                        </Col>
                    </Row>
                    :
                    <Button
                        filling="primary"
                        className={styles.bookButton}
                    >
                        {i18n.t("homepage.section.agenda.schedule")}
                    </Button>
                }
            </Col> :
            <Col xs={24} className={styles.noEventsContainer}>
                <B2>{i18n.t("homepage.section.agenda.noEvents")}</B2>
            </Col>
    }

    function renderEventsByDay() {
        if (!eventsByDay || !Object.keys(eventsByDay).length) return null

        return Object.keys(eventsByDay).map((item, index) => {
            if (index === 0) return null;
            return (
                <Col className={styles.eventRow} span={24} key={item}>
                    <div className={styles.dateList}>
                        <Overline >{formatEventListDate(item)}</Overline>
                    </div>
                    {eventsByDay && eventsByDay[item].map((event: Event) => {
                        return (
                            <EventSmallCard
                                event={event}
                                userSubscribed={isSubscribed(event)}
                                key={event.id}
                                onClick={() => handleEventClick(event)}
                            />
                        )
                    })}
                </Col>
            )
        })
    }

    function renderSeeMoreButton() {
        if (!nextEvent && (!eventsByDay || !Object.keys(eventsByDay).length)) return null;
        return (
            <Col span={24} className={styles.seeMore}>
                <Button className={styles.button} onClick={() => history.push('/agenda')}>{i18n.t("homepage.section.agenda.seeMore")}</Button>
            </Col>
        )
    }

    return (
        <Col xs={24} lg={8} offset={width < 992 ? 0 : 3} className={styles.agendaContainer}>
            <Row>
                <Col span={24} className={styles.subtitle}>
                    <Link className={"noDecoration"} to="/agenda">
                        <H2 className={styles.mainTitle}>{i18n.t("homepage.section.agenda.title")}</H2>
                        <ChevronLeft className={styles.chevron} />
                    </Link>
                </Col>

                {renderNextEvent()}
                {width > 991 && <Divider className={styles.divider} />}
                <Col xs={24} >
                    <Row>
                        {renderEventsByDay()}
                    </Row>
                </Col>
                {renderSeeMoreButton()}

            </Row>
            <EventModal
                modalData={modalData}
                onClose={handleModalClose}
                afterVisibleChange={handleModalVisibleChange}
                isVisible={modalIsOpen}
                isSubscribed={isSubscribed}
                getColor={getColor}
                refreshEvents={refreshEvent}
            />
        </ Col>
    )
}

export default Agenda;
import { useEffect, useRef } from "react"

export function useInitialState(state: any) {
    let ref = useRef<any>();
    useEffect(() => {
        ref.current = state;
        // eslint-disable-next-line
    }, [])

    return ref.current;
}
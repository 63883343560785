import React from "react";
import AuthenticationContext from "../../auth/authenticationContext";
import PageContainer from "../../base/pageContainer";
import FooterContent from "./footerContent";
import styles from './index.module.css';


export default function Footer() {
    return (
        <footer>
            <PageContainer className={styles.container}>
                <FooterContent />
            </PageContainer>
        </footer>
        
    )
}

Footer.contextType = AuthenticationContext
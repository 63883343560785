import { Row } from "antd";
import React from "react";
import styles from './index.module.css';

export interface SeparatorProps {
    data?: Array<any>;
    className?: string;
}

export default function Separator(props: SeparatorProps) {
    if (props.data === undefined || props.data.length === 0) {
        return null;
    }

    const data = props.data[0];

    return (
        data.exists && (
            <Row className={[styles.wrapper, props.className].join(" ")}>
                <hr />
            </Row>
        )
    );
}

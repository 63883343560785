import React from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.css';
import PageTitle from "../../../base/pageTitle";
import B2 from '../../../base/text/b2';
import i18n from '../../../../language/i18n';
import { ReactComponent as BackgroundLeftDesktop } from './../../../../assets/images/svg/backgrounds/broadcast/left.svg'
import { ReactComponent as BackgroundRight } from './../../../../assets/images/svg/backgrounds/broadcast/right.svg'
import { useWindowSize } from '../../../../hooks/useWindowSize';

interface IntroProps {
    title: string | undefined,
    subtitle: string | undefined,
    background: string | undefined,
    loading: boolean,
}

function Intro({ title, subtitle, background, loading }: IntroProps) {
    const { isTablet } = useWindowSize();

    return (
        <Row className={[styles.intro, loading ? styles.loading: ""].join(" ")}
            style={background === undefined
                ? {}
                : {
                    backgroundImage: `url(${background})`,
                }}>
            <Col span={24}>
                <PageTitle>{title || i18n.t("broadcast.section.intro.title")}</PageTitle>
            </Col>
            <Col span={24} className={styles.iconContainer}>
                {
                    !isTablet && (
                        <>
                            <BackgroundLeftDesktop />
                            <BackgroundRight />
                        </>
                    )
                }
            </Col>
            <Col span={24} className={styles.descriptionContainer}>

                {subtitle ?
                    <B2 className={styles.description}>
                        {subtitle}
                    </B2> : <>
                        <B2 className={styles.description}>
                            {i18n.t("broadcast.section.intro.description-1")}
                        </B2>
                        <B2 className={styles.description}>
                            {i18n.t("broadcast.section.intro.description-2")}
                        </B2>
                    </>
                }



            </Col>
        </Row>
    )
}

export default Intro;
import React, { useState } from 'react'
import { Col, Row, Carousel, Divider } from 'antd'
import { useWindowSize } from '../../../../hooks/useWindowSize';
import i18n from '../../../../language/i18n';
import Button from '../../../base/button-v2';
import H3 from '../../../base/text/h3'

import { Event } from '../../../../model/event/event'
import styles from './index.module.css';
import ChevronLeft from '../../../base/icon/chevronLeft';

import UpcomingCard from '../upcomingCard';
import { orderByDate } from '../../../../helpers/agenda/orderByDate';
import Spinner from '../../../base/spinner';

interface UpcomingEventsProps {
    data: Event[] | null,
    onEventClick: (event: Event) => void;
    isSubscribed: (event: Event | null) => boolean | undefined | null;
    getColor: (event: Event) => string;
    loading: boolean;
}

function UpcomingEvents({ data, onEventClick, isSubscribed, getColor, loading }: UpcomingEventsProps) {
    const [visibleItems, setVisibleItems] = useState<number>(4)
    const { width } = useWindowSize();

    function handleSeeMore() {
        setVisibleItems(current => current + 4);
    }

    function renderCard(event: Event) {
        return (
            <UpcomingCard event={event} isSubscribed={isSubscribed(event)} isPrivate={event.isPrivate} onEventClick={onEventClick} getColor={getColor} />
        )
    }

    function getSlidesBasis() {
        if (width < 992) {
            return 2
        } else {
            return 3
        }
    }

    function renderCarousel() {
        if (data && data.length <= getSlidesBasis()) {
            return (
                <Row className={styles.listContainer} >
                    {
                        orderByDate(data).map((event: Event) => {
                            return (
                                <Col key={event.id} lg={7} className={styles.listCardContainer}>
                                    {renderCard(event)}
                                </Col>
                            )
                        })
                    }
                </Row>
            )
        }
        return (data ?
            <Col>
                <Carousel
                    className={styles.carousel}
                    slidesToShow={getSlidesBasis()}
                    slidesToScroll={getSlidesBasis()}
                    infinite={false}
                    arrows={true}
                    dots={false}
                    nextArrow={<div><ChevronLeft className={styles.arrowNext} /></div>}
                    prevArrow={<div ><ChevronLeft className={styles.arrowPrev} /></div>}
                >
                    {orderByDate(data).map((event: Event) => {
                        return (
                            <Col span={22} className={styles.cardContainer} key={event.id}>
                                {renderCard(event)}
                            </Col>
                        )
                    })}
                </Carousel>
            </Col> :
            <Spinner />
        )
    }

    function renderList() {
        return (data &&
            orderByDate(data).map((event: Event, index: number) => {
                return (index < visibleItems &&
                    <Col span={24} className={styles.cardContainer} key={event.id}>
                        {renderCard(event)}
                    </Col>
                )
            })
        )
    }

    function renderButton() {
        if (data && data.length > visibleItems)
            return (
                <Col className={styles.seeMore} span={24}>
                    <Button onClick={handleSeeMore}>{i18n.t("agenda.section.upcoming.seeMore")}</Button>
                </Col>
            )

    }

    return (
        <Row gutter={[0, 30]} className={[styles.container, loading ? styles.containerLoading : ""].join(" ")}>
            <Col className={styles.sectionTitle} span={24}>
                <H3>{i18n.t('agenda.section.upcoming.title')}</H3>
                <Divider className={styles.divider} />
            </Col>
            {
                width <= 767 ?
                    <>
                        {renderList()}
                        {renderButton()}
                    </> :
                    renderCarousel()
            }
        </Row>
    )
}

export default UpcomingEvents;
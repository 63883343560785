import React from "react";
import styles from "./index.module.css";

export type SubTitleButtonProps = {
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  children?: any;
  onClick?: any;
};

const SubTitleButton: React.FC<SubTitleButtonProps> = (
  props: SubTitleButtonProps
) => {
  let styleSelection, styleDisabled;

  if (props.selected) {
    styleSelection = styles.selected;
  } else {
    styleSelection = styles.notSelected;
  }

  if (props.disabled) {
    styleDisabled = styles.disabled;
  } else {
    styleDisabled = null;
  }
  return (
      <div
        onClick={!props.disabled && props.onClick}
        className={[
          styles.subTitle,
          styleSelection,
          styleDisabled,
          props.className,
        ].join(" ")}
      >
        {props.children}
      </div>
  );
};

export default SubTitleButton;

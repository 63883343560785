import { Col, Input, List, Row, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { usePosts } from "../../../../../hooks/usePosts";
import { useQueryString } from "../../../../../hooks/useQueryString";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import i18n from "../../../../../language/i18n";
import { Post } from "../../../../../model/post/Post";
import { Tag } from "../../../../../model/tag/tag";
import TagService from "../../../../../services/tagService";
import SearchIcon from "../../../../base/icon/search";
import Pagination from "../../../../base/pagination";
import B2 from "../../../../base/text/b2";
import H5 from "../../../../base/text/h5";
import Overline from "../../../../base/text/overline";
import PostCard from "../../../post/postCard";
import FilterDesktop from "./filterDesktop";
import DropdownFiltersMobile from "./filterMobile";
import { useHistory } from 'react-router';
import styles from './index.module.css';

export type PostGridData = {
    postsOrder: 'asc' | 'desc'
    postsType: 'news' | 'note' | 'blog' | 'video' | 'event' | 'podcast'
    postsCategory: string | null
    type: 'grid_4x4',
    searchbar: boolean
}

export interface PostsGridProps {
    data: PostGridData;
}

export default function PostsGrid(props: PostsGridProps) {
    const { isPhone } = useWindowSize()
    const [page, setPage] = useState(1)
    const { postsOrder, postsType } = props.data;
    const { isTablet } = useWindowSize()
    const { search } = useLocation();
    const initialQueryString = new URLSearchParams(search);
    const initialTags = extractInitialTags()
    const initialQuery = initialQueryString.get('texto')
    const { loading, data, total, defaultSize, setSize, setFrom, query, setQuery, setTags, tags } = usePosts(initialQuery, [postsType], { field: 'creationDate', order: postsOrder }, initialTags);
    const [options, setOptions] = useState<Tag[]>([]);
    const [checkedList, setCheckedList] = useState<Tag[]>([]);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const [categoriesVisible, setCategoriesVisible] = useState<boolean>(false);
    const history = useHistory();

    useQueryString(
        { name: "texto", query: query },
        { name: "categorias", query: tags ? tags.join(',') : undefined }
    );

    useEffect(() => {

        if (!props.data.searchbar) {
            return
        }

        TagService.getAll({ types: [postsType], size: 25, sort: [{ field: "name", order: "asc" }] })
            .then((res: any) => {
                const { response } = res.data
                const mappedChekedList = response.filter((tag: Tag) => initialTags && initialTags.some(id => id === tag.id))

                setOptions(response)
                setCheckedList(mappedChekedList)
            });
        // eslint-disable-next-line
    }, [])

    function extractInitialTags() {
        if (props.data.searchbar && initialQueryString.get('categorias')) {
            return initialQueryString.get('categorias')!.split(',')
        } else if (props.data.postsCategory) {
            return [props.data.postsCategory]
        }

        return null
    }

    function handleFilterChange(list: Tag[]) {
        setCheckedList(list)
        const mappedList = list.map(item => item.id);
        setTags(mappedList)
        list.length === options.length ? setAllChecked(true) : setAllChecked(false);
        list.length && list.length !== options.length ?
            setIndeterminate(true) :
            setIndeterminate(false)
    }

    function handleClearAll() {
        setCheckedList([]);
        setIndeterminate(false);
        setAllChecked(false)
        setTags(undefined)
    }

    function handleCheckAll(event: CheckboxChangeEvent) {
        setAllChecked(event.target.checked)
        setIndeterminate(false);
        if (event.target.checked) {
            setCheckedList(options);
            setTags(options.map(item => item.id))
        } else {
            setCheckedList([]);
            setTags(undefined)
        }
    }

    async function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
        setPage(1)
        setFrom(0);
        setQuery(event.target.value);
        setSize(defaultSize);
    }

    const translateType = (type?: string) => {
        return i18n.t(`post.type.${type}`);
    }

    function handlePageChange(page: any) {
        setFrom(defaultSize * (page - 1))
        setPage(page)
    }

    function handleReachBottom() {
        setSize((prevSize: any) => prevSize === undefined ? defaultSize * 2 : prevSize * 2)
    }

    const handleVisibleCategoriesChange = (flag: boolean) => {
        setCategoriesVisible(flag);
    }

    function formatTags(tags: any[]) {
        if (!tags || !tags[0]) {
            return null
        }

        return <span className={styles.faded}>{` | ${tags[0].name}`}</span>
    }

    function renderSearchbar() {

        if (!props.data.searchbar) {
            return null
        }

        const suffix = isTablet ? undefined : (
            <div className={styles.suffixContainer}>
                <FilterDesktop
                    checkedList={checkedList}
                    optionsList={options}
                    handleFilterChange={handleFilterChange}
                    indeterminate={indeterminate}
                    allChecked={allChecked}
                    handleClearAll={handleClearAll}
                    handleCheckAll={handleCheckAll}
                    loading={loading}
                />
            </div>
        );

        return (
            <Col className={styles.searchInputContainer}>
                <Input
                    className={styles.searchInput}
                    prefix={<SearchIcon className={styles.searchInputIcon} />}
                    placeholder={i18n.t("plus.section.filters.search")}
                    value={query}
                    suffix={suffix}
                    onChange={handleSearch}
                />
                <Row className={styles.displayMobile}>
                    <Col className={[styles.tabMenuContainer, styles.noMarginBottom].join(" ")}>
                        <DropdownFiltersMobile
                            checkedList={checkedList}
                            optionsList={options}
                            loading={loading}
                            indeterminate={indeterminate}
                            allChecked={allChecked}
                            handleCheckAll={handleCheckAll}
                            handleClearAll={handleClearAll}
                            handleFilterChange={handleFilterChange}
                            handleVisibleChange={handleVisibleCategoriesChange}
                            visible={categoriesVisible}
                        />
                    </Col>
                </Row>
            </Col>
        )
    }

    function renderTotal() {
        if (total <= 0) {
            return null
        }

        return (
            <Col className={styles.resultsContainer}>
                <Overline>{total + " " + i18n.t("whoIsWho.section.filterList.results")}</Overline>
            </Col>
        )
    }

    function renderItem(item: Post) {
        if (item.type === "video" || item.type === "podcast") {
            return (
                <List.Item
                    className={styles.cardContainer}
                >
                    <PostCard
                        post={item}
                        onPostClick={() => history.push(`/broadcast/${item.id}`)}
                    />
                </List.Item>
            );
        }

        return (
            <List.Item
                className={styles.cardContainer}
            >
                <Link to={"/post/" + item.id} className={[styles.link, "noDecoration"].join(" ")}>
                    <div style={{ backgroundImage: (item.images !== undefined && item.images.length > 0) ? "url('" + item.images[0] + "')" : "" }} className={styles.image} />
                    <div className={styles.overlineContainer}><Overline className={styles.overline}>{translateType(item.type)} {formatTags(item.tags!)}</Overline></div>
                    <H5 className={styles.title}>{item.title}</H5>
                    <Typography.Paragraph ellipsis={{ rows: 4, expandable: false }} className={styles.itemBody}>{item.subTitle}</Typography.Paragraph>
                    <B2 className={[styles.date, styles.faded].join(' ')}>{moment(item.creationDate).format("DD/MM/YYYY")}</B2>
                </Link>
            </List.Item>
        )
    }

    return (
        <div className={styles.mainContainer}>
            {renderSearchbar()}
            <Row justify="start">
                <Row>
                    {renderTotal()}
                </Row>
                <div className={styles.divider} />
            </Row>
            <List
                className={styles.listWrap}
                grid={{
                    gutter: 30,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                dataSource={data}
                loading={loading}
                locale={{ emptyText: "Sem Resultados" }}
                renderItem={(item: Post) => renderItem(item)}
            />
            {total > 0 &&
                <Pagination
                    disabled={data.length === 0 || loading}
                    pageSize={defaultSize}
                    onChange={handlePageChange}
                    total={total}
                    current={page}
                    onReachBottom={handleReachBottom}
                    hasMore={total === 0 ? true : total !== data.length}
                    phonePagination={isPhone}
                    className={styles.pagination}
                />}
        </div>
    )
}

import React from 'react';
import CardItem from '../../../base/cardItem';
import styles from './index.module.css';

export interface CardWhoProps {
    cover?: any;
    defaultCardHeight: any;
    setDefaultCardHeight: any;
    onClick?: () => void;
}

function CardWho({ cover, setDefaultCardHeight, defaultCardHeight, onClick }: CardWhoProps) {

    function styleCardBody() {
        const a = {
            padding: "15px",
            height: defaultCardHeight || ""
        }
        return a
    }

    const cardItem =
        cover ?
            <CardItem
                cover={cover}
                coverClassName={styles.coverImg}
                className={styles.cardWithImage}
                setDefaultCardHeight={setDefaultCardHeight}
                onClick={onClick}
            >
            </CardItem> :
            <CardItem
                className={styles.cardWithoutImage}
                bodyStyle={styleCardBody()}
                onClick={onClick}
            >
            </CardItem>


    return cardItem
}

export default CardWho;
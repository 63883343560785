import React from 'react';

import styles from './index.module.css';
import { FaSpinner } from 'react-icons/fa';

export type SpinnerProps = {
    className?: string;
    full?: boolean;
    size?: string | number,
}

export default class Spinner extends React.Component<SpinnerProps, any> {
    render() {
        const { full, className, size } = this.props;
        return (
            <FaSpinner size={size} className={[styles.spin, (full ? styles.fullHeight : ""), className].join(' ')}></FaSpinner>
        )
    }
}   

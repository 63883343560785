import React from 'react'
import { Typography } from 'antd';

import styles from './index.module.css';
import { textProps } from '../text-interface';

const { Title } = Typography


export default function H2({ children, className, style }: textProps) {

    function getClasses() {
        const classes = [styles.title, className || ''];
        return classes.join(' ')
    }

    return (
        <Title
            className={getClasses()}
            style={style}
        >
            {children}
        </Title>)
}
import { Schemas } from '../core/Schemas';
import { LoginFailedEvent } from '../model/loginFailedEvent/LoginFailedEvent';
import { firestore } from './persistence';

const LoginFailedEventService = {
    setEvent: async function (event: LoginFailedEvent) {
        try {
            const documentReference = firestore.collection(Schemas.LOGIN_FAILED_EVENT.collection).doc()
            await documentReference.set(event);
        
            return true;
        } catch (e) {
            return false;
        }
    },

}

export default LoginFailedEventService;
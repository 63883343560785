import React, { useState } from 'react'
import { Col, Row, Skeleton } from 'antd';
import H4 from '../../../base/text/h4';
import H5 from '../../../base/text/h5';
import B2 from '../../../base/text/b2';
import SeeIcon from '../../../base/icon/see';
import styles from './index.module.css';
import i18n from '../../../../language/i18n';
import { Moment } from 'moment';
import Modal from '../../../base/modal';

const moment = require('moment');

type WelcomeConfig = {
    startDate?: Moment,
    endDate?: Moment,
    menuLink?: string,
    songName?: string,
    songArtist?: string,
    songSuggestedBy?: string,
    songLink?: string
}

export interface WelcomeProps {
    user: string;
    config?: WelcomeConfig;
}

function Welcome({ user, config }: WelcomeProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const googlePdfViewerUrl = "https://drive.google.com/viewerng/viewer?embedded=true&url="

    function openModal() {
        setIsModalOpen(true);
    }

    function closeModal() {
        setIsModalOpen(false);
    }

    function getTodayDate() {
        const date: string = moment().format('dddd, DD [de] MMMM, YYYY');
        const today = date.split('-feira').join('');
        return today;
    }

    function getMenuDateRange() {
        if (config === undefined) {
            return
        }
        const textFrom = i18n.t('section.welcome.from').toLowerCase();
        const textTo = i18n.t('section.welcome.to').toLowerCase();
        const { startDate, endDate } = config;

        if (startDate && endDate && startDate.format("MMMM") !== endDate.format("MMMM")) {
            return `${startDate.format("D")} ${textFrom} ${startDate.format("MMMM")} ${textTo} ${endDate.format("D")} ${textFrom} ${endDate.format("MMMM")}`
        }

        if (startDate && endDate) {
            return `${startDate.format("D")} ${textTo} ${endDate.format("D")} ${textFrom} ${endDate.format("MMMM")}`
        }
    }

    function renderSong() {
        if (config === undefined) {
            return
        }
        const { songLink, songName } = config;

        if (songLink === "") {
            return <B2 className={styles.musicName} >&#9835; {songName}</B2>
        } else {
            return <a href={songLink} target="_blank" className={[styles.musicName, styles.musicLink].join(" ")} rel="noreferrer" >&#9835; {songName}</a>
        }
    }

    if (config === undefined) {
        return (
            <Row className={styles.row}>
                <Skeleton title paragraph />
            </Row>
        );
    }

    const { songArtist, songSuggestedBy, menuLink } = config;

    const formatUsername = () => {
        return user.split(" ")[0];
    }
    return (
        <>
            <Row className={styles.row}>
                <div className={styles.background}></div>
                <Col className={styles.container} span={24} lg={8}>
                    <Row>
                        <Col className={styles.title}>
                            <H4>{`${i18n.t('section.welcome.hello')} ${formatUsername()}!`}</H4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={styles.text}>
                            <B2>{getTodayDate()}</B2>
                        </Col>
                    </Row>
                </Col>
                <Col className={styles.container} span={24} lg={8}>
                    <Row>
                        <Col className={styles.text} style={{ display: "grid" }}>
                            {renderSong()}
                            <B2>{songArtist}</B2>
                            <B2>sugerido por {songSuggestedBy}</B2>
                        </Col>
                    </Row>
                </Col>
                <Col className={styles.container} span={24} lg={8}>
                    <Row className={styles.dateContainer}>
                        <Col>
                            <Row >
                                <Col className={[styles.title, styles.weeklyMenu].join(' ')} onClick={openModal}>
                                    <H5>
                                        {i18n.t('section.welcome.menu')}
                                    </H5>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 5 }}>
                                <Col className={styles.text}>
                                    <B2>
                                        {`${i18n.t('section.welcome.from')} ${getMenuDateRange()}`}
                                    </B2>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={styles.seeIcon} onClick={openModal}>
                            <SeeIcon />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                className={styles.modal}
                wrapClassName={styles.wrapper}
                visible={isModalOpen}
                onCancel={closeModal}
                footer={null}
                centered={true}
            >
                {
                    menuLink?.includes(".pdf") ?
                        <iframe title='Menu' src={`${googlePdfViewerUrl}${encodeURIComponent(menuLink)}`} width="100%" />
                        : <img className={styles.menuImg} alt="menu" src={menuLink} />
                }
            </Modal>
        </>
    )
}

export default Welcome;
import React from 'react';
import { ReactComponent as Close } from '../../../../assets/images/svg/close_modal.svg';
import { IconProps } from '../interface';

function CloseModalIcon({ onClick, className }: IconProps) {
    return (
        <Close onClick={onClick} className={className} />
    )
}

export default CloseModalIcon;
import { useCallback, useEffect, useState } from "react";
import { Tag } from "../model/tag/tag";
import TagService from "../services/tagService";
import { useDebouncedSearch } from "./useDebouncedSearch";

const defaultSize = 16;
export function useTags(initialQuery?: string | null) {
    const [data, setData] = useState<Tag[]>([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState<object | undefined>(undefined);


    const getTagData = useCallback(async (query: string = "", from: number = 0, size: number = defaultSize, sort: object = [{ field: "name", order: "asc" }]) => {
        const criteria = {
            ...(query !== "" ? { query } : {}),
            ...(sort !== undefined ? { sort } : {}),
            from,
            size
        }

        try {
            const response = await TagService.getAll(criteria);
            if (total === 0) {
                setTotal(response.data.total)
            }
            return response.data
        } catch (error) {
            return { response: [] }
        }
    }, [total])

    const useGetTags = () => useDebouncedSearch(getTagData, defaultSize, initialQuery === null ? undefined : initialQuery, sort);

    const {
        setSize,
        query,
        setQuery,
        setFrom,
        searchResults
    } = useGetTags()

    useEffect(() => {
        setLoading(searchResults.loading)

        if (searchResults.result === undefined) {
            return
        }
        setData(searchResults.result.response)
    }, [searchResults])

    return {
        loading,
        data,
        setSize,
        query,
        setSort,
        setQuery,
        setFrom,
        total,
        searchResults,
        defaultSize
    }
}
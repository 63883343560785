import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import styles from './index.module.css';
import Overline from '../../../base/text/overline';
import i18n from '../../../../language/i18n';
import CloseFilterIcon from '../../../base/icon/closeFilter';

export type FiltersObject = {
    city?: string,
    department?: string,
    jobTitle?: string,
    [key: string]: string | undefined
}

export interface FiltersListProps {
    totalQueriedUsers: number;
    selectedFilters: FiltersObject;
    query?: string
    updateCriteria: (filters: FiltersObject, clearQuery: boolean) => void;
    className?: string;
    divider?: boolean
}

function FiltersList({ totalQueriedUsers, selectedFilters, updateCriteria, className, divider, query }: FiltersListProps) {
    const [showResults, setShowResults] = useState<boolean>(false)

    useEffect(() => {
        totalQueriedUsers && setShowResults(true);
    }, [totalQueriedUsers])

    function removeFilter(key: string) {
        const newFilters = Object.assign({}, selectedFilters)
        delete newFilters[key]

        updateCriteria(newFilters, false)
    }

    function removeAll() {
        updateCriteria({}, true)
    }

    function renderLabels() {
        return Object.entries(selectedFilters).map(([key, value]) => {

            if (value === undefined) {
                return null
            }

            return (
                <Col className={styles.labelContainer} onClick={() => removeFilter(key)} id={key} key={key} >
                    <Overline className={styles.bar}>|</Overline>
                    <Overline className={styles.value}>{value}</Overline>
                    <CloseFilterIcon className={styles.removeIcon} />
                </Col>
            )
        })
    }

    function renderClearAll() {
        if (Object.values(selectedFilters).some(filter => filter !== undefined) || query !== "") {
            return (
                <Col className={styles.labelContainer} onClick={removeAll}>
                    <Overline className={styles.bar}>|</Overline>
                    <Overline>{i18n.t("whoIsWho.section.filterList.clear")}</Overline>
                </Col>)
        }
    }

    function renderTotal() {
        if (!showResults) {
            return null
        }

        return (
            <Col className={styles.resultsContainer}>
                <Overline>{totalQueriedUsers + " " + i18n.t("whoIsWho.section.filterList.results")}</Overline>
            </Col>
        )
    }

    return (
        <Row className={[styles.container, className].join(" ")}>
            <Col>
                <Row>
                    {renderTotal()}
                    {renderLabels()}
                    {renderClearAll()}
                </Row>
                {divider && <div className={styles.divider} />}
            </Col>
        </Row>
    )
}

export default FiltersList;
import { Col } from "antd";
import React, { ReactElement } from "react";
import Paragraph from "../../../../base/text/paragraph";
import { translateSearchbarOptions } from "../../../../../helpers/header/translateSearchbarOptions";
import { OmniType } from "../../../../../model/omni/OmniType";
import styles from './index.module.css';

export type ItemProps = {
    items: ReactElement
    footer: ReactElement
    itemsContainerClassName?: string
    type: OmniType
}

function Section({ items, footer, type, itemsContainerClassName }: ItemProps) {
    return (
        <div>
            <Paragraph className={styles.sectionHeader}>{translateSearchbarOptions(type)}</Paragraph>
            <Col className={[styles.sectionItemsContainer, itemsContainerClassName].join(' ')}>
                {items}
            </Col>
            {footer}
        </div>
    )
}

export default Section;
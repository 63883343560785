import { firestore } from "../services/persistence";
import { firestore as FirestoreType } from "firebase";


export async function SubscribeEvent(eventId: string | null, userId: string | undefined) {
    let unsubscribeSnapshot: () => void | undefined;
    let timeoutId: NodeJS.Timeout | undefined;

    return new Promise<void>(async (resolve, reject) => {
        let eventSubscribeRequestRef: FirestoreType.DocumentReference;
        try {
            eventSubscribeRequestRef = await firestore.collection("request").add({
                action: "subscribe",
                kind: "event",
                creationDate: new Date(),
                status: "pending",
                createdByRef: firestore.collection('users').doc(userId),
                data: {
                    "userId": userId,
                    "eventId": eventId
                }
            })
        } catch (error) {
            console.error(error);
            return reject({ status: "error.event-request.unknown-error" })
        }
        // Listen to status change
        unsubscribeSnapshot = eventSubscribeRequestRef.onSnapshot(
            snap => {
                const requestData: any = snap.data();

                switch (requestData.status) {
                    case "success":
                        return resolve();
                    case "failure":
                        return reject({ status: requestData.errorCode || "error.event-request.unknown-error", message: requestData.errorMessage });
                }
            },
            err => {
                console.error(err);
                return reject({ status: "error.event-request.unknown-error" });
            }
        );

        // Set a timeout in case no state changes happen
        timeoutId = setTimeout(
            reject => {
                reject({ status: "error.timeout" });
            },
            30000,
            reject
        );
    }).finally(() => {
        // Clear listener and timeout on promise reject/resolve
        if (unsubscribeSnapshot) unsubscribeSnapshot();
        if (timeoutId) clearTimeout(timeoutId);
    });

}
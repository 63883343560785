import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import styles from './index.module.css';
import Overline from '../../../base/text/overline';
import i18n from '../../../../language/i18n';
import H3 from '../../../base/text/h3';
import CloseModalIcon from '../../../base/icon/closeModal';

export interface CategoriesListProps {
    totalQueriedPartnerships: number;
    label: string;
    checkedList: string[];
    categories: any[];
    setCheckedList: any;
    setCategories: any;
    clearFilters: () => void;
    className?: string;
    divider?: boolean
}

function CategoriesList({ totalQueriedPartnerships, label, checkedList, categories, setCheckedList, setCategories, clearFilters, className, divider }: CategoriesListProps) {
    const [showResults, setShowResults] = useState<boolean>(false)
    useEffect(() => {
        totalQueriedPartnerships && setShowResults(true);
    }, [totalQueriedPartnerships])

    function getLabel() {
        if (!checkedList.length) return i18n.t("plus.section.filters.all");
        if (checkedList.length >= 1) {
            return label;
        }
    }

    function removeFilter(index: number) {
        const deepClone = [...checkedList]
        deepClone.splice(index, 1)

        if (deepClone.length === 0) {
            clearFilters();
        }
        setCheckedList(deepClone);
        setCategories(deepClone);
    }

    function renderLabels() {
        const activeCategories = categories.filter((item: any) => checkedList.includes(item.value));

        const labels = activeCategories.map((item: any, index: number) => (
            <Col className={styles.labelContainer} onClick={() => removeFilter(index)} id={item.value} key={item.value} >
                <Overline className={styles.bar}>|</Overline>
                <Overline >{item.label} </Overline>
                <CloseModalIcon className={styles.removeIcon} />
            </Col>
        ))
        return labels
    }

    function clearAll() {
        clearFilters();
        setCategories([]);
    }

    function renderClearAll() {
        if (checkedList.length) {
            return (
                <Col className={styles.labelContainer} onClick={clearAll}>
                    <Overline className={styles.bar}>|</Overline>
                    <Overline>{i18n.t("plus.section.filters.clear")}</Overline>
                </Col>)
        }
    }

    return (
        <Row className={[styles.container, className].join(" ")}>
            <Col>
                <Row className={styles.mainLabel}>
                    <Col>
                        <H3 className={styles.mainLabelText}>{getLabel()}</H3>
                    </Col>
                </Row>
                <Row>
                    {
                        showResults && (
                            <Col className={styles.resultsContainer}>
                                <Overline>{totalQueriedPartnerships + " " + i18n.t("plus.section.filters.results")}</Overline>
                            </Col>)
                    }
                    {renderLabels()}
                    {renderClearAll()}
                </Row>
                {
                    divider ?
                        <div className={styles.divider} /> : undefined
                }
            </Col>
        </Row>
    )
}

export default CategoriesList;
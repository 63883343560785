import React from 'react';
import PageContainer from '../../components/base/pageContainer';
import Paragraph from '../../components/base/text/paragraph';
import authentication from '../../services/authentication';
import styles from './index.module.css';

export default function AccessDeniedPage(props: any) {

    function onLogout(event: any) {
        event.preventDefault();
        authentication.logout();
    }

    return (
        <PageContainer className={styles.container}>
            <Paragraph className={styles.paragraph}> Sem Permissões </Paragraph>
            <a href="/logout" onClick={onLogout} className={styles.logout}>Logout</a>
        </PageContainer>
    )

}
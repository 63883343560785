import { useEffect, useState } from "react";

export function useResizeWindow() {
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
    useEffect(() => {
        function updateSize() {
            setWindowSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener("resize", updateSize)

        return () => window.removeEventListener("resize", updateSize)
    }, [])
    return windowSize
}
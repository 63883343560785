import { functions } from './persistence';

const PartnershipCategoryService = {
    getAll: async function () {
        try {
            const response = await functions.httpsCallable("searchPartnershipCategory")({ size: 15, sort: [{ field: 'name', order: 'asc' }] })

            return response.data.response.map((c: any) => {
                const { name, id } = c;

                return { value: id, label: name }
            })
        } catch (e) {
            console.log(e);
        }
    },
}

export default PartnershipCategoryService

import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Col, Drawer, message, Modal, Row } from 'antd';
import styles from './index.module.css';
import { Event } from '../../../../model/event/event';
import CloseModalIcon from '../../../base/icon/closeModal';
import Overline from '../../../base/text/overline';
import H3 from '../../../base/text/h3';
import { formatDate } from '../../../../helpers/agenda/formatEventDate';
import B2 from '../../../base/text/b2';
import MapPinIcon from '../../../base/icon/map-pin';
import PhoneIcon from '../../../base/icon/phone';
import LinkIcon from '../../../base/icon/link';
import Button from '../../../base/button-v2';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import TickIcon from '../../../base/icon/tick';
import i18n from '../../../../language/i18n';
import { cleanHTML } from '../../../../helpers/cleanHTML';
import { SubscribeEvent } from '../../../../helpers/subscribeEvent';
import { isHighlightedEvent } from '../../../../model/highlightedEvent/highlightedEvent';
import AuthenticationContext from '../../../auth/authenticationContext';
import { formatLink } from '../../../../helpers/formatLink';
import MailIcon from '../../../base/icon/mail';
import B1 from '../../../base/text/b1';
import moment from 'moment';

interface EventModalProps {
    modalData: Event | null;
    onClose: () => void;
    afterVisibleChange: () => void;
    isVisible: boolean;
    isSubscribed: (event: Event | null) => boolean | null | undefined;
    getColor: (event: Event) => string;
    refreshEvents: () => void;
    subscribeSuccess?: boolean;
    setSubscribeSuccess?: Dispatch<SetStateAction<boolean>>;
}


function EventModal({
    modalData,
    onClose,
    afterVisibleChange,
    isVisible,
    isSubscribed,
    getColor,
    refreshEvents,
    subscribeSuccess,
    setSubscribeSuccess
}: EventModalProps) {
    const { width } = useWindowSize();
    const [loadingSave, setLoadingSave] = useState<boolean>(false)

    const context = useContext(AuthenticationContext);
    const userId = context?.auth?.uid;

    async function subscribeEvent() {
        setLoadingSave(true);
        try {
            await SubscribeEvent(isHighlightedEvent(modalData) ? modalData.eventId : modalData!.id, userId);
            await new Promise(resolve => setTimeout(resolve, 8000)).then(() => {
                refreshEvents();
            });

            setSubscribeSuccess && setSubscribeSuccess(true);
            setLoadingSave(false);
        } catch (error: any) {
            let messageString: string
            switch (error.status) {
                case 'error.event-request.unknown-error':
                default:
                    messageString = 'Ocorreu um erro, tente mais tarde.'
                    message.error({ content: (<B2>{messageString}</B2>) })
                    break;
            }
            refreshEvents();
            setLoadingSave(false)
        }
    }

    function renderIconsSection() {
        return (
            <div className={styles.iconsContainer}>
                {
                    modalData && modalData.location ?
                        <div>
                            <MapPinIcon className={styles.icon} />
                            <a className={"noDecoration"} href={`https://maps.google.com/?q=${modalData.location.address}`}>
                                <B2>{modalData.location.name}</B2>
                            </a>
                        </div> : null
                }
                {
                    modalData && modalData.phone ?
                        <div>
                            <PhoneIcon className={styles.icon} />
                            <a className={"noDecoration"} href={`tel:${modalData?.phone}`}>
                                <B2>{modalData?.phone}</B2>
                            </a>
                        </div> : null
                }
                {
                    modalData && modalData.email ?
                        <div>
                            <MailIcon className={[styles.icon, styles.mailIcon].join(" ")} />
                            <a className={"noDecoration"} href={`mailto: ${modalData.email}`}>
                                <B2>{modalData.email}</B2>
                            </a>
                        </div> : null
                }
                {
                    modalData && modalData.usefulLinks && modalData.usefulLinks.length > 0 ?
                        <div className={styles.linksGroup}>
                            <LinkIcon className={[styles.icon, styles.linksIcon].join(" ")} />
                            <div className={styles.linksContainer}>
                                {
                                    modalData.usefulLinks.map(link => (
                                        <div className={styles.linkText}>
                                            <a className={"noDecoration"} href={link}>
                                                <B2>{formatLink(link)}</B2>
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>
                        </div> : null
                }
            </div>
        )
    }

    function renderCategory() {
        return (modalData?.tags?.length ?
            <div className={styles.category}>
                <div style={{ backgroundColor: getColor(modalData) }}></div>
                <Overline>{modalData?.tags[0].name}</Overline>
            </div> :
            null
        )
    }

    function renderHeaderTitle() {
        return <Overline className={styles.headerTitle}>Eventos</Overline>
    }

    function renderImage() {
        return modalData?.images && modalData?.images.length > 0 && <img src={modalData?.images[0]} alt={modalData?.title} className={styles.image} />
    }

    function renderEventTitle() {
        return (
            <>
                <H3>{modalData?.title}</H3>
                <B1 className={styles.subtitle}> {modalData?.subTitle} </B1>
            </>
        )
    }

    function renderDate() {
        return (
            <B2 className={styles.date}>
                {formatDate(modalData, true)}
            </B2>
        )
    }

    function renderFooter() {
        if (modalData?.isPrivate) {
            return null;
        }

        if (isSubscribed(modalData)) {
            return (
                <Row className={styles.subscriptionRow}>
                    <Col className={styles.subscription} span={24}>
                        <div className={styles.tickIcon}>
                            <TickIcon />
                        </div>
                        <Overline>{i18n.t("agenda.section.modal.subscribed")}</Overline>
                    </Col>
                </Row>
            )
        }
    }

    function renderSubscribeButton() {
        if (subscribeSuccess) {
            return (
                moment(modalData?.startDate).isAfter(moment()) && <Button
                    className={width < 576 ? styles.subscribe : styles.subscribeSuccess}
                    filling="default"
                    type="enabled"
                >
                    {i18n.t("agenda.section.modal.subscribed")}
                </Button>
            )
        } else {
            return (
                moment(modalData?.startDate).isAfter(moment()) && <Button
                    loading={loadingSave}
                    onClick={subscribeEvent}
                    className={width < 576 ? styles.subscribe : styles.subscribeButton}
                    filling="primary"
                    type="enabled"
                >
                    {i18n.t("agenda.section.modal.subscribe")}
                </Button>
            )
        }
    }

    return (width < 576 ?
        <Drawer
            visible={isVisible}
            onClose={loadingSave ? () => { } : onClose}
            placement="bottom"
            className={styles.drawer}
            closeIcon={<CloseModalIcon className={[styles.closeIcon, loadingSave ? styles.loadingCloseIcon : "",].join(" ")} />}
            title={renderHeaderTitle()}
            afterVisibleChange={afterVisibleChange}
            destroyOnClose
            maskClosable={!loadingSave}
            zIndex={10000}
            
        >
            <Row className={styles.row}>
                <Col span={24} >
                    {renderImage()}
                    {renderCategory()}
                    {renderEventTitle()}
                    {renderDate()}
                    {
                    isSubscribed(modalData) ?
                        <Col className={[styles.subscription, styles.subscriptionMobile].join(" ")} span={24}>
                            <div className={styles.tickIcon}>
                                <TickIcon />
                            </div>
                            <Overline>{i18n.t("agenda.section.modal.subscribed")}</Overline>
                        </Col>
                        :
                        <Col>
                            {renderSubscribeButton()}
                        </Col>
                    }
                    {renderIconsSection()}
                    { modalData?.body && <div className={styles.body}>
                        <B2 className={styles.bodyTitle}>{i18n.t("agenda.sections.modal.bodyTitle")}</B2>
                        <div dangerouslySetInnerHTML={{ __html: cleanHTML(modalData?.body || "") }} className={styles.bodyText}></div>
                    </div>}
                    {
                        modalData?.instructions &&
                        <div className={styles.body}>
                            <B2 className={styles.bodyTitle}>{i18n.t("agenda.sections.modal.instructionsTitle")}</B2>
                            <div dangerouslySetInnerHTML={{ __html: cleanHTML(modalData?.instructions || "") }} className={styles.bodyText}></div>
                        </div>
                    }
                </Col>
            </Row>
        </Drawer> :
        <Modal
            visible={isVisible}
            onCancel={loadingSave ? undefined : onClose}
            title={renderHeaderTitle()}
            className={[styles.modal, isSubscribed(modalData) && styles.userAdded].join(" ")}
            closeIcon={<CloseModalIcon className={[styles.closeIcon, loadingSave ? styles.loadingCloseIcon : "",].join(" ")} />}
            destroyOnClose
            footer={renderFooter()}
            zIndex={10000}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <Row>
                <Col span={24}>
                    {renderImage()}
                </Col>
                <Col span={24}>
                    {renderCategory()}
                </Col>
                <Col span={12}>
                    {renderEventTitle()}
                    {renderDate()}
                    {renderSubscribeButton()}
                    {renderIconsSection()}
                </Col>
                <Col span={11} offset={1} >
                    {modalData?.body && <div className={styles.body}>
                        <B2 className={styles.bodyTitle}>{i18n.t("agenda.sections.modal.bodyTitle")}</B2>
                        <div dangerouslySetInnerHTML={{ __html: cleanHTML(modalData?.body || "") }} className={styles.bodyText} />
                    </div>}
                    {

                        modalData?.instructions &&
                        <div className={[styles.body, styles.instructions].join(" ")}>
                            <B2 className={styles.bodyTitle}>{i18n.t("agenda.sections.modal.instructionsTitle")}</B2>
                            <div dangerouslySetInnerHTML={{ __html: cleanHTML(modalData?.instructions || "") }} className={styles.bodyText} />
                        </div>
                    }
                </Col>
            </Row>
        </Modal>
    )
}

export default EventModal;
import { Row } from "antd";
import React from "react";
import H1 from "../../../../base/text/h1";
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.css';

export interface TitleProps {
  data?: Array<any>;
}

export default function Title(props: TitleProps) {
  if (props.data === undefined || props.data.length === 0) {
    return null;
  }

  const data = props.data[0];

  return (
    data.body && (
      <Row className={styles.wrapper}>
        <H1>{ ReactHtmlParser(data.body || "") }</H1>
      </Row>
    )
  );
}

import React from 'react';
import { Switch, useLocation, withRouter } from 'react-router-dom';

import { ProtectedRoute } from '../components/auth/protectedRoute';
import { AnonymousRoute } from '../components/auth/anonymousRoute';
import withAuthentication from '../components/auth/withAuthentication';
import { Claims } from '../model/auth/Claims';

import Home from '../pages/home';
import LoginPage from '../pages/login';
import PlusPlmj from '../pages/plus-plmj';
import WhoIsWho from '../pages/who-is-who';

import 'antd/dist/antd.css';
import '../styles/global.css';
import Teams from '../pages/teams';
import Agenda from '../pages/agenda';
import PageTemplate from '../pages/template';
import { Layout } from 'antd';
import styles from './index.module.css';
import Results from '../pages/agenda/results';
import PostPage from '../pages/post';
import Broadcast from '../pages/broadcast';
import VideoPage from '../pages/video';
import OmniResults from '../pages/omniResults';
import PageNotFound from '../pages/pageNotFound';
import Categories from '../pages/categories';

function App() {
    const location = useLocation();

    return (
        <Layout className={[styles.main, location.pathname === "/login" ? styles.noMargin : ""].join(" ")} id="mainSection">
            <Switch>
                <AnonymousRoute title="Login" path="/login" pageWrap={false} exact component={LoginPage} />
                <ProtectedRoute title="Plus PLMJ" path="/plus-plmj" exact component={PlusPlmj} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="Quem é Quem" path="/quem-e-quem" exact component={WhoIsWho} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="Equipas" path="/equipas" exact component={Teams} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="Agenda" path="/agenda" exact component={Agenda} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute path="/page/:link+" exact component={PageTemplate} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute path="/post/:id" exact component={PostPage} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute path="/broadcast/:id" exact component={VideoPage} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="Broadcast" path="/broadcast" exact component={Broadcast} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="Agenda" path="/agenda/resultados" exact component={Results} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="Pesquisa" path="/resultados" exact component={OmniResults} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="Categorias" path="/categorias" exact component={Categories} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="" path="/" pageWrap wrap exact component={Home} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
                <ProtectedRoute title="" component={PageNotFound} claims={[Claims.ADMINISTRATOR, Claims.EDITOR, Claims.VIEWER]} />
            </Switch>
        </Layout>
    );
}

export default withAuthentication(withRouter(App));
import { Col, Dropdown, Menu, Row } from "antd";
import React from "react";
import H5 from "../../../base/text/h5";
import styles from './index.module.css';
import ChevronDown from '../../../base/icon/chevronDown';
import i18n from "../../../../language/i18n";
import { useWindowSize } from "../../../../hooks/useWindowSize";

interface departmentsProps {
    departmentsData: any[],
    departmentSelected: any,
    setDepartment: any
    loading?: boolean
}

export default function DepartmentsList({ departmentsData, departmentSelected, setDepartment, loading }: departmentsProps) {
    const { isTablet } = useWindowSize()

    function handleClick(e: any) {
        setDepartment(departmentsData[e.key].data.name)
    }

    function menuDepartments() {
        return (
            <Menu onClick={handleClick}>
                {departmentsData.map((item, index) => {
                    return (< Menu.Item key={index} >
                        <p> {item.data.name}</p>
                    </Menu.Item>
                    )
                })
                }
            </Menu >
        )
    }


    return (
        <>

            {departmentsData.length >= 1 ?
                <>
                    {isTablet ?
                        <Col span={24}  >
                            <Dropdown
                                overlayClassName={styles.menuItem}
                                overlay={menuDepartments}
                                trigger={['click']}
                            >
                                <Row justify="space-between" align="middle" className={[styles.dropdownRow2].join(' ')} >
                                    <Col>
                                        <H5 className={[styles.title].join(' ')}>{departmentSelected || i18n.t("teams.section.departmentsList.selectDepartment")}</H5>
                                    </Col>
                                    <Col>
                                        <ChevronDown />
                                    </Col>
                                </Row>
                            </Dropdown>
                        </Col>
                        :
                        <>
                            <Row className={[styles.departmentRow, loading && styles.loading].join(' ')} >
                                {departmentsData.map((item, index) => {
                                    const selectedDepartmentStyle = departmentSelected === item.data.name ? styles.selectedDepartment : undefined
                                    return (
                                        <Col onClick={() => setDepartment(item.data.name)} className={[selectedDepartmentStyle, styles.departmentContainer].join(" ")} span={7} key={index}>
                                            <H5 className={[styles.department].join(' ')}>{item.data.name}</H5>
                                            <ChevronDown className={styles.ChevronDownDepartments} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </>

                    }
                </>
                : undefined}
        </>
    )
}
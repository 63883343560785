import React, { Fragment, useState } from 'react';
import { Col, Input, Row, Space, Checkbox, Dropdown, List } from 'antd';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import FiltersDesktop from '../filtersDesktop';
import SearchIcon from '../../../base/icon/search';
import Pagination from '../../../base/pagination';
import CardPlus from '../cardPlus';
import styles from './index.module.css';
import Button from '../../../base/button-v2';
import H4 from '../../../base/text/h4';
import ChevronDown from '../../../base/icon/chevronDown';
import i18n from '../../../../language/i18n';
import CategoriesList from '../categoriesList';

export interface CardListProps {
    setModalIsOpen: (data: {
        title: string,
        discount: number,
        image: string,
        description: string,
        location: string,
        link: string
        instructions: string
        email: string,
        email_body: string,
        categories: [any],
    }) => void;

    checkAll: boolean;
    checkedList: string[];
    onCheckboxChange: (list: any[]) => void;
    onCheckAllChange: (e: any) => void;
    categories: any[];
    clearAll: () => void;
    setCheckedList: any;
    setCheckedAll: any;
    initialState: any;
    partnerships: any;
    indeterminate: boolean;
    setIndeterminate: any;
}

function CardsList({ setModalIsOpen, onCheckAllChange, onCheckboxChange, checkAll, checkedList, setCheckedList, categories, clearAll, partnerships, initialState, indeterminate, setIndeterminate, setCheckedAll }: CardListProps) {
    const [page, setPage] = useState(1)
    const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
    const { isPhone, isTablet } = useWindowSize();
    const {
        loading,
        data,
        setSize,
        query,
        setSort,
        setQuery,
        setFrom,
        setDiscount,
        setCategories,
        queriedPartnershipsTotal,
        searchResults,
        defaultSize
    } = partnerships;

    function onClickCard(index: number) {
        const partner = data[index];
        setModalIsOpen(partner);
    }

    async function handleSearch(event: any) {
        setPage(1)
        setFrom(0)
        setQuery(event.target.value);
        setSize(defaultSize)
    }

    function handlePageChange(page: any) {
        setPage(page)
        setFrom(defaultSize * (page - 1))
    }

    function handleReachBottom() {
        setSize((prevSize: any) => prevSize * 2)
    }

    function handleSort(sort: any) {
        setSort(sort);
    }

    function handleDiscount(discount: any) {
        setPage(1)
        setFrom(0)
        setDiscount(discount);
    }

    function handleCategories(categories: any) {
        setPage(1)
        setFrom(0)
        setCategories(categories);
    }

    async function handleVisibleChange(flag: boolean) {
        if (!flag) {
            initialState.checkAll = checkAll;
            initialState.checkedList = checkedList;

            setCategories(checkedList);
        }
        setVisibleFilter(flag);
    };

    function clearFilters() {
        clearAll();
    }

    function getSelectedLabel() {
        if (checkedList.length === 1) {
            const [id] = checkedList;
            const selectedOption = categories.find((o: any) => o.value === id);
            return selectedOption.label;
        }

        if (checkedList.length > 1) {
            return `${i18n.t("plus.section.filters.several")} (${checkedList.length})`;
        }

        return i18n.t("plus.section.filters.select");
    }

    const menu = (
        <Space direction="vertical">
            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                className={styles.selectAll}
                style={{ marginTop: 9 }}
            >
                {i18n.t("plus.section.filters.all")}
            </Checkbox>
            <Checkbox.Group
                options={categories}
                value={checkedList}
                onChange={onCheckboxChange}
                className={styles.checkboxGroup}
                style={{ display: 'flex', flexDirection: 'column' }}
            />
            <Button
                filling="text"
                className={styles.clearButton}
                onClick={clearFilters}
            >
                {i18n.t("plus.section.filters.clear")}
            </Button>
        </Space>
    )

    function renderPaginationSection() {

        if (queriedPartnershipsTotal === 0) {
            return undefined
        }

        return (
            <Pagination
                disabled={searchResults.result === undefined || loading}
                pageSize={defaultSize}
                onChange={handlePageChange}
                total={queriedPartnershipsTotal}
                current={page}
                onReachBottom={handleReachBottom}
                hasMore={queriedPartnershipsTotal === 0 ? true : queriedPartnershipsTotal !== data.length}
                phonePagination={isPhone}
                className={styles.pagination}
            />
        )
    }

    function renderContent() {
        const loadingStyle = loading === true ? styles.cardListContainerLoading : undefined

        const suffix = isTablet ? undefined : (
            <div className={styles.suffixContainer}>
                <FiltersDesktop
                    categoryOptions={categories}
                    onSortSelect={handleSort}
                    onDiscountSelect={handleDiscount}
                    onCategoriesSelect={handleCategories}
                    checkedList={checkedList}
                    setCheckedList={setCheckedList}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckedAll}
                />
            </div>
        );

        const content = (
            <Fragment>
                <Row>
                    <Col className={styles.searchInputContainer}>
                        <Input
                            className={styles.searchInput}
                            prefix={<SearchIcon className={styles.searchInputIcon} />}
                            placeholder={"Pesquisar"}
                            value={query}
                            suffix={suffix}
                            onChange={handleSearch}
                        />
                    </Col>
                </Row>
                <CategoriesList
                    totalQueriedPartnerships={queriedPartnershipsTotal}
                    label={getSelectedLabel()}
                    checkedList={checkedList}
                    categories={categories}
                    setCheckedList={setCheckedList}
                    setCategories={setCategories}
                    clearFilters={clearFilters}
                    className={styles.listLarge}
                    divider
                />
                <Row className={styles.filter}>
                    <Col span={24} className={styles.dropdownContainer}>
                        <Dropdown
                            overlay={menu}
                            onVisibleChange={handleVisibleChange}
                            visible={visibleFilter}
                            overlayClassName={styles.overlay}
                            trigger={['click']}
                        >
                            <Row justify="space-between" align="middle" className={styles.dropdownRow}>
                                <Col>
                                    <H4>{getSelectedLabel()}</H4>
                                </Col>
                                <Col>
                                    <ChevronDown />
                                </Col>
                            </Row>
                        </Dropdown>
                    </Col>

                    <Col>
                        <CategoriesList
                            totalQueriedPartnerships={queriedPartnershipsTotal}
                            label={getSelectedLabel()}
                            checkedList={checkedList}
                            categories={categories}
                            setCheckedList={setCheckedList}
                            setCategories={setCategories}
                            clearFilters={clearFilters}
                            className={styles.listSmall}
                        />
                    </Col>
                </Row>

                <List
                    grid={{
                        gutter: 20,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 4,
                    }}
                    className={[styles.cardListContainer, loadingStyle].join(" ")}
                    locale={{ emptyText: "Sem Resultados" }}
                    dataSource={data}
                    renderItem={(item: any, index: number) => {
                        return (
                            <List.Item className={styles.cardListItem}>
                                <CardPlus
                                    discount={item.discount}
                                    cover={item.image}
                                    title={item.title}
                                    link={item.link}
                                    location={item.location}
                                    onClick={() => onClickCard(index)}
                                    description={item.description}
                                />
                            </List.Item>
                        )
                    }}
                />

                {renderPaginationSection()}
            </Fragment>
        )

        return content
    }
    return (renderContent())
}

export default CardsList;
import { Checkbox, Col, Dropdown, Input, Row, Space } from "antd";
import React, { Fragment, useState } from "react";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import i18n from "../../../../language/i18n";
import ChevronDown from "../../../base/icon/chevronDown";
import SearchIcon from "../../../base/icon/search";
import H4 from "../../../base/text/h4";
import FiltersDesktop from "../results/filtersDesktop";
import Button2 from '../../../base/button-v2';
import styles from './index.module.css';
import { Tag } from "../../../../model/tag/tag";
import { useHistory } from "react-router";
import { ChangeEvent } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface SearchBarFiltersProps {
    eventTags: Tag[]
}

function SearchBarFilters({ eventTags }: SearchBarFiltersProps) {
    const { isTablet } = useWindowSize();
    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const [checkedList, setCheckedList] = useState<Tag[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const history = useHistory();

    async function handleVisibleChange(flag: boolean) {
        setVisibleFilter(flag);
    };

    async function handleSearch(event: ChangeEvent<HTMLInputElement>) {
        setQuery(event.target.value);
    }

    async function handleEnter() {
        const queryText = query.length ? `query=${query}` : ""

        history.push({
            pathname: "/agenda/resultados",
            search: activeFilters.length ? `filtros=${activeFilters}&${queryText}` : queryText
        })
    }

    function onCheckboxChange(list: any[]) {
        setActiveFilters(list.map((item) => item.id))
        setCheckedList(list);
        setCheckAll(list.length === eventTags!.length);
        setIndeterminate(!!list.length && list.length < eventTags!.length);
    }

    function onCheckAllChange(e: CheckboxChangeEvent) {
        if (e.target.checked) {
            const eventTagsIdList = eventTags!.map((tag: Tag) => tag.id);
            setCheckedList(eventTags!);
            setActiveFilters(eventTagsIdList)
        } else {
            setCheckedList([]);
        }
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    function clearAll() {
        setActiveFilters([])
        setCheckedList([]);
        setCheckAll(false);
        setIndeterminate(false);
    }

    function getSelectedLabelMobile() {
        if (checkedList.length === 1) {
            return checkedList[0].name
        }

        if (checkedList.length > 1) {
            return `${i18n.t("plus.section.filters.several")} (${checkedList.length})`;
        }
        return i18n.t("plus.section.filters.select");
    }


    const menu = (
        <Space direction="vertical" className={styles.dropdownOptionsContainer}>
            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                className={styles.selectAll}
            >
                {i18n.t("plus.section.filters.all")}
            </Checkbox>
            <Checkbox.Group
                onChange={onCheckboxChange}
                className={styles.checkboxGroup}
                style={{ display: 'flex', flexDirection: 'column' }}
                value={checkedList as any}
            >   {
                    eventTags?.map((item: Tag) => <Checkbox className={styles.selectOption} value={item} key={item.id}> {item.name}</Checkbox>)
                }
            </Checkbox.Group>
            <Button2
                filling="text"
                className={styles.clearButton}
                onClick={clearAll}
            >
                {i18n.t("plus.section.filters.clear")}
            </Button2>
        </Space >
    )

    function render() {
        const suffix = isTablet ? undefined : (
            <div className={styles.suffixContainer}>
                <FiltersDesktop
                    checkedList={checkedList}
                    setCheckedList={setCheckedList}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                    eventTypes={eventTags || []}
                    setActiveFilters={setActiveFilters}
                />
            </div>
        );

        const content = (
            <Fragment>
                <Row className={styles.searchInputRow}>
                    <Col className={styles.searchInputContainer}>
                        <Input
                            className={styles.searchInput}
                            prefix={<SearchIcon className={styles.searchInputIcon} />}
                            placeholder={"Pesquisar"}
                            value={undefined}
                            suffix={suffix}
                            onChange={handleSearch}
                            onPressEnter={handleEnter}
                        />
                    </Col>
                </Row>

                <Row className={styles.filter}>
                    <Col span={24} className={styles.dropdownContainer}>
                        <Dropdown
                            overlay={menu}
                            onVisibleChange={handleVisibleChange}
                            visible={visibleFilter}
                            overlayClassName={styles.overlay}
                            trigger={['click']}
                        >
                            <Row justify="space-between" align="middle" className={styles.dropdownRow}>
                                <Col>
                                    <H4>{getSelectedLabelMobile()}</H4>
                                </Col>
                                <Col>
                                    <ChevronDown />
                                </Col>
                            </Row>
                        </Dropdown>
                    </Col>
                </Row>
            </Fragment>
        )
        return content
    }

    return render()
}
export default SearchBarFilters;
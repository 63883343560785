import { useState, useEffect } from "react";

export function useWindowSize() {
    
        function getWindowSize() {
            return {
                width: window.innerWidth, 
                height: window.innerHeight,
                isTablet: window.innerWidth < 1024,
                isPhone: window.innerWidth < 768,
            }
        }
    
    
    const [windowSize, setWindowSize] = useState(getWindowSize);

    useEffect(() => {
        const handleResizeListener = () => {
            return window.addEventListener("resize", () => setWindowSize(getWindowSize()));
        }
        handleResizeListener()
        return () => window.removeEventListener('resize', handleResizeListener);
    }, [])


    return windowSize;
}
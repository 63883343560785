import { useCallback, useEffect, useState } from "react";
import PostsService from "../services/postsService";
import { useDebouncedSearch } from "./useDebouncedSearch";

const defaultSize = 8;
export function useBroadcast(initialQuery?: string | null, initialTags?: string[] | null) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState<object | undefined>(undefined);
    const [type, setType] = useState<string>("all");
    const [tags, setTags] = useState<Array<string> | undefined>(initialTags || undefined);

    const getTypeCriteria = (type: string) => {
        if (type === "video") {
            return ["video"];
        }

        if (type === "podcast") {
            return ["podcast"];
        }

        return ["video", "podcast"];
    }

    const getBroadcastData = useCallback(async (query: string = "", from: number = 0, size: number = defaultSize, type: string, sort: object = [{ field: "updatedDate", order: "desc" }], tags: string[] = []) => {
        const criteria = {
            ...(query !== "" ? { query } : {}),
            ...(sort !== undefined ? { sort } : {}),
            ...{ types: getTypeCriteria(type) },
            ...(tags !== undefined ? { tags } : {}),
            from,
            size
        }

        try {
            const response = await PostsService.getAll(criteria);
            if (total === 0) {
                setTotal(response.data.total)
            }
            return response.data;
        } catch (e) {
            return { response: [] };
        }
    }, [total]);


    const useGetBroadcast = () => useDebouncedSearch(getBroadcastData, defaultSize, initialQuery === null ? undefined : initialQuery, type, sort, tags);

    const {
        setSize,
        query,
        setQuery,
        setFrom,
        searchResults
    } = useGetBroadcast();

    useEffect(() => {
        setLoading(searchResults.loading)

        if (searchResults.result === undefined) {
            return
        }

        setData(searchResults.result.response)
    }, [searchResults])

    return {
        loading,
        data,
        setSize,
        query,
        setSort,
        setQuery,
        setFrom,
        setType,
        total,
        searchResults,
        defaultSize,
        tags,
        setTags
    }
}
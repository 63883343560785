import { firestore } from "./persistence";
import { firestore as FirestoreType } from "firebase";

const headerTabsService = {
    getTabsList: async function (userId: string) {
        const userRef = firestore.collection('users').doc(userId);
        const userSnapshot = await userRef.collection('tabs').doc('_').get();
        let tabs = [];

        if (!userSnapshot.exists) {
            userRef.collection('tabs').doc('_').set({tabs:[]}).catch((error)=>{
                throw new Error('Could not create in user document -> ' + error);
            })
        } else{
            tabs = userSnapshot.data()!.tabs;
        }


        if(!tabs){
            return undefined;
        }

        return tabs;
    },
    addTab: async function (userId:string, tab:{name:string, uri:string}) {
        const userRef = firestore.collection('users').doc(userId).collection('tabs').doc('_');
        const userSnapshot = await userRef.get();

        if (!userSnapshot.exists) {
            throw new Error('Could not fetch user document')
        }

        const tabs = userSnapshot.data()!.tabs;

        if(!tabs){
            userRef.update({tabs:[tab]}).then(()=>{
                return true;
            })
        } else{
            userRef.update({tabs: FirestoreType.FieldValue.arrayUnion(tab)}).then(()=>{
                return true
            })
        }
    },
    removeTab: async function (userId:string, tab:{name:string, uri:string}) {
        const userRef = firestore.collection('users').doc(userId).collection('tabs').doc('_');
        const userSnapshot = await userRef.get();

        if (!userSnapshot.exists) {
            throw new Error('Could not fetch user document');
        }

        const tabs = userSnapshot.data()!.tabs;

        if(!tabs || tabs === []){
            return [];
        } else{
            userRef.update({tabs: FirestoreType.FieldValue.arrayRemove(tab)}).then(tabs=>{
                return tabs;
            })
        }
    },
}

export default headerTabsService;
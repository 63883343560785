import { Event } from "../../model/event/event";
import moment from "moment";

export function orderByDate(events: Event[]) {
    const eventsDeepCopy = [...events]
    eventsDeepCopy.sort((a: Event, b: Event) => {
        if (moment(a.startDate).isAfter(moment(b.startDate))) return 1;
        if (moment(a.startDate).isBefore(moment(b.startDate))) return -1;
        return 0
    })

    return eventsDeepCopy;
}
import { Col, Row } from "antd";
import React from "react";
import i18n from "../../../../language/i18n";
import PageTitle from "../../../base/pageTitle";
import Paragraph from "../../../base/text/paragraph";
import styles from './index.module.css';

interface IntroProps {
    title?: string;
    subTitle?: string;
}


function Intro({title, subTitle} : IntroProps) {
    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <PageTitle>
                            {title || i18n.t("teams.section.intro.title")}
                        </PageTitle>
                    </Col>
                </Row>
                <Row style={{ marginLeft: 0, marginRight: 0 }}>
                    <Col>
                        <Paragraph className={styles.description}>
                            {subTitle || i18n.t('teams.section.intro.description')}
                        </Paragraph>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Intro;
import { Badge, Checkbox, Col, Divider, Dropdown, Radio, Row, Slider, Space } from 'antd';
import React, { useState } from 'react';
import i18n from '../../../../language/i18n';
import ChevronDown from '../../../base/icon/chevronDown';
import ControlIcon from '../../../base/icon/control';
import B1 from '../../../base/text/b1';
import B2 from '../../../base/text/b2';
import styles from './index.module.css';

export type FiltersDesktopProps = {
    categoryOptions: { label: string, value: string }[]
    checkedList: string[]
    setCheckedList: React.Dispatch<React.SetStateAction<string[]>>
    setIndeterminate: React.Dispatch<React.SetStateAction<boolean>>
    setCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    indeterminate: boolean
    checkAll: boolean
    onCategoriesSelect: (categories: any) => void
    onSortSelect: (sort: any) => void
    onDiscountSelect: (discount: any) => void
}

function FiltersDesktop(props: FiltersDesktopProps) {
    const { checkedList,
        setCheckedList,
        indeterminate,
        setIndeterminate,
        checkAll,
        setCheckAll,
        categoryOptions,
        onCategoriesSelect,
        onSortSelect,
        onDiscountSelect
    } = props;

    const [order, setOrder] = React.useState<number | undefined>(undefined);
    const [slider, setSlider] = useState<[number, number]>([0, 100]);
    const [visibleOrder, setVisibleOrder] = useState<boolean>(false);
    const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
    const [orderSelected, setOrderSelected] = useState<boolean>(false);

    const onChange = (list: any) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < categoryOptions.length);
        setCheckAll(list.length === categoryOptions.length);

        onCategoriesSelect(list);
    };

    const onCheckAllChange = (e: any) => {
        const list = categoryOptions.map((o) => o.value);

        setCheckedList(e.target.checked ? list : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);

        onCategoriesSelect(list);
    };

    const clearCheckAll = () => {
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);

        onCategoriesSelect([]);
    }

    const clearOrder = () => {
        const discount = { greaterThanOrEqual: 0, lessThanOrEqual: 100 }
        const sort = [{ field: "sortable_title", order: "asc" }];

        setSlider([0, 100]);
        setOrder(1);
        setVisibleOrder(false);
        setOrderSelected(false);

        onSortSelect(sort);
        onDiscountSelect(discount);
    }

    const onChangeRadio = (e: any) => {
        setOrder(e.target.value);
        setOrderSelected(true);

        let sort = undefined;
        switch (e.target.value) {
            case 1: sort = { field: "sortable_title", order: "asc" }; break;
            case 2: sort = { field: "sortable_title", order: "desc" }; break;
            case 3: sort = { field: "discount", order: "desc" }; break;
        }

        onSortSelect([sort]);
    };

    const onChangeSlider = (value: any) => {
        setSlider(value);
        setOrderSelected(true);

        const [greaterThanOrEqual, lessThanOrEqual] = value;
        const discount = { greaterThanOrEqual, lessThanOrEqual };
        onDiscountSelect(discount);
    }

    const formatMarks = () => {
        const showStart = slider[0] > 9
        const showEnd = slider[1] < 91
        return {
            0: showStart ? '0%' : '',
            100: showEnd ? '100%' : '',
        }
    }

    const onVisibleChange = (flag: boolean) => {
        setVisibleOrder(flag);
    }

    const onVisibleFilterChange = (flag: boolean) => {
        setVisibleFilter(flag);
    }

    const getSelectedLabel = () => {
        if (checkedList.length > 1) {
            return `${i18n.t("plus.section.filters.several")} (${checkedList.length})`;
        }

        if (checkedList.length === 1) {
            const id = checkedList[0]
            const option = categoryOptions.find(o => o.value === id)!
            return option.label;
        }

        return i18n.t("plus.section.filters.select");
    }

    const menuOrder = (
        <Space direction="vertical" className={styles.menuOrderContainer}>
            <B2>{i18n.t("plus.section.filters.sortTitle")}</B2>
            <Radio.Group onChange={onChangeRadio} value={order} className={styles.radioGroup}>
                <Space direction="vertical">
                    <Radio value={1}>{i18n.t("plus.section.filters.sortAsc")}</Radio>
                    <Radio value={2}>{i18n.t("plus.section.filters.sortDesc")}</Radio>
                    <Radio value={3}>{i18n.t("plus.section.filters.sortDiscount")}</Radio>
                </Space>
            </Radio.Group>
            <Space direction="horizontal" style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                    <Col style={{ width: "100%" }}>
                        <B2>{i18n.t("plus.section.filters.sortRange")}</B2>
                        <Slider
                            range
                            value={slider}
                            className={styles.slider}
                            marks={formatMarks()}
                            onChange={onChangeSlider}
                            tooltipVisible={visibleOrder}
                            tooltipPlacement="bottom"
                            tipFormatter={(value: any) => (<span> {value}% </span>)}
                            tooltipPrefixCls={[styles.tooltip, "ant-tooltip"].join(" ")}
                        />
                    </Col>
                </Row>
            </Space>
            <Row style={{ width: "100%" }}>
                <Col style={{ width: "100%" }}>
                    <span className={styles.clearButton} onClick={clearOrder}> {i18n.t("plus.section.filters.clear")} </span>
                </Col>
            </Row>
        </Space>
    )

    const menuFilter = (
        <Space direction="vertical" className={styles.menuFilterContainer}>
            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                className={styles.selectAll}
            >
                {i18n.t("plus.section.filters.all")}
            </Checkbox>
            <Checkbox.Group
                options={categoryOptions}
                value={checkedList}
                onChange={onChange}
                className={styles.selectGroup}
            />
            <span className={styles.clearButton} onClick={clearCheckAll}> {i18n.t("plus.section.filters.clear")} </span>
        </Space>
    );

    return (
        <div className={styles.filterContainer}>
            <Dropdown
                overlay={menuOrder}
                trigger={['click']}
                onVisibleChange={onVisibleChange}
                visible={visibleOrder}
                placement="bottomRight"
                overlayClassName={styles.filtersDropdown}
            >
                <div className={styles.control}>
                    <Badge dot color={orderSelected ? "#000" : "white"}>
                        <ControlIcon className={[styles.pointer].join(" ")} onClick={e => e.preventDefault()} />
                    </Badge>
                </div>
            </Dropdown>
            <Dropdown
                overlay={menuFilter}
                trigger={['click']}
                onVisibleChange={onVisibleFilterChange}
                visible={visibleFilter}
            >
                <div className={styles.dropdown}>
                    <Divider type="vertical" className={styles.divider} />
                    <span className={styles.pointer} onClick={e => e.preventDefault()}>
                        <B1>
                            {getSelectedLabel()} <ChevronDown className={styles.chevron} />
                        </B1>
                    </span>
                </div>
            </Dropdown>
        </div>
    )
}

export default FiltersDesktop;
import { Event } from "../../model/event/event";

export function getCategoryColor(event: Event | null) {
    if (!event || !event.tags) return ""
    const [tag] = event.tags;
    switch (tag?.name.toLowerCase()) {
        case "evento interno":
            return "#8AD6AF";
        case "evento externo":
            return "#FAC362";
        case "formação":
            return "#AEA2CD"
    }
}
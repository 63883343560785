import { Col, Row, Skeleton } from 'antd';
import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router';
import PageContainer from '../../components/base/pageContainer';
import Intro from '../../components/sections/video/intro';
import styles from './index.module.css';
import PostsService from '../../services/postsService';
import { Post } from '../../model/post/Post';
import Text from '../../components/sections/template/typography/text';
import Separator from '../../components/sections/template/structure/separator';
import RelatedContent from '../../components/sections/post/relatedContent';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import AnalyticsService from '../../services/analytics';

export default function VideoPage() {
    const [loading, setLoading] = useState<boolean>(true);
    const [post, setPost] = useState<Post | undefined>(undefined);
    const [relatedPosts, setRelatedPosts] = useState<Array<any>>([]);
    useDocumentTitle(post?.title);

    const { id }: any = useParams();

    const getPost = useCallback(async (id: string) => {
        await PostsService.get(id).then((postData: Post) => {
            setPost(postData)

            AnalyticsService.event(AnalyticsService.broadcastEvent, { id: postData.id, title: postData.title });
            if (postData && postData.tags && postData.tags.length > 0) {
                getRelatedPosts(postData.tags[0].id)
                    .then((relatedPosts: any) => {
                        setRelatedPosts(relatedPosts.data.response.filter((relatedPost: Post) => relatedPost.id !== id));
                        setLoading(false);
                    })
            } else {
                setLoading(false);
            }
        })
    }, [])

    const getRelatedPosts = async (category: string) => {
        const criteria = {
            from: 0,
            size: 16,
            tags: [category],
            types: ["not_defined", "news", "blog", "note", "video", "podcast"],
            sort: [{ field: "updatedDate", order: "desc" }]
        }

        return await PostsService.getAll(criteria);
    }

    const getContentLink = () => {
        if (post?.type === "video" && post?.video !== undefined) {
            return post.video;
        }

        if (post?.type === "podcast" && post?.podcast !== undefined) {
            return post.podcast;
        }

        throw new Error("Invalid type on broadcast");
    }

    useEffect(() => {
        getPost(id)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (post?.id !== id && post?.id !== undefined) {
            setLoading(true);
            getPost(id)
        }
    }, [post, id, getPost])

    if (loading === true) {
        return (
            <PageContainer className={styles.loadingContainer}>
                <Skeleton />
            </PageContainer>
        );
    }

    return (
        <>
            <PageContainer className={styles.bodyContainer}>
                <Row>
                    <Col xs={24} md={18} className={styles.introContainer}>
                        <Intro
                            id={post?.id}
                            title={post!.title}
                            type={post?.type}
                            tag={post?.tags?.length && post?.tags[0].name}
                            content={getContentLink()}
                            updatedDate={post?.updatedDate.toDate().toLocaleDateString("pt-PT")}
                        />
                    </Col>
                </Row>
                <Row className={getContentLink().type === "spotify" ? styles.bodyRow : ""}>
                    {post?.body &&
                        (<Col xs={24} md={18} className={styles.bodyContainer}>
                            <Text data={[{ body: post.body! }]} />
                        </Col>)}
                </Row>
            </PageContainer>
            {relatedPosts.length > 0 &&
                <Row>
                    <Col style={{ width: '100%' }}>
                        <PageContainer>
                            <Separator className={styles.separator} data={[{ exists: true }]} />
                            <RelatedContent data={relatedPosts} />
                        </PageContainer>
                    </Col>
                </Row>
            }
        </>
    );
}

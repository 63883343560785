import { Row } from "antd";
import React from "react";
import styles from './index.module.css';

export type PostLeadProps = {
    className?: string;
    children?: any;
}

export default function PostLead(props: PostLeadProps) {
    return (
        <Row justify="start">
            <div className={styles.lead}> {props.children} </div>
        </Row>
    );
}

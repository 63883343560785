import React, { useState, useRef, useEffect } from 'react'
import { Col, Drawer, Radio, RadioChangeEvent, Row, Space } from 'antd';
import B2 from '../../../base/text/b2';
import styles from './index.module.css';
import FilterIcon from '../../../base/icon/filter';
import CloseModalIcon from '../../../base/icon/closeModal';
import Button from '../../../base/button-v2';
import i18n from '../../../../language/i18n';

interface FiltersMobileProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    stateOnMount: any;
    data: any;
    orderValue: number;
    setOrderValue: (value: number) => void;
}

function FiltersMobile({
    isOpen,
    setIsOpen,
    stateOnMount,
    data,
    orderValue,
    setOrderValue,
}: FiltersMobileProps) {
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [, setShowTooltip] = useState<boolean>(true);
    const drawerBodyNode = useRef<any>();

    const { setSort } = data;

    useEffect(() => {
        drawerBodyNode.current = document.querySelector(".ant-drawer-body");

        function onScroll() {
            const scrolledY = drawerBodyNode.current?.scrollTop;
            setShowTooltip(false)
            setScrollTop(scrolledY);
        }

        drawerBodyNode.current?.addEventListener('scroll', onScroll);

        return () => {
            drawerBodyNode.current?.removeEventListener('scroll', onScroll);
        }

    }, [scrollTop]);

    function onClose() {
        setOrderValue(stateOnMount.orderValue);
        setIsOpen(false);
    }

    function onSubmit() {
        stateOnMount.orderValue = orderValue;

        let sort;
        switch (orderValue) {
            case 1: sort = { field: "title", order: "asc" }; break;
            case 2: sort = { field: "title", order: "desc" }; break;
            case 3: sort = { field: "updatedDate", order: "desc" }; break;
            case 4: sort = { field: "updatedDate", order: "asc" }; break;
        }

        setSort([sort]);
        setIsOpen(false);
    }

    function renderTitle() {
        return (
            <div className={styles.headerTitle}>
                <FilterIcon />
                <B2>{i18n.t("plus.section.filters.filtersWord")}</B2>
            </div>
        );
    }

    function onRadioChange(e: RadioChangeEvent) {
        const { value } = e.target;
        setOrderValue(value);
    }

    function setToDefault() {
        setOrderValue(3);
    }

    return (
        <Drawer
            visible={isOpen}
            width="100%"
            className={styles.drawer}
            placement="bottom"
            height="50%"
            title={renderTitle()}
            closeIcon={<CloseModalIcon />}
            onClose={onClose}
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                paddingBottom: 0,
            }}
        >
            <Row gutter={[{}, 30]} id="row">
                <Col span={24}>
                    <B2 className={styles.titleRadio}>{i18n.t("plus.section.filters.sortTitle")}</B2>
                    <Radio.Group
                        value={orderValue}
                        className={styles.radioGroup}
                        onChange={onRadioChange}
                        defaultValue={3}
                    >
                        <Space direction="vertical" size="middle" defaultValue={3}>
                            <Radio value={1}>{i18n.t("plus.section.filters.sortAsc")}</Radio>
                            <Radio value={2}>{i18n.t("plus.section.filters.sortDesc")}</Radio>
                            <Radio value={3}>{i18n.t("broadcast.section.filters.dateDesc")}</Radio>
                            <Radio value={4}>{i18n.t("broadcast.section.filters.dateAsc")}</Radio>
                        </Space>
                    </Radio.Group>
                </Col>
            </Row>
            <Row className={styles.buttonsContainer} >
                <Col>
                    <Button
                        className={styles.tertiary}
                        onClick={setToDefault}
                    >
                        {i18n.t("plus.section.filters.clear")}
                    </Button>
                    <Button onClick={onSubmit}>{i18n.t("plus.section.filters.search")}</Button>
                </Col>
            </Row>
        </Drawer>
    )
}

export default FiltersMobile;
import { Tag } from '../model/tag/tag';
import { functions } from './persistence';

const TagService = {

    getAll: function (criteria?: any) {
        return functions.httpsCallable("searchTags")(criteria || {});
    },
    formatTags: function (tags: Array<Tag>) {
        const string = tags?.map(item => ` | ${item.name}`).join(" ");
        return string;
    }
}

export default TagService;
import { Col } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router";
import Paragraph from "../../../../base/text/paragraph";
import { OmniType } from "../../../../../model/omni/OmniType";
import Button from "../../../../base/button-v2";
import Overline from "../../../../base/text/overline";
import Section from "../core";
import styles from './index.module.css';
import i18n from "../../../../../language/i18n";

export type ItemProps = {
    items: any[]
    total: number
    query: string,
    defaultSize: number
}

function EventSection({ total, items, query, defaultSize }: ItemProps) {
    const history = useHistory()

    const displayNoResultsMessage = total <= 5
    return (
        <Section
            type={OmniType.EVENTS}
            footer={(
                <>
                    {total === 0 ?
                        <Button className={styles.button} onClick={() => history.push(`/agenda`)}>
                            {i18n.t("omnisearch.results.text.goToAgenda")}
                        </Button> :
                        <Button className={styles.button} onClick={() => history.push(`/agenda/resultados?query=${query}`)}>
                            {total <= defaultSize ?
                                i18n.t("omnisearch.results.text.seeAllResultsAgenda") :
                                `${i18n.t("omnisearch.results.text.seeMoreBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inAgendaPage")}`}
                        </Button>
                    }
                </>
            )}
            items={(
                <>
                    {items.map((item: any) => {
                        const hasTag = item.tags && item.tags[0] && item.tags[0].name
                        return (
                            <Col className={styles.eventItem} onClick={() => history.push(`/agenda/resultados?query=${item.title}`)}>
                                {hasTag && <div className={styles.eventItemCategory}>
                                    <div style={{ backgroundColor: item.tags[0].color }}></div>
                                    <Overline>{item.tags[0].name}</Overline>
                                </div>}
                                <Highlighter
                                    className={styles.eventItemTitle}
                                    highlightClassName={styles.highlight}
                                    searchWords={[query]}
                                    autoEscape={true}
                                    textToHighlight={item.title}
                                />
                            </Col>
                        )
                    })}
                    {displayNoResultsMessage &&
                        <Paragraph className={styles.noResultsMessage}>{total === 0 ?
                            `${i18n.t("omnisearch.results.text.noResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inAgenda")}` :
                            `${i18n.t("omnisearch.results.text.noMoreResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inAgenda")}`}</Paragraph>
                    }
                </>
            )}
        />
    )
}

export default EventSection;
import React from 'react';
import { Route as ReactRoute, RouteProps as ReactRouteProps } from 'react-router';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';

import AuthenticationContext from '../authenticationContext';

export type RouteProps = {
    /**
     * 
     */
    wrap?: boolean;

    /**
     * 
     */
    dark?: boolean;

    /**
     * 
     */
    pageWrap?: boolean;

    title?: string;
}

export function Route(props: RouteProps & ReactRouteProps) {
    useDocumentTitle(props.title)

    return (<ReactRoute {...props} />)
}

Route.contextType = AuthenticationContext;
import { Row } from "antd";
import React from "react";
import Paragraph from "../paragraph";
import styles from './index.module.css';

export interface HighlightProps {
  data?: Array<any>;
}

export default function Highlight(props: HighlightProps) {
  if (props.data === undefined || props.data.length === 0) {
    return null;
  }

  const data = props.data[0];

  return (
    data.body && (
      <Row className={styles.wrapper}>
        <mark><Paragraph data={[{body:data.body}]}/></mark>
      </Row>
    )
  );
}

import moment, { MomentInput, MomentFormatSpecification } from 'moment';
import 'moment/locale/pt'
import 'moment/locale/en-gb'

export const momentWithLocale = moment;

export function momentPlatform(inp?: MomentInput, format?: MomentFormatSpecification, strict?: boolean) {
    return moment(inp, format, strict).locale('en-gb')
};

export function changeLocale(language: 'pt' | 'en') {
    moment.locale(language)
}

import React, { Fragment, useState } from 'react';
import { Col, Input, Row, Skeleton, List as AntList } from 'antd';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import SearchIcon from '../../../base/icon/search';
import styles from './index.module.css';
import i18n from '../../../../language/i18n';
import PostCard from '../../post/postCard';
import { useHistory } from 'react-router';
import Pagination from '../../../base/pagination';
import FilterDesktop from '../filterDesktop';
import Overline from '../../../base/text/overline';
import { Tag } from '../../../../model/tag/tag';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DropdownFiltersMobile from '../../../base/dropdownFiltersMobile';
import TabMenu from '../tabMenu';

interface ListProps {
    brodcastData: any,
    checkedList: Tag[],
    optionsList: Tag[],
    handleFilterChange: (a: any) => void,
    indeterminate: boolean,
    allChecked: boolean,
    handleClearAll: () => void,
    handleCheckAll: (event: CheckboxChangeEvent) => void
}

function List({
    brodcastData,
    checkedList,
    optionsList,
    handleFilterChange,
    indeterminate,
    allChecked,
    handleClearAll,
    handleCheckAll
}: ListProps) {
    const [page, setPage] = useState(1)
    const [categoriesVisible, setCategoriesVisible] = useState<boolean>(false);
    const [tab1Selected, setTab1Selected] = useState<boolean>(true);
    const [tab2Selected, setTab2Selected] = useState<boolean>(false);
    const [tab3Selected, setTab3Selected] = useState<boolean>(false);

    const { isTablet } = useWindowSize();
    const history = useHistory();

    const {
        loading,
        data,
        setSize,
        query,
        setSort,
        setQuery,
        setFrom,
        total,
        searchResults,
        defaultSize,
        setType
    } = brodcastData;

    function renderList() {
        if (loading === true) {
            return <Skeleton active />
        }

        return (
            <AntList
                grid={{
                    gutter: 20,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 4,
                }}
                className={styles.cardListContainer}
                dataSource={data}
                renderItem={(post: any, index: number) => {
                    return (
                        <AntList.Item className={styles.cardListItem} key={post.id}>
                            {renderCard(post)}
                        </AntList.Item>
                    )
                }}
            />
        )
    }

    function renderCard(post: any) {
        return (
            <PostCard
                post={post}
                onPostClick={() => history.push(`/broadcast/${post.id}`)}
                className={styles.listItem}
            />
        )
    }

    function handlePageChange(page: number) {
        setPage(page++)
        setSize((prevSize: number) => prevSize * 2)
    }

    function handleReachBottom() {
        setSize((prevSize: number) => prevSize * 2)
    }

    const handleVisibleCategoriesChange = (flag: boolean) => {
        setCategoriesVisible(flag);
    }

    function renderPaginationSection() {
        if (total === 0) {
            return undefined
        }

        return (
            <Pagination
                className={styles.pagination}
                disabled={searchResults.result === undefined || loading}
                pageSize={defaultSize}
                onChange={handlePageChange}
                total={total}
                current={page}
                onReachBottom={handleReachBottom}
                hasMore={total === 0 ? true : total !== data.length}
                phonePagination={true}
            />
        )
    }

    async function handleSearch(event: any) {
        setPage(1)
        setFrom(0);
        setQuery(event.target.value);
        setSize(defaultSize);
    }

    function renderContent() {
        const suffix = isTablet ? undefined : (
            <div className={styles.suffixContainer}>
                <FilterDesktop
                    checkedList={checkedList}
                    onSortSelect={(sort: any) => setSort(sort)}
                    optionsList={optionsList}
                    handleFilterChange={handleFilterChange}
                    indeterminate={indeterminate}
                    allChecked={allChecked}
                    handleClearAll={handleClearAll}
                    handleCheckAll={handleCheckAll}
                    loading={loading}
                />
            </div>
        );

        const content = (
            <Fragment>
                <Row>
                    <Col className={styles.searchInputContainer}>
                        <Input
                            className={styles.searchInput}
                            prefix={<SearchIcon className={styles.searchInputIcon} />}
                            placeholder={i18n.t("plus.section.filters.search")}
                            value={query}
                            suffix={suffix}
                            onChange={handleSearch}
                        />
                    </Col>
                </Row>
                <Row className={styles.displayMobile}>
                    <Col className={[styles.tabMenuContainer, styles.noMarginBottom].join(" ")}>
                        <DropdownFiltersMobile
                            checkedList={checkedList}
                            optionsList={optionsList}
                            loading={loading}
                            indeterminate={indeterminate}
                            allChecked={allChecked}
                            handleCheckAll={handleCheckAll}
                            handleClearAll={handleClearAll}
                            handleFilterChange={handleFilterChange}
                            handleVisibleChange={handleVisibleCategoriesChange}
                            visible={categoriesVisible}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.tabMenuContainer}>
                        <TabMenu
                            Tab1Label={i18n.t("broadcast.section.tab.all")}
                            Tab1IsSelected={tab1Selected}
                            Tab1OnClick={() => { setTab1Selected(true); setTab2Selected(false); setTab3Selected(false); setType("all"); }}
                            Tab2Label={i18n.t("broadcast.section.tab.video")}
                            Tab2IsSelected={tab2Selected}
                            Tab2OnClick={() => { setTab1Selected(false); setTab2Selected(true); setTab3Selected(false); setType("video"); }}
                            Tab3Label={i18n.t("broadcast.section.tab.podcast")}
                            Tab3IsSelected={tab3Selected}
                            Tab3OnClick={() => { setTab1Selected(false); setTab2Selected(false); setTab3Selected(true); setType("podcast"); }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className={styles.resultsContainer}>
                        <Overline>{total + " " + i18n.t("plus.section.filters.results")}</Overline>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ width: '100%' }}>
                        <div className={styles.divider} />
                    </Col>
                </Row>
                {renderList()}
                <Row justify='end'>
                    <Col>
                        {renderPaginationSection()}
                    </Col>
                </Row>

            </Fragment>
        )

        return content
    }
    return (renderContent())
}

export default List;
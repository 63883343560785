import { Col, Divider, Drawer, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useResizeWindow } from "../../../../hooks/useResizeWindow";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useHistory } from "react-router";
import i18n from "../../../../language/i18n";
import CloseModalIcon from "../../../base/icon/closeModal";
import LocationIcon from "../../../base/icon/location";
import MsgTeamsIcon from "../../../base/icon/msgTeams";
import B2 from "../../../base/text/b2";
import H5 from "../../../base/text/h5";
import Overline from "../../../base/text/overline";
import CardWho from "../cardWho";
import UserDetail from "../userDetail";
import styles from './index.module.css';
import PlaceholderPhoto from '../../../../assets/images/png/PlaceholderPhoto.jpg'
import { Department } from "../../../../model/department/Department";

export interface UserListProps {
    users: any;
    loading?: boolean,
    departments?: Department[]
}

function UserList({ users, loading, departments }: UserListProps) {
    const [defaultCardHeight, setDefaultCardHeight] = useState(0)
    const [drawerData, setDrawerData] = useState<any>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const imgRef = useRef<any>(null)
    const windowSize = useResizeWindow();
    const { isPhone, isTablet, width } = useWindowSize();
    const history = useHistory();

    useEffect(() => {
        if (imgRef.current === null || defaultCardHeight > 0) {
            return
        }

        setDefaultCardHeight(imgRef.current.offsetHeight)

    }, [windowSize, imgRef, defaultCardHeight])


    const setDefaultCardHeightOnce = useCallback((newImgRef: any) => {
        if (imgRef.current === null) {
            imgRef.current = newImgRef.current;
        }
        if (defaultCardHeight === 0) {
            setDefaultCardHeight(imgRef.current.offsetHeight)
        }
    }, [defaultCardHeight])


    const loadingStyle = loading === true ? styles.userListContainerLoading : undefined

    function handleUserDetailsDrawer(value: boolean, item?: any) {
        setIsVisible(value);
        item ? setDrawerData(item) : setDrawerData(null);
    }


    const wrapperStyle: React.CSSProperties = {
        height: '70vh',
        width: '100vw',
        backgroundColor: '#000'
    }

    const headerStyle: React.CSSProperties = {
        border: 'none',
        padding: '12px 24px 6px 24px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#000',
    }

    const bodyStyle: React.CSSProperties = {
        backgroundColor: '#000',
        color: '#fff',
        padding: ' 0 24px 12px 24px',
    }

    const maskStyle: React.CSSProperties = {
        opacity: '50%'
    }

    function setMarginLeft(index: number) {
        //the remainder different from 0 checks if the item is not the first in the row
        if (width < 576) {
            return 0;
        } else if (isPhone) {
            return index % 2 !== 0 ? (2 / 24) * 100 : 0
        } else if (isTablet) {
            return index % 3 !== 0 ? ((3 / 24) / 2) * 100 : 0
        } else {
            return index % 4 !== 0 ? ((4 / 24) / 3) * 100 : 0
        }
    }

    function renderOtherDepartments(data: any) {
        const attributesToRender = [
            'extensionAttribute1',
            'extensionAttribute3',
            'extensionAttribute11',
            'extensionAttribute12',
            'extensionAttribute13',
            'extensionAttribute14'
        ]
        let res: any[] = [];
        for (const atr of attributesToRender) {
            if (data.onPremisesExtensionAttributes[atr]) {
                if (departments?.some(dep => dep.data.name === data.onPremisesExtensionAttributes[atr])) {
                    res.push(
                        <Link to={`/equipas?departamento=${encodeURIComponent(data.onPremisesExtensionAttributes[atr])}`}>
                            <B2 className={[styles.infoText, styles.link].join(" ")}>{data.onPremisesExtensionAttributes[atr]}</B2>
                        </Link>
                    )
                } else {
                    res.push(<B2 className={[styles.infoText].join(" ")}>{data.onPremisesExtensionAttributes[atr]}</B2>)
                }
            }

        }
        return res;
    }

    function renderDepartmentsSection(data: any) {

        if (!data) {
            return null
        }

        const renderMainDepartment = () => {
            if (!data.department) {
                return null
            }

            if (departments?.some(dep => dep.data.name === data.department)) {
                return (
                    <Link className="noDecoration" to={`/equipas?departamento=${encodeURIComponent(data.department)}`}>
                        <B2 className={[styles.infoText, styles.link].join(" ")}>{data.department}</B2>
                    </Link>
                )
            } else {
                return (<B2 className={[styles.infoText].join(" ")}>{data.department}</B2>)
            }

        }

        return (
            <div className={styles.department} style={{ display: "block" }}>
                <Overline className={styles.label}>{i18n.t("whoIsWho.section.card.teams")} </Overline>
                {renderMainDepartment()}
                {renderOtherDepartments(data)}
            </div>
        )
    }

    function handleSecretaryClick(name: any) {
        history.push(`/quem-e-quem?texto=${name}`);
        history.go(0);
    }


    function renderDetailsBody(data: any) {
        return (
            <>
                {
                    data && data.mailNickname &&
                    <Overline className={[styles.department, styles.jobTitle].join(' ')}> {data.mailNickname} </Overline>
                }
                {
                    data && data.jobTitle &&
                    <Overline className={[styles.department, styles.jobTitle].join(' ')}> {data.jobTitle} </Overline>
                }
                {renderDepartmentsSection(data)}
                <Divider
                    type={'horizontal'}
                    className={styles.divider}
                />
                {
                    data && data.mobilePhone &&
                    <div className={styles.department}>
                        <Overline className={styles.label}>{i18n.t("whoIsWho.section.card.mobilePhone")} </Overline>
                        <Overline className={styles.infoText} title={data.mobilePhone}>{data.mobilePhone}</Overline>
                    </div>
                }
                {
                    data && data.msIpPhone &&
                    (
                        <>
                            <div className={styles.department}>
                                <Overline className={styles.label}>{i18n.t("whoIsWho.section.card.phone")} </Overline>
                                <Overline className={styles.infoText}>{data.msIpPhone}</Overline>
                            </div>
                        </>
                    )
                }     
                {
                    data && data.businessPhones?.length > 0 &&
                    (
                        <>
                            <div className={styles.department}>
                                <Overline className={styles.label}>{i18n.t("whoIsWho.section.card.phoneExt")} </Overline>
                                <Overline className={styles.infoText}>{data.businessPhones.join('/ ')}</Overline>
                            </div>
                        </>
                    )
                }
                {
                    data && data.email &&
                    <div className={styles.department}>
                        <Overline className={styles.label}>{i18n.t("whoIsWho.section.card.email")} </Overline>
                        <div onClick={() => { window.open(`mailto:${data.email}`, "_blank"); return false; }}>
                            <Overline className={[styles.infoText, styles.link].join(" ")}>{data.email}</Overline>
                        </div>
                    </div>
                }
                {
                    data && data.msExchAssistantName &&
                    <div className={styles.department} style={{ display: "block" }}>
                        <Overline className={styles.label}>{i18n.t("whoIsWho.section.card.secretary")} </Overline>
                        <div onClick={() => { handleSecretaryClick(encodeURIComponent(data.msExchAssistantName)); return false; }}>
                            <Overline className={[styles.infoText, styles.link].join(" ")}>{data.msExchAssistantName}</Overline>
                        </div>
                    </div>
                }
                {
                    data && data.officeLocation &&
                    <Row justify={"space-between"} align={"middle"} className={styles.officeContainer}>
                        <Col >
                            <Overline className={styles.department}> {data.officeLocation.split("/")[0]} </Overline>
                            <Overline className={styles.department}> {data.officeLocation.split("/")[1]} </Overline>
                        </Col>
                        <Col className={styles.locationContainer}>
                            <LocationIcon />
                        </Col>
                    </Row>
                }
                {data && data.email &&
                    <Row
                        justify={"space-between"}
                        align={"middle"}
                        className={styles.teamsContainer}
                        onClick={() => window.open(`https://teams.microsoft.com/l/chat/0/0?users=${data.email}`, "_blank")}
                        style={isPhone ? {
                            position: "absolute",
                            left: "0",
                            bottom: "0",
                            width: "100vw",
                            padding: "14px 24px",
                            textTransform: "capitalize",
                            backgroundColor: "#A0C6D2"
                        } : {}}
                    >
                        <Col span={24}>
                            <Row align="middle" justify="space-between" style={{ width: "100%"}}>
                                <Col span={22}>
                                    <B2 className={styles.microsoftLink}>{i18n.t("whoIsWho.section.card.microsoft")}</B2>
                                </Col>
                                <Col span={2}>
                                    <MsgTeamsIcon />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </>)
    }

    return (
        <Row gutter={[{}, 45]} className={[styles.userListContainer, loadingStyle].join(" ")} >
            {users.map((item: any, index: number) => {
                return (
                    <Col xs={24} sm={11} md={7} lg={5} key={item.id} onClick={(e: any) => e.preventDefault()} style={{ marginLeft: setMarginLeft(index) + '%' }}>
                        <div className={styles.card}>
                            <CardWho
                                cover={item.photo || PlaceholderPhoto}
                                defaultCardHeight={defaultCardHeight}
                                setDefaultCardHeight={setDefaultCardHeightOnce}
                                onClick={() => handleUserDetailsDrawer(true, item)}
                            />
                            {!isPhone &&
                                <div className={styles.details}>
                                    <UserDetail
                                        data={item}
                                        renderData={renderDetailsBody}
                                    />
                                </div>
                            }
                        </div>
                    </Col>
                )
            })}
            {isPhone &&
                <Col>
                    <Drawer
                        className={styles.drawer}
                        visible={isVisible}
                        title={<H5 className={styles.name}> {drawerData?.name} </H5>}
                        onClose={() => handleUserDetailsDrawer(false)}
                        placement="bottom"
                        contentWrapperStyle={wrapperStyle}
                        headerStyle={headerStyle}
                        maskStyle={maskStyle}
                        closeIcon={<CloseModalIcon className={styles.closeIcon} />}
                        bodyStyle={bodyStyle}
                        destroyOnClose
                    >
                        {renderDetailsBody(drawerData)}
                    </Drawer>
                </Col>
            }
        </Row>
    )
}

export default UserList;
import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/base/pageContainer';
import { Event } from '../../../model/event/event';
import EventList from '../../../components/sections/agenda/results/eventList';
import Intro from '../../../components/sections/agenda/results/intro';
import SearchBarFilters from '../../../components/sections/agenda/results/searchBarFilters';
import { Tag } from '../../../model/tag/tag';
import TagService from '../../../services/tagService';
import EventService from '../../../services/eventService';

function Results() {
    const [eventTags, setEventTags] = useState<Tag[]>([])
    const [size, setSize] = useState<number>(4)
    const [loading, setLoading] = useState<boolean>(false);
    const [totalEvents, setTotalEvents] = useState<number>(0)
    const [events, setEvents] = useState<null | Event[]>([])
    const [query, setQuery] = useState<string>("");
    const [activeFilters, setActiveFilters] = useState<string[]>([]);

    useEffect(() => {
        TagService.getAll({ types: ["event"], sort: [{ field: "name", order: "asc" }] })
            .then(res => {
                setEventTags(res.data.response);
            });
    }, []);

    async function refreshEvents() {
        const res = await EventService.getAll({
            ...(query ? { query: query } : {}),
            size: size,
            sort: [{
                field: 'startDate',
                order: 'asc'
            }],
            tags: activeFilters || [],
        })

        setEvents(res.data.response)
        return res.data.response
    }

    return (
        <PageContainer>
            <Intro />
            <SearchBarFilters
                eventTags={eventTags}
                setEventTags={setEventTags}
                totalEvents={totalEvents}
                size={size}
                setLoading={setLoading}
                setTotalEvents={setTotalEvents}
                events={events}
                setEvents={setEvents}
                loading={loading}
                query={query}
                setQuery={setQuery}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
            />
            <EventList
                eventTags={eventTags}
                setSize={setSize}
                loading={loading}
                setLoading={setLoading}
                totalEvents={totalEvents}
                events={events}
                refreshEvents={refreshEvents}
            />
        </PageContainer>
    );
}

export default Results;
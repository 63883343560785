import firebase from 'firebase';
import { Post } from '../model/post/Post';
import { Tag } from '../model/tag/tag';
import { functions, firestore } from './persistence';

const PostsService = {
    getAll: function (criteria?: any) {
        return functions.httpsCallable("searchPosts")(criteria || {});
    },
    get: async function (id: string): Promise<Post> {
        const postSnapshot = await firestore.collection('posts').doc(id).get();

        if (postSnapshot.exists === false) {
            throw Error("Post not found");
        }

        const post = { id: postSnapshot.id, ...postSnapshot.data() } as Post;

        if (post.tags !== undefined && post.tags.length > 0) {
            post.tags = await Promise.all(
                post.tags.map((tag: any) => {
                    return firestore.collection('tags').doc(tag.id).get()
                        .then((snapshot: firebase.firestore.DocumentSnapshot) => {
                            return { ...snapshot.data(), id: snapshot.id } as Tag;
                        });
                })
            );
        }

        return post;
    },
}

export default PostsService;
import moment from "moment";
import { Event } from "../../model/event/event";

export function formatDate(event: Event, width: number) {
    let weekdayFormat = 'ddd';
    if (width < 768) weekdayFormat = "dddd";
    const startDate = moment(event.startDate);
    const endDate = moment(event.endDate);
    if (!event.endDate) {
        return `${startDate.format(weekdayFormat).split('-')[0]}, 
                ${startDate.format("D")}/${startDate.format("M")} às 
                ${startDate.format("HH")}:${startDate.format("mm")}`
    }

    return `${startDate.format(weekdayFormat).split('-')[0]}, 
            ${startDate.format("D")}/${startDate.format("M")} - 
            ${endDate.format(weekdayFormat).split('-')[0]},
            ${endDate.format("D")}/${endDate.format("M")} `
}
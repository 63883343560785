import React, { useState, useRef, useEffect } from 'react'
import { Col, Drawer, Radio, RadioChangeEvent, Row, Space, Checkbox, Slider } from 'antd';
import B2 from '../../../base/text/b2';
import styles from './index.module.css';
import FilterIcon from '../../../base/icon/filter';
import CloseModalIcon from '../../../base/icon/closeModal';
import Button from '../../../base/button-v2';
import i18n from '../../../../language/i18n';

interface FiltersMobileProps {
    isOpen: boolean;
    setIsOpen: any;
    categories: string[];
    checkedList: string[];
    checkedListSetter: any;
    checkedAll: boolean;
    checkedAllSetter: any;
    onCheckboxChange: (list: any[]) => void;
    onCheckAllChange: (e: any) => void;
    stateOnMount: any;
    partnerships: any;
    indeterminate: boolean;
    orderValue: number;
    setOrderValue: any;
    sliderValue: [number, number];
    setSliderValue: any;
}

function FiltersMobile({
    isOpen,
    setIsOpen,
    categories,
    checkedList,
    checkedAll,
    checkedAllSetter,
    checkedListSetter,
    onCheckAllChange,
    onCheckboxChange,
    stateOnMount,
    partnerships,
    indeterminate,
    orderValue,
    setOrderValue,
    sliderValue, setSliderValue
}: FiltersMobileProps) {
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [showTooltip, setShowTooltip] = useState<boolean>(true);
    const drawerBodyNode = useRef<any>();

    const {
        setSort,
        setDiscount,
        setCategories,
    } = partnerships;

    useEffect(() => {
        drawerBodyNode.current = document.querySelector(".ant-drawer-body");
        let itemsHeight = 0;

        for (let i = 2; i < categories.length; i++) {
            itemsHeight += 40;
        }

        function onScroll() {
            const scrolledY = drawerBodyNode.current?.scrollTop;
            setShowTooltip(false)
            setScrollTop(scrolledY);
        }

        drawerBodyNode.current?.addEventListener('scroll', onScroll);

        if (scrollTop >= 18 + itemsHeight) {
            setShowTooltip(true);
        }

        return () => {
            drawerBodyNode.current?.removeEventListener('scroll', onScroll);
        }

    }, [scrollTop, categories.length]);

    function onClose() {
        checkedListSetter(stateOnMount.checkedList);
        checkedAllSetter(stateOnMount.checkAll);
        setOrderValue(stateOnMount.orderValue);
        setSliderValue(stateOnMount.sliderValue);
        setIsOpen(false);
    }

    function onSubmit() {
        stateOnMount.checkedList = checkedList;
        stateOnMount.checkAll = checkedAll;
        stateOnMount.orderValue = orderValue;
        stateOnMount.sliderValue = sliderValue;

        let sort;
        switch (orderValue) {
            case 1: sort = { field: "title", order: "asc" }; break;
            case 2: sort = { field: "discount", order: "desc" }; break;
            case 3: sort = { field: "title", order: "desc" }; break;
        }

        const [greaterThanOrEqual, lessThanOrEqual] = sliderValue;
        const discount = { greaterThanOrEqual, lessThanOrEqual };

        setCategories(checkedList)
        setSort([sort]);
        setDiscount(discount);
        setIsOpen(false);
    }

    function renderTitle() {
        return (
            <div className={styles.headerTitle}>
                <FilterIcon />
                <B2>{i18n.t("plus.section.filters.filtersWord")}</B2>
            </div>
        );
    }

    function onRadioChange(e: RadioChangeEvent) {
        const { value } = e.target;
        setOrderValue(value);
    }

    function onSliderChange(value: [number, number]) {
        setSliderValue(value);
    }

    function formatMarks() {
        const showStart = sliderValue[0] > 9
        const showEnd = sliderValue[1] < 89
        return {
            0: showStart ? '0%' : '',
            100: showEnd ? '100%' : '',
        }
    }

    function setToDefault() {
        checkedListSetter([]);
        setSliderValue([0, 100]);
        setOrderValue(1);
        checkedAllSetter(false);
    }

    return (
        <Drawer
            visible={isOpen}
            width="100%"
            className={styles.drawer}
            placement="bottom"
            height="95%"
            title={renderTitle()}
            closeIcon={<CloseModalIcon />}
            onClose={onClose}
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                paddingBottom: 0,
            }}
        >
            <Row gutter={[{}, 30]} id="row">
                <Col span={24}>
                    <B2 className={styles.titleRadio}>{i18n.t("plus.section.filters.sortTitle")}</B2>
                    <Radio.Group
                        value={orderValue}
                        className={styles.radioGroup}
                        onChange={onRadioChange}
                        defaultValue="1"
                    >
                        <Space direction="vertical" size="middle" defaultValue="1">
                            <Radio value={1}>{i18n.t("plus.section.filters.sortAsc")}</Radio>
                            <Radio value={2}>{i18n.t("plus.section.filters.sortDiscount")}</Radio>
                            <Radio value={3}>{i18n.t("plus.section.filters.sortDesc")}</Radio>
                        </Space>
                    </Radio.Group>
                </Col>
                <Col span={24}>
                    <B2 className={styles.titleCheckbox}>{i18n.t("plus.section.filters.categoriesTitle")}</B2>
                    <Checkbox
                        onChange={onCheckAllChange}
                        checked={checkedAll}
                        style={{ margin: '20px 0 9px 0' }}
                        indeterminate={indeterminate}
                    >
                        {i18n.t("plus.section.filters.all")}
                    </Checkbox>
                    <Checkbox.Group
                        className={styles.checkboxGroup}
                        value={checkedList}
                        onChange={onCheckboxChange}
                        options={categories}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    />
                </Col>
                <Col style={{ width: '98%', marginBottom: 60 }} >
                    <B2 className={styles.titleSlider}>{i18n.t("plus.section.filters.sortRange")}</B2>
                    <Slider
                        range
                        value={sliderValue}
                        className={styles.slider}
                        marks={formatMarks()}
                        onChange={onSliderChange}
                        tooltipVisible={isOpen && showTooltip}
                        tooltipPlacement="bottom"
                        tipFormatter={(value: any) => `${value}%`}
                        tooltipPrefixCls={[styles.tooltip, "ant-tooltip"].join(" ")}
                    />
                </Col>
            </Row>
            <Row className={styles.buttonsContainer} >
                <Col>
                    <Button
                        className={styles.tertiary}
                        onClick={setToDefault}
                    >
                        {i18n.t("plus.section.filters.clear")}
                    </Button>
                    <Button onClick={onSubmit}>{i18n.t("plus.section.filters.search")}</Button>
                </Col>
            </Row>
        </Drawer>
    )
}

export default FiltersMobile;
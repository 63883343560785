import React from 'react';
import { ReactComponent as OpenInNewSvg } from '../../../../assets/images/svg/open-in-new.svg';

export interface OpenInNewProps {
    onClick?: () => any;
    className?: string;
}

function OpenInNew({ onClick, className }: OpenInNewProps) {
    return (
        <OpenInNewSvg onClick={onClick} className={className} />
    )
}

export default OpenInNew;

import React from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.css';
import { ReactComponent as BackgroundLeft } from './../../../../../assets/images/svg/backgrounds/agenda/agenda-left.svg'
import { ReactComponent as BackgroundRight } from './../../../../../assets/images/svg/backgrounds/agenda/agenda-right.svg'
import H2 from '../../../../base/text/h2';
import i18n from '../../../../../language/i18n';
import { useWindowSize } from '../../../../../hooks/useWindowSize';

function Intro() {
    const { isTablet } = useWindowSize();

    return (
        <Row className={styles.intro}>
            <Col span={24}>
                <H2>{i18n.t("agenda.results.section.intro.title")}</H2>
            </Col>
            <Col span={24} className={styles.iconContainer}>
                {!isTablet &&
                    <>
                        <BackgroundLeft />
                        <BackgroundRight />
                    </>
                }
            </Col>
        </Row>
    )
}

export default Intro;

import React from 'react';
import styles from './index.module.css';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import AppsIcon from '../../../base/icon/apps';
import i18n from '../../../../language/i18n';
import { useHistory } from 'react-router';
// import MeetingIcon from '../../../base/icon/meet';
// import SapIcon from '../../../base/icon/sap';
// import SNIcon from '../../../base/icon/serviceNow';
// import PrazosIcon from '../../../base/icon/prazos';
// import NDIcon from '../../../base/icon/netDocuments';
// import TrackerIcon from '../../../base/icon/trackRecord';
// import LibraryIcon from '../../../base/icon/library';
// import RiseIcon from '../../../base/icon/rise';
// import PowerBiIcon from '../../../base/icon/powerBi';


export type SidebarProps = {
    open: boolean;
    data?: Array<object>;
    apps?: Array<object>
    toggleSidebar?: any
}

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
    const { isTablet } = useWindowSize();
    const history = useHistory();
    // const apps = [
    //     { title: "Marcação de salas", cover: <MeetingIcon />, link: "http://app.plmj.org/gsalas/" },
    //     { title: "SAP Portal", cover: <SapIcon />, link: "https://lxs4p150.plmj.pt/sap/bc/ui2/flp" },
    //     { title: "Gestão de prazos", cover: <PrazosIcon />, link: "https://p224.plmj.pt/" },
    //     { title: "Service Now", cover: <SNIcon />, link: "https://plmj.service-now.com/sp" },
    //     { title: "NetDocuments", cover: <NDIcon />, link: "https://de.netdocuments.com/neWeb2/docCent.aspx?whr=CA-OXGSOBDJ." },
    //     { title: "Track Record", cover: <TrackerIcon />, link: "http://app.plmj.org/TrackRecord/AreaPessoal/MeusRegistos" },
    //     { title: "Biblioteca", cover: <LibraryIcon />, link: "http://app.plmj.pt/Biblioteca/Catalogo/" },
    //     { title: "Rise", cover: <RiseIcon />, link: "https://performancemanager.successfactors.eu/login?company=plmjadvoga#/login" },
    //     { title: "Power BI", cover: <PowerBiIcon />, link: "https://app.powerbi.com/home" }
    // ]

    const getLinkValue = (link: string) => {
        if (["agenda", "quem-e-quem", "equipas", "plus-plmj", "broadcast"].some(str => link.startsWith(str))) {
            //Specific page 
            history.push(`/${link}`);
            props.toggleSidebar(false)
            return
            
        }
        
        if (link.search("http") >= 0) {
            //External link
            window.open(link)
            return
        }

        props.toggleSidebar(false)
        history.push(`/page/${link}`);
        return
    }

    return (
        <ProSidebar
            collapsed={props.open === false}
            collapsedWidth={'0px'}
            width={isTablet ? "95%" : 430}
            className={styles.side}
        >
            <Menu>
                {isTablet && (
                    <SubMenu
                        key={`plmj-apps`}
                        title={i18n.t("apps.title")}
                        className={[styles.sub, styles.apps].join(" ")}
                        icon={<AppsIcon />}
                    >
                        {props.apps?.map((item: any, index: number) => {
                            return (
                                <MenuItem key={`${item.title}-${index}`} className={styles.appsRow} onClick={ () => getLinkValue(item.link)}>
                                    <img src={item.cover} alt="app" style={{ maxWidth: 25, marginRight: 10 }} />
                                    <span> {item.title} </span>
                                </MenuItem>
                            );
                        })}
                    </SubMenu>
                )}
                {props.data?.map((item: any, index: number) => {
                    if (item.children && item.children.length > 0) {
                        return (
                            <SubMenu
                                key={`${item?.title}-${index}`}
                                title={item?.title}
                                className={styles.sub}
                            >
                                {item.children.map((child: any, index: number) => {
                                    if (child.children && child.children.length > 0) {
                                        return (
                                            <SubMenu
                                                key={`${child?.title}-${index}`}
                                                title={child?.title}
                                                className={styles.secondarySub}
                                            >
                                                {child.children.map((c: any, index: number) => {
                                                    return (
                                                        <MenuItem onClick={() => getLinkValue(c.value)} key={`${c.title}-${index}`}>
                                                            {c.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </SubMenu>
                                        )
                                    }

                                    return (
                                        <MenuItem onClick={() => getLinkValue(child.value)} key={`${child.title}-${index}`}>
                                            {child.title}
                                        </MenuItem>
                                    )
                                })}
                            </SubMenu>
                        );
                    }

                    return (
                        <MenuItem
                            onClick={() => getLinkValue(item.value)}
                            key={`${item?.title}-${index}`}
                        >
                            <span className={styles.sideTitle}>
                                {item.title}
                            </span>
                        </MenuItem>
                    );
                })}
            </Menu>
        </ProSidebar>
    );
}

export default Sidebar;
import React, { useState } from "react";
import { Carousel, Col, Row, Typography } from "antd";
import PageTitle from "../../../../base/pageTitle";
import styles from './index.module.css';
import i18n from "../../../../../language/i18n";
import { Link, useHistory } from 'react-router-dom';
import Button from "../../../../base/button-v2";
import ChevronLeft from "../../../../base/icon/chevronLeft";
import TagService from "../../../../../services/tagService";
import { SpotlightComponentProps } from "..";
import { Post } from "../../../../../model/post/Post";
import Pagination from "../../../../base/pagination";


export const SpotlightSectionDesktop = ({autoRotate, mainCarouselRef, handlePageChange, secondaryCarouselRef, currentSlide, handleMainCarouselSelection, handleSecondaryCarouselSelection, data } : SpotlightComponentProps) => {
    const [draggingSecondaryCarousel, setDraggingSecondaryCarousel] = useState(false);
    const itemsLength = data ? data.length : 0;
    const history = useHistory();

    const pagination = (
        <Row justify="end" style={{ width: '100%' }}> 
            <Col>
                <Pagination
                    disabled={data.length === 0 || data === null}
                    pageSize={1}
                    onChange={handlePageChange}
                    total={data.length}
                    current={currentSlide + 1}
                    phonePagination={false}
                    className={styles.paginationSpot}
                />
            </Col>
        </Row>
    )

    function renderMainSlider() {
        return (
            <Carousel
                ref={mainCarouselRef}
                slidesToScroll={1}
                slidesToShow={1}
                infinite={true}
                autoplay={autoRotate}
                autoplaySpeed={5000}
                pauseOnHover={true}
                effect="fade"
                arrows={true}
                dots={false}
                draggable={true}
                afterChange={(currentSlide: number) => handleMainCarouselSelection(currentSlide)}
                nextArrow={((1 + currentSlide) !== itemsLength) ? <div id={"desktopCarouselArrowRight"}><ChevronLeft className={styles.arrowNext} /></div> : <div />}
                prevArrow={(currentSlide !== 0) ? <div id={"desktopCarouselArrowLeft"}><ChevronLeft className={styles.arrowPrev} /></div> : <div />}
            >
                {data && data.map((p: Post) => ((
                    <div>
                        <Row>
                            <Col span={24}>
                                <Row className={styles.primaryCarouselRow}>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <span className={[styles.label, styles.labelBlog, styles.titleShadow].join(' ')}>
                                            {p.type ? `${i18n.t("post.type." + p.type)}` : `${i18n.t("post.type.news")}`}
                                        </span>
                                        <span className={[styles.label, styles.labelCategory, styles.titleShadow].join(' ')}>
                                            {p.tags ? TagService.formatTags(p.tags) : ""}
                                        </span>
                                        <PageTitle className={[styles.title, styles.titleShadow].join(' ')}>
                                            <Link to={"post/" + p.id}>
                                                {p.title}
                                            </Link>
                                        </PageTitle>
                                        <Typography.Paragraph className={[styles.subtitle, styles.titleShadow].join(' ')} ellipsis={{ rows: 4, expandable: false }}>
                                            {p.subTitle}
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <div 
                                            style={{ backgroundImage: (p.images !== undefined && p.images.length > 0) ? "url('" + p.images[0] + "')" : "" }} 
                                            className={styles.image} 
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )))}
            </Carousel>
        );
    }

    const secondarySlider = 
        (
            <Row className={styles.secondaryCarouselRow}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Button filling="primary" onClick={() => history.push("post/" + data[currentSlide].id )}>{i18n.t("section.latest.button")}</Button>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Carousel
                        ref={secondaryCarouselRef}
                        slidesToShow={6}
                        slidesToScroll={6}
                        infinite={false}
                        arrows={false}
                        dots={false}
                        draggable={true}
                    >
                        {data.map((post: Post, index: number) => ((
                            <Col>
                                <div
                                    onDrag={() => setDraggingSecondaryCarousel(true)}
                                    onClick={() => !draggingSecondaryCarousel && handleSecondaryCarouselSelection && handleSecondaryCarouselSelection(index)}
                                    className={[styles.tinyImage, (currentSlide === (index)) && styles.tinyImageSelected].join(' ')}
                                    style={{ backgroundImage: (post.images !== undefined && post.images.length > 0) ? "url('" + post.images[0] + "')" : "" }}
                                />
                            </Col>
                        )))}
                    </Carousel>
                </Col>
            </Row>
        )   

    return (
        <>
            {renderMainSlider()}
            {secondarySlider}
            {pagination}
        </>
    );
}

import { Col, Dropdown, Row, Menu } from "antd";
import React from "react";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import ChevronDown from "../../../base/icon/chevronDown";
import SubTitleButton from "../../../base/subTitleButton";
import H5 from "../../../base/text/h5";
import styles from "./index.module.css";

export default function TabMenu({ Tab1Label, Tab1IsSelected, Tab1OnClick, Tab2Label, Tab2IsSelected, Tab2OnClick, Tab3Label, Tab3IsSelected, Tab3OnClick } : any) {
    const { isTablet } = useWindowSize();

    const menuTeams = (
        <Menu>
            <Menu.Item key="0" onClick={e => handleClick(e)}>
                <p>{Tab1Label}</p>
            </Menu.Item>
            <Menu.Item key="1" style={{ color: "grey" }} onClick={e => handleClick(e)}>
                <p>{Tab2Label}</p>
            </Menu.Item>
            <Menu.Item key="2" style={{ color: "grey" }} onClick={e => handleClick(e)}>
                <p>{Tab3Label}</p>
            </Menu.Item>
        </Menu>
    )

    function handleClick(e: any) {
        const key = e.key;

        if(key === "0") {
            Tab1OnClick();
        } if(key === "1") {
            Tab2OnClick();
        } if(key === "2") {
            Tab3OnClick();
        }
    }

    function setSelectedLabel() {
        if (Tab1IsSelected === true) {
            return Tab1Label
        }

        if (Tab2IsSelected === true) {
            return Tab2Label
        }

        if (Tab3IsSelected === true) {
            return Tab3Label
        }
    }

    if (isTablet) {
        return (
            <Row>
                <Col span={24}>
                    <Dropdown overlay={menuTeams} trigger={['click']}>
                        <Row justify="space-between" align="middle" className={styles.dropdownRow}>
                            <Col>
                                <H5 className={[styles.title].join(' ')}> {setSelectedLabel()}</H5>
                            </Col>
                            <Col>
                                <ChevronDown />
                            </Col>
                        </Row>
                    </Dropdown>
                </Col>
            </Row>
        )
    }

    return (
        <Row justify={"center"} style={{ marginTop: 20 }}>
            <Col span={6} style={{ textAlign:"center" }}>
                <SubTitleButton className={styles.tabTitle} disabled={false} selected={Tab1IsSelected} onClick={Tab1OnClick}>
                    {Tab1Label}
                </SubTitleButton>
            </Col>
            <Col span={3}/>
            <Col span={6} style={{ textAlign:"center" }}>
                <SubTitleButton className={styles.tabTitle} disabled={false} selected={Tab2IsSelected} onClick={Tab2OnClick}>
                    {Tab2Label}
                </SubTitleButton>
            </Col>
            <Col span={3}/> 
            <Col span={6} style={{ textAlign:"center" }}>
                <SubTitleButton className={styles.tabTitle} disabled={false} selected={Tab3IsSelected} onClick={Tab3OnClick}>
                    {Tab3Label}
                </SubTitleButton>
            </Col>
        </Row>
    );
    
}
import { firestore } from './persistence';

const PLUSPLMJService = {
    
    getPLUSPLMJ: async function () {
        const querySnapshot = await firestore.collection("pages_custom").doc("plusplmj").get()
        return querySnapshot.data()
    },

}

export default PLUSPLMJService;
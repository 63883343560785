import { Col, Dropdown, Menu, Row } from "antd";
import React from "react";
import SubTitleButton from "../../../base/subTitleButton";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import styles from './index.module.css';
import ChevronDown from "../../../base/icon/chevronDown";
import H5 from "../../../base/text/h5";
import ReactHtmlParser from 'react-html-parser';

export type TwoTabsProps = {
    topTab?: boolean;
    marginTop?: number;
    Tab1Label: string;
    Tab1IsSelected: boolean;
    Tab1OnClick: any;
    Tab2Label: string;
    Tab2IsSelected: boolean;
    Tab2OnClick: any;
    Tab3Label?: string;
    Tab3IsSelected?: boolean;
    Tab3OnClick?: any;
}

function TwoTabs({ topTab, marginTop, Tab1Label, Tab1IsSelected, Tab1OnClick, Tab2Label, Tab2IsSelected, Tab2OnClick, Tab3Label, Tab3IsSelected, Tab3OnClick }: TwoTabsProps) {

    const { isTablet } = useWindowSize();

    const menuTeams = (
        <Menu>
            {Tab1Label && (
                <Menu.Item key="0" onClick={e => handleClick(e)}>
                    <p>{ReactHtmlParser(Tab1Label)}</p>
                </Menu.Item>)}
            {Tab2Label && (
                <Menu.Item key="1" style={{ color: "grey" }} onClick={e => handleClick(e)}>
                    <p>{ReactHtmlParser(Tab2Label)}</p>
                </Menu.Item>)}
            {Tab3Label && (
                <Menu.Item key="2" style={{ color: "grey" }} onClick={e => handleClick(e)}>
                    <p>{ReactHtmlParser(Tab3Label)}</p>
                </Menu.Item>
            )}
        </Menu>
    )

    function handleClick(e: any) {
        const key = e.key;

        if (key === "0") {
            Tab1OnClick();
        } if (key === "1") {
            Tab2OnClick();
        } if (key === "2") {
            Tab3OnClick();
        }
    }

    function getSelectedLabel() {
        if (Tab1IsSelected) {
            return ReactHtmlParser(Tab1Label);
        }

        if (Tab2IsSelected) {
            return ReactHtmlParser(Tab2Label);
        }

        if (Tab3IsSelected) {
            return ReactHtmlParser(Tab3Label!);
        }

        return "";
    }

    if (isTablet) {
        return (
            <Row>
                <Col span={24}  >
                    <Dropdown overlay={menuTeams} trigger={['click']}>
                        <Row justify="space-between" align="middle" className={styles.dropdownRow}>
                            <Col>
                                {topTab ? <H5 style={{ marginBlockEnd: '0em', }} className={[styles.title].join(' ')}> {getSelectedLabel()}</H5> 
                                : <H5 className={[styles.title].join(' ')}> {getSelectedLabel()}</H5>}
                            </Col>
                            <Col>
                                {topTab ?  <ChevronDown className={styles.dropdownIcon} />
                                : <ChevronDown />}
                            </Col>
                        </Row>
                    </Dropdown>
                </Col>
            </Row>
        )
    }

    const columnsSize = Tab3Label ? 8 : 12;

    return (
        <Row justify={"space-between"} style={{ marginTop: marginTop }} className={styles.tabs}>
            <Col span={columnsSize}>
                <SubTitleButton disabled={false} selected={Tab1IsSelected} onClick={Tab1OnClick}>
                    {ReactHtmlParser(Tab1Label)}
                </SubTitleButton>
            </Col>
            <Col span={columnsSize}>
                <SubTitleButton disabled={false} selected={Tab2IsSelected} onClick={Tab2OnClick}>
                    {ReactHtmlParser(Tab2Label)}
                </SubTitleButton>
            </Col>
            {Tab3Label && (
                <Col span={columnsSize}>
                    <SubTitleButton disabled={false} selected={Tab3IsSelected} onClick={Tab3OnClick}>
                        {ReactHtmlParser(Tab3Label)}
                    </SubTitleButton>
                </Col>
            )}
        </Row>
    );
}

export default TwoTabs;
import React from 'react';
import { ReactComponent as Icon } from '../../../../assets/images/svg/mail.svg';
import { IconProps } from '../interface';

function MailIcon({ onClick, className }: IconProps) {
    return (
        <Icon onClick={onClick} className={className} />
    )
}

export default MailIcon;
import { firestore } from "./persistence"
import { firestore as FirestoreType } from "firebase";
import { RequestRoleSetting } from "../model/requestRoleSetting/RequestRoleSetting";


const settingsService = {
    updateRoles: async function (request: RequestRoleSetting) {
        let unsubscribeSnapshot: () => void | undefined;
        let timeoutId: NodeJS.Timeout | undefined;
        return new Promise<void>(async (resolve, reject) => {
            let roleSettingChangeRequestRef: FirestoreType.DocumentReference;
            const roleSettingChange = {
                administratorRole: request.administratorRole,
                editorRole: request.editorRole,
                viewerRole: request.viewerRole,
                createdByRef: firestore.collection('users').doc(request.createdByRef),
                statusCode: "pending",
                creationDate: new Date()
            };

            try {
                roleSettingChangeRequestRef = await firestore.collection("request-role-setting-change").add(roleSettingChange);
            } catch (err) {
                console.error(err);
                return reject({ status: "error.event-request.unknown-error" });
            }

            // Listen to status change
            unsubscribeSnapshot = roleSettingChangeRequestRef.onSnapshot(
                snap => {
                    const requestData: any = snap.data();

                    switch (requestData.statusCode) {
                        case "success":
                            return resolve();
                        case "failure":
                            return reject({ status: requestData.errorCode || "error.event-request.unknown-error", message: requestData.errorMessage });
                    }
                },
                err => {
                    console.error(err);
                    return reject({ status: "error.event-request.unknown-error" });
                }
            );

            // Set a timeout in case no state changes happen
            timeoutId = setTimeout(
                reject => {
                    reject({ status: "error.timeout" });
                },
                15000,
                reject
            );
        }).finally(() => {
            // Clear listener and timeout on promise reject/resolve
            if (unsubscribeSnapshot) unsubscribeSnapshot();
            if (timeoutId) clearTimeout(timeoutId);
        });
    },
    getRoles: async function () {
        const rolesSnapshot = await firestore.doc('settings/roles').get()

        if (!rolesSnapshot.exists) {
            throw new Error('Could not fetch roles document')
        }

        return rolesSnapshot.data()!
    },
    getSitemap: async function () {
        const snapshot = await firestore.doc('settings/sitemap').get()

        if (!snapshot.exists) {
            throw new Error('Could not fetch roles document')
        }
        const data = snapshot.data();

        return data?.sitemap as Array<object>;
    },
    getJobTitles: async function () {
        const snapshot = await firestore.doc('settings/job_titles').get()

        if (!snapshot.exists) {
            throw new Error('Could not fetch roles document')
        }

        return snapshot.data()!.teamsPageOrder as Array<string>;
    },
    updateWebVersion: async function (cb: (documentSnapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void) {
        firestore
            .doc('settings/webapp')
            .onSnapshot(cb)
    }
}

export default settingsService
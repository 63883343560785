import { Col, Row } from "antd";
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import Paragraph from "../../../base/text/paragraph";
import styles from './index.module.css';

export type TeamsDescriptionType = {
    id: string;
    textCol1?: string;
    textCol2?: string;
    textCol3?: string;
    consistentStyle?: boolean
}

const TeamsDescription: React.FC<TeamsDescriptionType> = (props: TeamsDescriptionType) => {
    const getText = () => {
        if (props.textCol1) {
            return props.textCol1;
        }

        if (props.textCol2) {
            return props.textCol2;
        }

        if (props.textCol3) {
            return props.textCol3;
        }

        return "";
    }

    return (
        <Row justify={"start"} className={styles.descriptionsContainer} style={{ marginTop: 70 }}>
            <Col lg={24} sm={24} className={styles.description}>
                <Paragraph>{ReactHtmlParser(getText() || "")}</Paragraph>
            </Col>
        </Row>
    );
}


export default TeamsDescription;
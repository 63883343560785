import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { Claims } from '../../../model/auth/Claims';
import AccessDeniedPage from '../../../pages/accessDenied';
import AuthenticationContext from '../authenticationContext';
import { Route } from '../route';

export type ProtectedRouteProps = {
    component: any;
    claims?: Claims[];
    exact?: boolean;
    path?: string;
    wrap?: boolean;
    dark?: boolean;
    pageWrap?: boolean;
    title?: string;
};

export function ProtectedRoute(props: ProtectedRouteProps) {
    const { component, claims, ...rest } = props;
    const context = useContext(AuthenticationContext);

    if (context === null || context.loading) {
        return null;
    }

    if (!context.loading && (!context.auth || context.auth.isAnonymous)) {
        return <Redirect to={{ pathname: "/login" }} />
    }

    if (!claims || context.claims === undefined || !claims.some(claim => !!context.claims!![claim])) {
        return <Route {...rest} component={AccessDeniedPage} />;
    }

    return (
        <Route {...props} />
    );
}
import { Col, Row } from 'antd';
import React from 'react';
import i18n from '../../../../language/i18n';
import Button from '../../../base/button-v2';
import CardItem from '../../../base/cardItem';
import LinkIcon from '../../../base/icon/link';
import MapPinIcon from '../../../base/icon/map-pin';
import TagIcon from '../../../base/icon/tag';
import B1 from '../../../base/text/b1';
import H3 from '../../../base/text/h3';
import H5 from '../../../base/text/h5';
import styles from './index.module.css';

export interface CardPlusProps {
    link?: string
    location?: string
    discount: number | string;
    cover?: any;
    title: string;
    onClick: (title: string, description: string) => any;
    description: string;
}

function CardPlus({ discount, cover, title, onClick, description, link, location }: CardPlusProps) {

    function onIconClick(e: MouseEvent, icon: 'link' | 'map') {
        e.stopPropagation();
        if (icon === 'link' && link) {
            let toOpen = link.trim()
            if (!toOpen.match(/^https?:\/\//i)) {
                toOpen = 'http://' + toOpen;
            }
            window.open(toOpen);
        } else if (icon === 'map' && location) {
            const address = location.split(" ");
            const addressQuery = address.join("+");
            window.open(`https://www.google.com/maps/search/?api=1&query=${addressQuery}`);
        }

    }

    function formatDiscount() {

        if (typeof discount === 'number') {
            discount = `${discount}%`
            return discount;
        } else if (discount[discount.length - 1] !== '%') {
            discount += '%'
            return discount;
        }

        return discount
    }

    function renderDiscount() {

        if (discount !== undefined) {
            return (<H3 className={styles.title}>{formatDiscount()}</H3>)
        }

        return (
            <Col>
                <B1 className={styles.specialConditionsText}>{i18n.t("plus.text.specialConditions")}</B1>
            </Col>
        )
    }

    return (
        <CardItem
            cover={cover}
            className={styles.container}
            bodyStyle={{padding:"10px 15px 15px 15px"}}
            coverClassName={styles.cardCover}
            useBackground
            onClick={() => onClick(title, description)}
        >
            <Row justify="space-between" className={styles.row}>
                <Col className={styles.titleContainer}>
                    <TagIcon className={styles.titleIcon} />
                    {renderDiscount()}
                </Col>
            </Row>
            <Row>
                <Col>
                    <H5 className={styles.description}>{title}</H5>
                </Col>
            </Row>
            <Row justify="space-between">
                <Col className={styles.icons}>
                    <MapPinIcon className={[styles.icon, styles.leftIcon].join(' ')} onClick={(e) => onIconClick(e, 'map')} />
                    <LinkIcon className={styles.icon} onClick={(e: MouseEvent) => onIconClick(e, 'link')} />
                </Col>
                <Col className={styles.bookButtonContainer}>
                    <Button className={styles.button} >{i18n.t('plus.section.card.book')}</Button>
                </Col>
            </Row>
        </CardItem>
    )
}

export default CardPlus;
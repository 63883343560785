import { Col, Divider, Menu, Row } from 'antd';
import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatEventDate } from '../../../../../helpers/header/formatEventDate';
import { Notification } from '../../../../../model/notification/notification';
import B1 from '../../../../base/text/b1';
import Overline from '../../../../base/text/overline';
import styles from './index.module.css';

interface NotificationCardProps {
    item: Notification;
    onClick: () => void;
    className?: string;
}

function NotificationCard({ item, onClick, className }: NotificationCardProps) {
    const publishDate = moment(item.postPublishDate.seconds * 1000).format("DD/MM/YYYY")

    function renderCategory() {
        function translateCategory() {
            switch (item.postType) {
                case "event":
                    return "evento";
                case "news":
                    return "notícia";
                case "note":
                    return "nota";
                case "blog":
                    return "blog"
                case "podcast":
                    return "podcast";
                case "video":
                    return "video"
            }
        }
        if (item.postType === "event") {
            return (
                <div className={styles.eventCategory}>
                    <div style={{ backgroundColor: item.tagColor }} />
                    <Overline>{item.tagName}</Overline>
                </div>
            )
        }

        return <Overline>{translateCategory()} | {item.tagName}</Overline>
    }

    function renderDate() {
        if (item.postType === "event") {
            return <B1 className={styles.eventDate}>{formatEventDate(item)}</B1>
        }

        return <B1>{publishDate}</B1>
    }

    function setRoute() {
        switch (item.postType) {
            case "event":
                return `/agenda/resultados?query=${item.postTitle}`;
            case "video" || "podcast":
                return `/broadcast/${item.postRef.id}`;
            default:
                return `/post/${item.postRef.id}`;
        }
    }

    return (
        <Menu.Item key="0" className={styles.menuItem} onClick={onClick}>
            <Link to={setRoute()}>
                <Row className={styles.postType}>
                    <Col span={24}>
                        {renderCategory()}
                    </Col>
                </Row>
                <Row className={styles.postTitle}>
                    <Col span={24}>
                        <B1>{item.postTitle}</B1>
                    </Col>
                </Row>
                <Row className={styles.date}>
                    <Col span={24}>
                        {renderDate()}
                    </Col>
                </Row>
            </Link>
            <Divider className={[styles.divider, className].join(" ")} />
        </Menu.Item>
    )
}

export default NotificationCard;
import { firestore } from './persistence';

const BroadcastService = {
    
    getBroadcast: async function () {
        const querySnapshot = await firestore.collection("pages_custom").doc("broadcast").get()
        return querySnapshot.data()
    },

}

export default BroadcastService;
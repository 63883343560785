// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";

import firebaseConfig from '../configuration/firebase/firebase-config.json';

// Initialize Firebase
firebase.initializeApp(firebaseConfig.result.sdkConfig);

export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.app().functions('europe-west1');
export const storage = firebase.storage();
export const firebaseSDK = firebase;


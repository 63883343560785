import React from 'react';
import { ReactComponent as See } from '../../../../assets/images/svg/see-icon.svg';

export interface SeeIconProps {
    onClick?: () => any;
    className?: string;
}

function SeeIcon({ onClick, className }: SeeIconProps) {
    return (
        <See onClick={onClick} className={className} />
    )
}

export default SeeIcon;

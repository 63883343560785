import { Row } from "antd";
import React from "react";
import styles from './index.module.css';

export type SectionTitleProps = {
    className?: string;
    children?: any;
}

export default function SectionTitle(props: SectionTitleProps) {
    return (
        <Row justify="start">
            <div className={[styles.title, props.className].join(" ")}> {props.children} </div>
        </Row>
    );
}

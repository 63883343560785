import { Col, Row } from "antd";
import React from "react";
import i18n from "../../../../language/i18n";
import PageTitle from "../../../base/pageTitle";
import Paragraph from "../../../base/text/paragraph";
import styles from './index.module.css';

interface IntroProps {
    className?: string;
    title?: string;
    subTitle?: string;
}

export default function Intro({ className, title, subTitle }: IntroProps) {
    return (
        <Row className={className}>
            <Col>
                <Row>
                    <Col>
                        <PageTitle>
                            {title || i18n.t("whoIsWho.section.intro.title")}
                        </PageTitle>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Paragraph className={styles.description}>
                            {subTitle || i18n.t("whoIsWho.section.intro.description")}
                        </Paragraph>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'

interface queryStringProps {
    name: string;
    query: string | undefined;
}

export function useQueryString(...args: queryStringProps[]) {
    const dependencies = JSON.stringify(args)
    const history = useHistory()
    useEffect(() => {
        const queryParams = new URLSearchParams();
        for (const item of args) {
            const { name, query } = item
            if (query) {
                queryParams.append(name, query)
            } else {
                queryParams.delete(name)
            }
        }
        history.replace({ search: queryParams.toString() })
        return () => {
            for (const item of args) {
                queryParams.delete(item.name)
            }
            return;
        };

        //eslint-disable-next-line
    }, [history, dependencies])
}
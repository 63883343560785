import React, { useEffect, useState } from 'react'
import { Col, Row, Carousel, Divider } from 'antd'
import { useWindowSize } from '../../../../hooks/useWindowSize';
import i18n from '../../../../language/i18n';
import Button from '../../../base/button-v2';
import H3 from '../../../base/text/h3'

import { Event } from '../../../../model/event/event'
import styles from './index.module.css';
import ChevronLeft from '../../../base/icon/chevronLeft';

import HighlightCard from '../highlightCard';
import { HighlightedEvent } from '../../../../model/highlightedEvent/highlightedEvent';

interface HighlightedEventsProps {
    data: HighlightedEvent[] | null,
    onEventClick: (event: Event) => void;
    isSubscribed: (event: Event | null) => boolean | undefined | null;
    getColor: (event: Event) => string;
    loading: boolean;
    refreshEvents: () => void;
}

function HighlightedEvents({ data, onEventClick, isSubscribed, getColor, loading, refreshEvents }: HighlightedEventsProps) {
    const [visibleItems, setVisibleItems] = useState<number>(4)
    const [slidesBase, setSlidesBase] = useState<number>(4);
    const { width } = useWindowSize();

    useEffect(() => {
        width < 1200 ? setSlidesBase(3) : setSlidesBase(4);
    }, [width]);

    function handleSeeMore() {
        setVisibleItems(current => current + 4);
    }

    function renderCard(event: HighlightedEvent) {
        return (
            <HighlightCard
                event={event}
                isSubscribed={isSubscribed(event)}
                isPrivate={event.isPrivate}
                onEventClick={onEventClick}
                getColor={getColor}
                refreshEvents={refreshEvents}
            />
        )
    }

    function renderCarousel() {
        if (data && data.length <= slidesBase) {
            return <Row className={styles.listContainer}> {renderList()}</Row>
        }
        
        return (
            <Col>
                {data &&
                    <Carousel
                        className={styles.carousel}
                        slidesToShow={width < 1200 ? 3 : 4}
                        slidesToScroll={width < 1200 ? 3 : 4}
                        infinite={false}
                        arrows={true}
                        dots={false}
                        nextArrow={<div><ChevronLeft className={styles.arrowNext} /></div>}
                        prevArrow={<div ><ChevronLeft className={styles.arrowPrev} /></div>}
                    >
                        {data.map((event, index) => {
                            return (
                                <Col span={22} className={styles.cardContainer} key={event.id}>
                                    {renderCard(event as any)}
                                </Col>
                            )
                        })}
                    </Carousel>}

            </Col>
        )
    }

    function renderList() {
        return (
            data && data.map((event: HighlightedEvent, index: number) => {
                return (index < visibleItems &&
                    <Col xs={24} sm={11} md={7} xl={5} className={styles.cardContainer} key={event.id}>
                        {renderCard(event)}
                    </Col>
                )
            })
        )
    }

    function renderButton() {
        if (data && data.length > visibleItems) 
            return (
                <Col className={styles.seeMore} span={24}>
                    <Button onClick={handleSeeMore}>{i18n.t("agenda.section.highlighted.seeMore")}</Button>
                </Col>
            )
    }

    return (
        <Row gutter={[0, 30]} className={loading ? styles.containerLoading : ""}>
            <Col className={styles.sectionTitle} span={24}>
                <H3>{i18n.t("agenda.section.highlighted.title")}</H3>
                <Divider className={styles.divider} />
            </Col>
            {
                width <= 767 &&
                <>
                    {renderList()}
                    {renderButton()}
                </>
            }
            {
                width > 767 &&
                renderCarousel()
            }
        </Row>
    )
}

export default HighlightedEvents;
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import AuthenticationContext from "../../components/auth/authenticationContext";
import PageContainer from "../../components/base/pageContainer";
import CategoriesList from "../../components/sections/categories/categoriesList";
import Intro from "../../components/sections/categories/intro";
import { useTags } from "../../hooks/useTags";
import NotificationsService from "../../services/NotificationService";

export default function Categories() {
    const { search } = useLocation()
    const context = useContext(AuthenticationContext);
    const [subscriptions, setSubscriptions] = useState<string[] | undefined>(undefined)
    const initialQueryString = new URLSearchParams(search)
    const categoriesData = useTags(initialQueryString.get("texto"))

    const fetchSubscriptions = useCallback(async () => {

        if (context === null || context.auth === null) {
            return
        }

        const subcriptionIds = await NotificationsService.getSubscriptions(context.auth.uid)
        setSubscriptions(subcriptionIds)

    }, [context])

    useEffect(() => {
        fetchSubscriptions()
    }, [fetchSubscriptions])

    return (
        <PageContainer>
            <Intro />
            <CategoriesList
                categoriesData={categoriesData}
                subscriptions={subscriptions}
                refreshSubscriptions={fetchSubscriptions}
            />
        </PageContainer>
    )
}
import { Carousel, Col, Row, Tooltip } from "antd";
import React from "react";
import H2 from "../../../base/text/h2";
import H5 from "../../../base/text/h5";
import B2 from "../../../base/text/b2";
import { useWindowSize } from "../../../../hooks/useWindowSize";


import styles from './index.module.css';
import CardItem from "../../../base/cardItem";
import { useState } from "react";
import ChevronLeft from "../../../base/icon/chevronLeft";
import Overline from "../../../base/text/overline";
import { KioskItem } from "../../../../model/kioskItem/KioskItem";

interface KioskProps {
    kioskItems?: Array<KioskItem>
}

function Kiosk({kioskItems}: KioskProps) {
    // const [newspapers] = useState<any[]>([
    //     { cover: newspaper1, title: "Observador", publishDate: "Edições diárias", link: 'https://observador.pt/' },
    //     { cover: newspaper2, title: "Jornal de Negócios", publishDate: "Edições diárias", link: 'https://www.jornaldenegocios.pt/' },
    //     { cover: newspaper3, title: "Jornal Económico", publishDate: "Edições diárias", link: 'https://leitor.jornaleconomico.pt/' },
    //     { cover: newspaper4, title: "Expresso", publishDate: "Edições semanais", link: 'https://leitor.jornaleconomico.pt/' },
    //     { cover: newspaper5, title: "Público", publishDate: "Edições diárias", link: 'https://www.publico.pt/' },
    //     { cover: newspaper6, title: "Jornal de Notícias", publishDate: "Edições diárias", link: 'https://www.jn.pt/' },
    //     { cover: newspaper7, title: "Advocatus", publishDate: "Edições diárias", link: 'https://eco.sapo.pt/advocatus/' },
    //     { cover: newspaper8, title: "Diário de Notícias", publishDate: "Edições diárias", link: 'https://www.dn.pt/' }
    // ])
    // TO DELETE LATER

    const [currentSlide, setCurrentSlide] = useState(0);
    const [isEmailCopied, setIsEmailCopied] = useState(false);
    const [isPasswordCopied, setIsPasswordCopied] = useState(false);
    const newspaperRecurrency = new Map<string, string>([
        ["daily", "Edições diárias"],
        ["weekly", "Edições semanais"],
        ["montlhy", "Edições mensais"]
    ])
    

    const { isPhone, isTablet } = useWindowSize();

    function renderHeader() {
        return (
            <Row className={styles.kioskHeader}>
                <Col><H2 className={styles.kioskHeaderTitle}>Quiosque</H2></Col>
            </Row>
        )
    }

    function handleCopyEmail(email: string) {
        navigator.clipboard.writeText(email).then(() => {
            setIsEmailCopied(true);
            setTimeout(() => setIsEmailCopied(false), 5000);
        })
    }

    function handleCopyPassword(password: string) {
        navigator.clipboard.writeText(password).then(() => {
            setIsPasswordCopied(true);
            setTimeout(() => setIsPasswordCopied(false), 5000);
        })
    }

    function renderNewspapers() {

        if (kioskItems === undefined) {
            return
        }

        const itemsLength = kioskItems.length;

        return (
            <Carousel
                className={styles.kioskCarousel}
                slidesToShow={isPhone ? 1 : isTablet ? 2 : 4}
                slidesToScroll={isPhone ? 1 : isTablet ? 2 : 4}
                infinite={false}
                arrows={true}
                dots={false}
                draggable={true}
                afterChange={(currentSlide: number) => setCurrentSlide(currentSlide)}
                nextArrow={(isTablet ? 1 + currentSlide !== itemsLength : 4 + currentSlide !== itemsLength) ? <div id={isTablet ? "mobileCarouselArrowRight" : "desktopCarouselArrowRight"}><ChevronLeft className={styles.arrowNext} /></div> : <div />}
                prevArrow={(currentSlide !== 0) ? <div id={isTablet ? "mobileCarouselArrowLeft" : "desktopCarouselArrowLeft"}><ChevronLeft className={styles.arrowPrev} /></div> : <div />}
            >
                {kioskItems.map((newspaper: KioskItem) => {
                    return (
                        <Tooltip overlayClassName={styles.tooltip} overlayStyle={{ maxWidth: '270px' }} placement="bottomLeft"
                            title={
                                <Col>
                                    <Row className={styles.tooltipRow} onClick={() => handleCopyEmail(newspaper.emailAccount)}>
                                        <Col>
                                            <Row className={styles.labelRow}>
                                                <Overline className={styles.label}>Email:</Overline>
                                                <Overline className={styles.copyLabel}>{isEmailCopied ? "Copiado!" : "Copiar"}</Overline>
                                            </Row>
                                            <Row>
                                                <span>{newspaper.emailAccount}</span>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={styles.tooltipRow} onClick={() => handleCopyPassword(newspaper.passwordAccount)}>
                                        <Col>
                                            <Row className={styles.labelRow}>
                                                <Overline className={styles.label}>Password:</Overline>
                                                <Overline className={styles.copyLabel}>{isPasswordCopied ? "Copiado!" : "Copiar"}</Overline>
                                            </Row>
                                            <Row>
                                                <span>{newspaper.passwordAccount}</span>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            }>
                            <Col xs={24} md={22} lg={20}>
                                <a href={newspaper.link} target="_blank" className={[styles.headerLink, "noDecoration"].join(" ")} rel="noreferrer nofollow">
                                    <CardItem className={styles.newspaperCard} cover={newspaper.cover} >
                                        <H5 className={styles.cardTitle}>{newspaper.name}</H5>
                                        <B2 className={styles.publishedDate} >{newspaperRecurrency.get(newspaper.recurrency)}</B2>
                                    </CardItem>
                                </a>
                            </Col>
                        </Tooltip>
                    )
                }
                )}
            </Carousel>
        )
    }

    return (
        <Row style={{ marginBottom: 80 }}>
            <Col>
                {renderHeader()}
                {renderNewspapers()}
            </Col>
        </Row>
    )
}

export default Kiosk;

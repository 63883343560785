import { Col } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router";
import Paragraph from "../../../../base/text/paragraph";
import { OmniType } from "../../../../../model/omni/OmniType";
import Button from "../../../../base/button-v2";
import Section from "../core";
import styles from './index.module.css';
import i18n from "../../../../../language/i18n";

export type ItemProps = {
    items: any[]
    total: number
    query: string,
    defaultSize: number
}

function UserSection({ total, items, query, defaultSize }: ItemProps) {
    const history = useHistory()

    const displayNoResultsMessage = total <= 5
    return (
        <Section
            type={OmniType.USERS}
            footer={(
                <>
                    {total === 0 ?
                        <Button className={styles.button} onClick={() => history.push(`/quem-e-quem`)}>
                            {i18n.t("omnisearch.results.text.goToWhoIsWho")}
                        </Button> :
                        <Button className={styles.button} onClick={() => history.push(`/quem-e-quem?texto=${query}`)}>
                            {total <= defaultSize ?
                                i18n.t("omnisearch.results.text.seeAllResultsWho") :
                                `${i18n.t("omnisearch.results.text.seeMoreBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inWhoPage")}`}
                        </Button>
                    }
                </>

            )}
            items={(
                <>
                    {items.map((item: any) => (
                        <Col className={styles.userItem} onClick={() => history.push(`/quem-e-quem?texto=${item.displayName}`)}>
                            <Highlighter
                                className={styles.userItemTitle}
                                highlightClassName={styles.highlight}
                                searchWords={[query]}
                                autoEscape={true}
                                textToHighlight={item.displayName}
                            />
                            <Highlighter
                                className={styles.userItemDepartment}
                                highlightClassName={styles.highlight}
                                searchWords={[query]}
                                autoEscape={true}
                                textToHighlight={item.department}
                            />
                        </Col>
                    ))}
                    {displayNoResultsMessage &&
                        <Paragraph className={styles.noResultsMessage}>{total === 0 ?
                            `${i18n.t("omnisearch.results.text.noResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inWho")}` :
                            `${i18n.t("omnisearch.results.text.noMoreResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inWho")}`}
                        </Paragraph>
                    }
                </>
            )}
        />
    )
}

export default UserSection;
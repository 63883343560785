import { Col, Row, Skeleton } from 'antd';
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router';
import PageContainer from '../../components/base/pageContainer';
import Intro from '../../components/sections/post/intro';
import styles from './index.module.css';
import PostsService from '../../services/postsService';
import { Post } from '../../model/post/Post';
import Text from '../../components/sections/template/typography/text';
import PostLead from '../../components/sections/post/lead';
import Separator from '../../components/sections/template/structure/separator';
import RelatedContent from '../../components/sections/post/relatedContent';
import UsefulLinks from '../../components/sections/post/usefulLinks';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import AnalyticsService from '../../services/analytics';

export default function PostPage() {
    const [loading, setLoading] = useState<boolean>(true);
    const [post, setPost] = useState<Post | undefined>(undefined);
    const [relatedPosts, setRelatedPosts] = useState<Array<any>>([]);
    const { width } = useWindowSize();
    const history = useHistory();

    const { id }: any = useParams();
    useDocumentTitle(post?.title);

    const getRelatedPosts = async (category: string) => {
        const criteria = {
            from: 0,
            size: 16,
            tags: [category],
            types: ["not_defined", "news", "blog", "note", "video", "podcast"],
            sort: [{ field: "updatedDate", order: "desc" }],
        }

        return await PostsService.getAll(criteria);
    }

    const getPost = useCallback(async (id: string) => {
        try {
            await PostsService.get(id).then((postData: Post) => {

                if (postData.status === "draft") {
                    history.push("/404");
                }
                setPost(postData)

                AnalyticsService.event(AnalyticsService.postEvent, { id: postData.id, title: postData.title });

                if (postData && postData.tags && postData.tags.length > 0) {
                    getRelatedPosts(postData.tags[0].id)
                        .then((relatedPosts: any) => {
                            const related = relatedPosts.data.response.filter((relatedPost: Post) => relatedPost.id !== id)
                            setRelatedPosts(related);
                            setLoading(false);
                        })
                } else {
                    setLoading(false);
                }
            })
        } catch (error: any) {
            console.log("error", error.message);
            if (error.message === "Post not found") {
                history.push("/404")
            }
        }

    }, [history])

    useEffect(() => {
        getPost(id)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (post?.id !== id && post?.id !== undefined) {
            setLoading(true);
            getPost(id)
        }
    }, [post, id, getPost])

    if (loading === true) {
        return (
            <PageContainer className={styles.loadingContainer}>
                <Skeleton />
            </PageContainer>
        );
    }

    return (
        <>
            <PageContainer className={styles.bodyContainer}>
                <Row>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} className={styles.introContainer}>
                        <Intro
                            title={post!.title}
                            type={post?.type}
                            tag={(post?.tags && post.tags.length > 0 && post?.tags[0].name) || undefined}
                            images={post?.images}
                            updatedDate={post?.updatedDate.toDate().toLocaleDateString("pt-PT")}
                        />
                        {post?.lead && (<PostLead> {post.lead} </PostLead>)}
                    </Col>
                </Row>
                <Row className={styles.bodyRow}>
                {post?.body &&
                        (<Col xs={24} md={14} className={styles.bodyContainer}>
                            <Text data={[{ body: post.body! }]} />
                        </Col>)}
                    {post?.usefulLinks && post?.usefulLinks.length > 0 &&
                        <Col xs={24} sm={24} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} style={{ width: '100%' }}>
                            <Row className={styles.linksContainer} >
                                <Col style={{ width: '100%' }}>
                                    <Separator className={styles.linkSeparator} data={[{ exists: true }]} />
                                    <UsefulLinks className={styles.linkList} data={post.usefulLinks} />
                                    {width > 768 && <Separator className={styles.linkSeparator} data={[{ exists: true }]} />}
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </PageContainer>
            {relatedPosts.length > 0 &&
                <Row>
                    <Col style={{ width: '100%' }}>
                        <PageContainer>
                            <Separator className={styles.separator} data={[{ exists: true }]} />
                            <RelatedContent data={relatedPosts} />
                        </PageContainer>
                    </Col>
                </Row>
            }
        </>
    );
}

import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../base/pageTitle";
import Paragraph from "../../../base/text/paragraph";
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.css';
import { useWindowSize } from "../../../../hooks/useWindowSize";

interface IntroProps {
    title: string;
    className?: string;
    subtitle?: string;
    backgroundImage?: string;
    textColor?: string;
}

export default function Intro({ className, title, subtitle, backgroundImage, textColor }: IntroProps) {
    const [cutouts, setCutouts] = useState<JSX.Element | undefined>(undefined)
    const { isTablet } = useWindowSize();

    useEffect(() => {
        const randNumber = Math.floor(Math.random() * 10) + 1;

        const leftCutout = require('../../../../assets/images/svg/officialCutouts/left' + randNumber + '.svg').default;
        const rightCutout = require('../../../../assets/images/svg/officialCutouts/right' + randNumber + '.svg').default;

        setCutouts(
            <>
                <img src={leftCutout} alt="leftCutout" />
                <img src={rightCutout} alt="rightCutout" />
            </>
        );

        return () => {
            const header = document.getElementById('mainHeader');

            if (header) {
                header.classList.remove("darkTextHeader");
                header.classList.remove("lightTextHeader");
            }
        }
    }, [])

    // CSS Class injection into mainHeader component
    if (textColor) {
        let colorRule;

        switch (textColor) {
            case '#000000':
                colorRule = "darkTextHeader";
                break;
            case '#FFFFFF':
                colorRule = "lightTextHeader";
                break;
            default:
                colorRule = "darkTextHeader";
        }

        const header = document.getElementById('mainHeader');

        if (header) {
            if (header.classList.contains("darkTextHeader")) {
                header.classList.remove("darkTextHeader");
            } else if (header.classList.contains("lightTextHeader")) {
                header.classList.remove("lightTextHeader");
            }
            header.classList.add(colorRule);
        }
    }

    return (
        <Row className={[styles.introContainer, className].join(" ")} style={{ backgroundImage: `url("${backgroundImage}")` }}>
            <Col className={styles.introContent}>
                <Row>
                    <Col style={{ width: '100%' }}>
                        <PageTitle>
                            <span style={{ color: `${textColor}` }}>
                                {ReactHtmlParser(title || "")}
                            </span>
                        </PageTitle>
                    </Col>
                </Row>
                {subtitle !== undefined &&
                    <Row>
                        <Col className={styles.paragraphContainer}>
                            <Paragraph className={styles.description}>
                                <span style={{ color: `${textColor}` }}>
                                    {ReactHtmlParser(subtitle || "")}
                                </span>
                            </Paragraph>
                        </Col>
                    </Row>
                }
            </Col>
            {isTablet ? <></> : (
                <Col className={styles.backgroundContainer}>
                    {cutouts}
                </Col>
            )}
        </Row>
    )
}
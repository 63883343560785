import { KioskItem } from '../model/kioskItem/KioskItem';
import { firestore } from './persistence';

const kioskItemsService = {
    get: async function (id: string) {
        const querySnapshot = await firestore.collection("kiosk_items").doc(id).get();

        return querySnapshot.data() as KioskItem;
    },
}

export default kioskItemsService;
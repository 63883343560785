import { Row, Col, Carousel, List } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import i18n from "../../../../language/i18n";
import { Post } from "../../../../model/post/Post";
import Button from "../../../base/button-v2";
import ChevronLeft from "../../../base/icon/chevronLeft";
import PostCard from "../postCard";
import SectionTitle from "../sectionTitle";
import styles from './index.module.css';

export type RelatedContentProps = {
    className?: string;
    children?: any;
    data: Array<Post>;
}

export default function RelatedContent({ data }: RelatedContentProps) {
    const [visibleItems, setVisibleItems ] = useState<number>(4);
    const [currentSlide, setCurrentSlide] = useState(0);
    
    const { width } = useWindowSize();
    const history = useHistory();

    function handleSeeMore() {
        setVisibleItems(current => current + 4);
    }

    function getPathname(post: Post) {
        if (post.type && (post.type === "video" || post.type === "podcast")) {
            return `/broadcast/${post.id}`;
        }

        return `/post/${post.id}`;
    }

    function renderList() {
        return (
            <Row className={styles.listRowContainer}>
                <Col span={24}>
                    <List
                        grid={{
                            gutter: 20,
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 3,
                            xl: 4,
                            xxl: 4,
                        }}
                        locale={{ emptyText: "Sem Resultados" }}
                        dataSource={data.slice(0, visibleItems)}
                        renderItem={(item: Post) => {
                            return (
                                <List.Item> 
                                    <PostCard 
                                        post={item} 
                                        onPostClick={() => history.push(getPathname(item))}
                                        className={styles.listItem}
                                    />
                                </List.Item>
                            );
                        }}
                    />
                </Col>
            </Row>
        )
    }
    
    function renderButton() {
        if (data.length > visibleItems) {
            return (
                <Col className={styles.seeMore} span={24}>
                    <Button onClick={handleSeeMore}>{i18n.t("agenda.section.highlighted.seeMore")}</Button>
                </Col>
            )
        }

        return <></>
    }

    function shouldRenderNextArrow() {
        const slidesToShow = (width < 1200 ? 3 : 4);
        return currentSlide + slidesToShow === data.length
    }


    
    function renderCarousel() {
        const slidesToShow = width < 1200 ? 3 : 4

        if (data.length <= slidesToShow ) {
            return (<Row className={styles.listRowContainer}> {renderList()} </Row>);
        }

        return (
            <Col span={24}>
                <Carousel
                    className={styles.carousel}
                    slidesToShow={slidesToShow}
                    slidesToScroll={1}
                    infinite={false}
                    arrows={true}
                    dots={false}
                    afterChange={(currentSlide:number)=> setCurrentSlide(currentSlide)}
                    nextArrow={shouldRenderNextArrow() ? <div /> : <div> <ChevronLeft className={styles.arrowNext} /> </div>}
                    prevArrow={(currentSlide !== 0) ? <div ><ChevronLeft className={styles.arrowPrev} /></div> : <div/>}
                >
                    {data.map((item: Post) => {
                        return (
                            <PostCard 
                                post={item} 
                                onPostClick={() => history.push(getPathname(item))}
                            />
                        );
                    })}
                </Carousel>
            </Col>
        );
    }

    return (
        <Row gutter={[0, 24]} className={styles.sectionRow}>
            <Col span={24}>
                <SectionTitle className={styles.sectionTitle}> {i18n.t("post.sections.related.title")} </SectionTitle>
            </Col>
            {
                width <= 991 ?
                    <>
                        {renderList()}
                        {renderButton()}
                    </> :
                    renderCarousel()
            }
        </Row>
    )
}

import { Col, Row } from "antd";
import React from "react";
import PageTitle from "../../../base/pageTitle";
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.css';
import CategoryTitle from "../../../base/categoryTitle";
import i18n from "../../../../language/i18n";

interface IntroProps {
    title: string;
    tag?: string | number;
    type?: string;
    updatedDate?: string;
    className?: string;
    images?: Array<string>;
}

export default function Intro({ className, title, type, tag, updatedDate, images }: IntroProps) {
    const translateType = (type?: string) => {
        return i18n.t(`post.type.${type}`);
    }

    const renderImage = () => {
        if (images?.length === 1) {
            return (
                <Col className={styles.postImage} style={{ width: '100%'}}>
                    <img alt="Post" src={images[0]} />
                </Col>
            )
        }
    }

    const renderTag = () => {
        if (type === undefined) {
            return tag;
        }

        if (tag === undefined) {
            return translateType(type);
        }

        return `${translateType(type)} | ${tag}`;
    }
    
    return (
        <Row className={[styles.introContainer, className].join(" ")}>
            <Col style={{ width: "100%" }}>
                <Row>
                    <Col>
                        <CategoryTitle>
                            { renderTag() }
                        </CategoryTitle>
                    </Col>
                </Row>
            </Col>
            <Col style={{ width: "100%" }}>
                <Row>
                    <Col>
                        <PageTitle className={styles.title}>
                            {ReactHtmlParser(title || "")}
                        </PageTitle>
                    </Col>
                </Row>
            </Col>
            <Col style={{ width: "100%" }}>
                <Row>
                    <Col>
                        <CategoryTitle className={styles.lastUpdate}>
                            {`${i18n.t("post.sections.intro.lastUpdate")}: ${updatedDate}`}
                        </CategoryTitle>
                    </Col>
                </Row>
            </Col>
            { images && images.length > 0 && renderImage() }
        </Row>
    )
}
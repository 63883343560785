import { functions } from './persistence';

import { Criteria } from '../model/criteria/Criteria'

const EventService = {

    /**
     * Criteria:
     * - query: string;
     * - from: number;
     * - size: number
     * - sort: { field: string, order: "asc" | "desc" }[]
     * "range": { field: string, range: "gt" | "gte" | "lt" | "lte", value: Date }[]
     * "tags": string[]
     */

    getAll: function (criteria?: Criteria) {
        return functions.httpsCallable("searchEvents")(criteria || {});
    },

    getHighlightedEvents: function () {
        return functions.httpsCallable("searchHighlightedEvents")({});
    },
}

export default EventService;
import { App } from '../model/app/App';
import { firestore } from './persistence';

const AppsService = {
    get: async function (id: string) {
        const querySnapshot = await firestore.collection("applications").doc(id).get();

        return querySnapshot.data() as App;
    },
}

export default AppsService;
import { Row } from "antd";
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.css';

export interface QuoteProps {
  data?: Array<any>;
}

export default function Quote(props: QuoteProps) {
  if (props.data === undefined || props.data.length === 0) {
    return null;
  }

  const data = props.data[0];

  return (
    data.body && (
      <Row className={styles.wrapper}>
        <blockquote>{ ReactHtmlParser(data.body || "") }</blockquote>
      </Row>
    )
  );
}

import { Page } from "../model/page/Page";
import { firestore } from "./persistence";

const pageService = {
    get: async function (link: string): Promise<Page> {
        const pageSnapshot = await firestore.collection('pages').where("link", "==", link).get();

        if (pageSnapshot.empty || pageSnapshot.docs.length > 1) {
            throw Error("Link não encontrado");
        }

        return pageSnapshot.docs[0].data() as Page;
    },
}

export default pageService
import { auth as AuthType } from 'firebase';
import { auth } from '../services/persistence';
import azureConfig from '../configuration/azure/azure.json';

const authenticationProvider = new AuthType.OAuthProvider('microsoft.com');

authenticationProvider.setCustomParameters({
    prompt: "login",
    tenant: azureConfig.tenantId,
});

const authentication = {
    useFirebaseAuthentication: function (callback: (user: firebase.User | null) => void) {
        return auth.onAuthStateChanged(callback);
    },

    authenticateUser: async function () {
        try {
            // sign in with redirect
            await auth.signInWithPopup(authenticationProvider);
        } catch (error) {
            console.error(error);
        }
    },

    logout: function () {
        return auth.signOut();
    }
}

export default authentication
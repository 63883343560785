import React from 'react'
import { Col, Input, Row } from 'antd';
import styles from './index.module.css';

interface SearchBarProps {
    className?: string;
    style?: any;
    icon?: JSX.Element;
    span?: number;
    placeholder?: string;
    value: string;
    onChange: (e: any) => void;
}

function SearchBar({
    className,
    style,
    icon,
    span,
    placeholder,
    value,
    onChange
}: SearchBarProps) {
    return (
        <Row style={{ ...style }} className={[styles.container, className].join(" ")}>
            <Col span={span || 24}>
                <Input prefix={icon} placeholder={placeholder || ''} value={value} onChange={onChange} />
            </Col>
        </Row>
    )
}

export default SearchBar;
import React from 'react'
import { Col, Row } from 'antd';
import H4 from '../../../base/text/h4';
import B2 from '../../../base/text/b2';
import Statistic from '../../../base/text/statistic';

import styles from './index.module.css';

//Images
import MapPinIcon from '../../../base/icon/map-pin';
import LinkIcon from '../../../base/icon/link';
import TagIcon from '../../../base/icon/tag';
import PhoneIcon from '../../../base/icon/phone';
import i18n from '../../../../language/i18n';

interface ModalBodyProps {
    title: string,
    cover: string,
    location: string,
    link: string,
    discount: string,
    description: string,
    phone: string
}

function ModalBody({ title, cover, location, link, discount, description, phone }: ModalBodyProps) {

    function onIconClick(icon: 'link' | 'map') {
        if (icon === 'link' && link) {
            let toOpen = link.trim()
            if (!toOpen.match(/^https?:\/\//i)) {
                toOpen = 'http://' + toOpen;
            }
            window.open(toOpen);
        } else if (icon === 'map' && location) {
            const address = location.split(" ");
            const addressQuery = address.join("+");
            window.open(`https://www.google.com/maps/search/?api=1&query=${addressQuery}`);
        }

    }

    return (
        <Row>
            <Col className={styles.container}>
                <Row>
                    <Col style={{ width: "100%" }}>
                        <img src={cover} alt="placeholder" style={{ width: "100%", maxWidth: "none" }} />
                    </Col>
                </Row>
                <Row className={styles.title}>
                    <Col>
                        <H4>{title}</H4>
                    </Col>
                </Row>
                {
                    location !== undefined &&
                    (
                        <Row>
                            <Col span={3} className={styles.icon}>
                                <MapPinIcon />
                            </Col>
                            <Col span={20} offset={1}>
                                <B2 className={[styles.info, styles.clickableInfo].join(' ')} onClick={() => onIconClick('map')}>{location}</B2>
                            </Col>
                        </Row>

                    )
                }
                {
                    phone !== undefined && (
                        <Row>
                            <Col span={3} className={styles.icon}>
                                <PhoneIcon />
                            </Col>
                            <Col span={20} offset={1}>
                                <B2 className={styles.info}>{phone}</B2>
                            </Col>
                        </Row>
                    )
                }
                {
                    link !== undefined && (
                        <Row>
                            <Col span={3} className={styles.icon}>
                                <LinkIcon />
                            </Col>
                            <Col span={20} offset={1}>
                                <B2
                                    className={[styles.info, styles.clickableInfo, styles.linkInfo].join(' ')}
                                    onClick={() => onIconClick('link')}
                                >
                                    {link}
                                </B2>
                            </Col>
                        </Row>
                    )
                }
            </Col>
            <Col className={styles.container} >
                {
                    // Waiting for confirmation whether there should be a 'N/D' 
                    // or not rendering at all
                    discount !== undefined ?
                        <Row className={styles.discount}>
                            <Col span={3} className={styles.icon}>
                                <TagIcon />
                            </Col>
                            <Col span={21}>
                                <Statistic>
                                    {`${discount || 0}%`}
                                </Statistic>
                            </Col>
                        </Row> :
                        <Row className={[styles.discount, styles.discountText].join(" ")}>
                            <Col>
                                <Statistic>
                                    {i18n.t("plus.text.specialConditions")}
                                </Statistic>
                            </Col>
                        </Row>
                }
                <Row className={styles.descriptionContainer}>
                    <Col>
                        <B2 className={styles.description}>
                            {description}
                        </B2>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ModalBody;
import React from 'react';
import { ReactComponent as Phone } from '../../../../assets/images/svg/phone-icon.svg';
import { IconProps } from '../interface';

function PhoneIcon({ onClick, className }: IconProps) {
    return (
        <Phone onClick={onClick} className={className} />
    )
}

export default PhoneIcon;

import React from 'react';
import { ReactComponent as Control } from '../../../../assets/images/svg/control.svg';
import { IconProps } from '../interface';

function ControlIcon({ onClick, className }: IconProps) {
    return (
        <Control onClick={onClick} className={className} />
    )
}

export default ControlIcon;

import { Row } from 'antd';
import moment from 'moment';
import React from 'react'
import { Event } from '../../../model/event/event';
import TickIcon from '../../base/icon/tick';
import B2 from '../text/b2';
import styles from './index.module.css';

interface EventSmCardProps {
    event: Event;
    onClick?: (event: Event) => any;
    userSubscribed: boolean | null | undefined;
    className?: string;
}

function EventSmallCard({ event, onClick, userSubscribed, className }: EventSmCardProps) {
    return (
        <Row justify="start" align="top" className={[styles.card, className].join(" ")} onClick={() => onClick && onClick(event)} key={event.id}>
                <Row justify="start" align="middle" className={styles.eventTypeContainer} key={event.id}>
                    <div className={styles.category}  style={{ backgroundColor: event.tags[0].color }} />
                    <B2>{moment(event.startDate).format('HH:mm')}</B2>
                </Row>

                <B2 className={styles.title}>{event.title}</B2>
            {
                userSubscribed &&
                <div className={styles.tick}>
                    <TickIcon />
                </div>
            }
        </Row>
    )
}

export default EventSmallCard;
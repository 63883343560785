import React from 'react';
import { textProps } from '../text-interface';

import styles from './index.module.css';

export default function Paragraph({ children, className, onClick }: textProps) {
    return (
        <span className={[styles.text, className].join(' ')} onClick={onClick}>
            {children}<br></br>
        </span>
    )

}   
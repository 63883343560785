import { firestore } from './persistence';

const WhoIsWhoService = {
    
    getWhoIsWho: async function () {
        const querySnapshot = await firestore.collection("pages_custom").doc("whoiswho").get()
        return querySnapshot.data()
    },

}

export default WhoIsWhoService;
import { OmniType } from "./OmniType"

export class OmniSearchResult {
    size?: number
    type?: OmniType
    response: {
        users?: OmniResponse
        partnerships?: OmniResponse
        events?: OmniResponse
        news?: OmniResponse
        broadcasts?: OmniResponse
        pages?: OmniResponse
        teams?: OmniResponse
        categories?: OmniResponse
        applications?: OmniResponse
        [type: string]: OmniResponse | undefined
    }

    constructor() {
        this.response = {
            users: { response: [], total: 0 },
            partnerships: { response: [], total: 0 },
            events: { response: [], total: 0 },
            news: { response: [], total: 0 },
            broadcasts: { response: [], total: 0 },
            pages: { response: [], total: 0 },
            teams: { response: [], total: 0 },
            categories: { response: [], total: 0 },
            applications: { response: [], total: 0 }
        }
        this.size = 5
        this.type = OmniType.ALL
    }

}

export type OmniResponse = {
    response: Array<any>
    total: number
}
import { Checkbox, Col, Dropdown, Input, Row, Space } from "antd";
import React, { ChangeEvent, Fragment, useState } from "react";
import { useEffect } from "react";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import i18n from "../../../../../language/i18n";
import ChevronDown from "../../../../base/icon/chevronDown";
import SearchIcon from "../../../../base/icon/search";
import H4 from "../../../../base/text/h4";
import EventTypeList from "../eventTypeList";
import FiltersDesktop from "../filtersDesktop";
import Button2 from '../../../../base/button-v2';
import styles from './index.module.css';
import EventService from "../../../../../services/eventService";
import { Tag } from "../../../../../model/tag/tag";
import { Event } from "../../../../../model/event/event";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useHistory, useLocation } from "react-router";


interface SearchBarFiltersProps {
    eventTags: Tag[]
    setEventTags: React.Dispatch<React.SetStateAction<Tag[]>>,
    size: number,
    totalEvents: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setTotalEvents: React.Dispatch<React.SetStateAction<number>>,
    events: Event[] | null,
    setEvents: React.Dispatch<React.SetStateAction<Event[] | null>>
    loading: boolean;
    query: string;
    setQuery: React.Dispatch<React.SetStateAction<string>>
    activeFilters: string[],
    setActiveFilters: React.Dispatch<React.SetStateAction<string[]>>
}

function SearchBarFilters({ totalEvents, eventTags, setEventTags, size, setLoading, setTotalEvents, events, setEvents, loading, query, setQuery, activeFilters, setActiveFilters }: SearchBarFiltersProps) {
    const { isTablet } = useWindowSize();
    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const [checkedList, setCheckedList] = useState<Tag[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();


    useEffect(
        () => {
            let filterTags: string[] = []
            let queryString: string = ""
            if (location.search) {
                let locationQueryString = location.search.substring(1)
                let vars = locationQueryString.split("&")
                for (let index = 0; index < vars.length; index++) {
                    let pair = vars[index].split("=")
                    if (pair[0] === "query") queryString = pair[1]
                    if (pair[0] === "filtros") {
                        let filters = pair[1].split(",")
                        filterTags = [...filters]
                    }
                }
            }
            setLoading(true)
            const getEvents = EventService.getAll({
                size: size,
                sort: [{
                    field: 'startDate',
                    order: 'asc'
                }],
                tags: activeFilters.length ? activeFilters : filterTags,
                ...(query ? { query: query } : {}),
                ...(queryString.length ? { query: queryString } : {}),
            })
            Promise.all([getEvents]).then((values) => {
                const [eventsRes] = values;
                const events = eventsRes.data.response
                setEvents(events);
                setTotalEvents(eventsRes.data.total)
                setLoading(false)
                setQuery(queryString)
                let checkedTags = eventTags.filter((item) => {
                    return filterTags.includes(item.id)
                })

                setCheckAll(eventTags.length === checkedTags.length);
                setCheckedList(checkedTags)
            }).catch(err => {
                setLoading(false)
                console.error(err)
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [size, activeFilters, eventTags]
    )

    async function handleVisibleChange(flag: boolean) {
        setVisibleFilter(flag);
    };

    async function handleSearch(event: ChangeEvent<HTMLInputElement>) {
        setQuery(event.target.value);
    }

    async function handleEnter() {
        let queries = location.search.split("?").join("").split("&")
        const queryText = query.length ? `query=${query}` : ""
        const queryFilters = queries.find((item) => {
            return item.includes("filtros")
        })?.split("?").join("") || ""

        history.push({
            pathname: "/agenda/resultados",
            search: queryFilters.length ? `${queryFilters}&${queryText}` : queryText
        })

        setLoading(true)
        const res = await EventService.getAll({
            ...(query ? { query: query } : {}),
            size: size,
            sort: [{
                field: 'startDate',
                order: 'asc'
            }],
            tags: activeFilters || [],
        }
        )
        setTotalEvents(res.data.total)
        setEvents(res.data.response)
        setLoading(false)
    }

    function onCheckboxChange(list: any[]) {
        let queries = location.search.split("&")
        const queryText = queries.find((item) => item.includes("query"))?.split("?").join("") || ""
        const newActiveFilters = list.map((item: Tag) => item.id)

        if (location.pathname === "/agenda/resultados")
            history.push({
                pathname: "/agenda/resultados",
                search: newActiveFilters.length ? `filtros=${newActiveFilters.join(",")}&${queryText}` : queryText
            })


        setActiveFilters(newActiveFilters)
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < eventTags.length);
        setCheckAll(list.length === eventTags.length);
    }

    function onCheckAllChange(e: CheckboxChangeEvent) {
        let queries = location.search.split("&");
        const queryText = queries.find((item) => item.includes("query"))?.split("?").join("") || "";
        history.push({
            pathname: "/agenda/resultados",
            search: e.target.checked ? `filtros=${eventTags.map((item) => item.id).join(",")}&${queryText}` : queryText
        });

        if (e.target.checked) {
            setCheckedList(eventTags!);
            setActiveFilters(eventTags!.map((tag: Tag) => tag.id));
        } else {
            setCheckedList([]);
        }
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    function clearAll() {
        let queries = location.search.split("&")
        const queryText = queries.find((item) => item.includes("query"))?.split("?").join("") || ""
        history.push({
            pathname: "/agenda/resultados",
            search: queryText
        })
        setActiveFilters([])
        setCheckedList([]);
        setCheckAll(false);
        setIndeterminate(false);
    }

    function getSelectedLabel(e: CheckboxChangeEvent) {
        if (checkedList.length === 1) {
            const [id] = checkedList;
            const selectedOption = eventTags!.find((o: Tag) => o === id);
            return selectedOption?.name;
        }

        if (checkedList.length > 1) {
            return `${i18n.t("plus.section.filters.several")} (${checkedList.length})`;
        }

        return i18n.t("plus.section.filters.select");

    }
    function getSelectedLabelMobile() {
        if (checkedList.length === 1) {
            return checkedList[0].name
        }

        if (checkedList.length > 1) {
            return `${i18n.t("plus.section.filters.several")} (${checkedList.length})`;
        }
        return i18n.t("plus.section.filters.select");
    }


    const menu = (
        <Space direction="vertical" className={styles.dropdownOptionsContainer}>
            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                className={styles.selectAll}
                disabled={loading}
            >
                {i18n.t("plus.section.filters.all")}
            </Checkbox>
            <Checkbox.Group
                onChange={onCheckboxChange}
                className={styles.checkboxGroup}
                style={{ display: 'flex', flexDirection: 'column' }}
                value={checkedList as any}
                disabled={loading}
            >   {
                    eventTags?.map((item: Tag) => <Checkbox className={styles.selectOption} value={item} key={item.id}> {item.name}</Checkbox>)
                }
            </Checkbox.Group>
            <Button2
                filling="text"
                className={styles.clearButton}
                onClick={clearAll}
            >
                {i18n.t("plus.section.filters.clear")}
            </Button2>
        </Space >
    )

    function render() {
        const suffix = isTablet ? undefined : (
            <div className={styles.suffixContainer}>
                <FiltersDesktop
                    checkedList={checkedList}
                    setCheckedList={setCheckedList}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                    eventTypes={eventTags || []}
                    setActiveFilters={setActiveFilters}
                    loading={loading}
                />
            </div>
        );

        const content = (
            <Fragment>
                <Row className={styles.searchInputRow}>
                    <Col className={styles.searchInputContainer}>
                        <Input
                            className={styles.searchInput}
                            prefix={<SearchIcon className={styles.searchInputIcon} />}
                            placeholder={"Pesquisar"}
                            value={query}
                            suffix={suffix}
                            onChange={handleSearch}
                            onPressEnter={handleEnter}
                        />
                    </Col>
                </Row>
                <EventTypeList
                    totalQueriedEvents={totalEvents ? totalEvents : 0}
                    label={getSelectedLabel}
                    checkedList={checkedList}
                    eventTypes={eventTags || []}
                    setCheckedList={setCheckedList}
                    setEventTypes={setEventTags}
                    clearFilters={clearAll}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    className={styles.listLarge}
                    divider
                />
                <Row className={styles.filter}>
                    <Col span={24} className={styles.dropdownContainer}>
                        <Dropdown
                            overlay={menu}
                            onVisibleChange={handleVisibleChange}
                            visible={visibleFilter}
                            overlayClassName={styles.overlay}
                            trigger={['click']}
                        >
                            <Row justify="space-between" align="middle" className={styles.dropdownRow}>
                                <Col>
                                    <H4>{getSelectedLabelMobile()}</H4>
                                </Col>
                                <Col>
                                    <ChevronDown />
                                </Col>
                            </Row>
                        </Dropdown>
                    </Col>

                    <Col>
                        <EventTypeList
                            totalQueriedEvents={totalEvents ? totalEvents : 0}
                            label={getSelectedLabel}
                            eventTypes={eventTags || []}
                            checkedList={checkedList}
                            setEventTypes={setEventTags}
                            setCheckedList={setCheckedList}
                            clearFilters={clearAll}
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                            className={styles.listSmall}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
        return content
    }

    return render()

}
export default SearchBarFilters;
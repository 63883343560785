import { Col, Row } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import PageContainer from '../../components/base/pageContainer'
import B2 from '../../components/base/text/b2';
import H2 from '../../components/base/text/h2';
import i18n from '../../language/i18n';

import styles from './index.module.css';

function PageNotFound() {
    return (
        <PageContainer className={styles.container}>
            <Row className={styles.notFoundRowTitle} justify="center">
                <Col>
                    <H2>{i18n.t("pageNotFound.title")}</H2>
                </Col>
            </Row>
            <Row className={styles.notFoundRowMessage} justify="center">
                <Col>
                    <B2>{i18n.t("pageNotFound.description")}</B2>
                </Col>
            </Row>
            <Row className={styles.homeLink} justify="center">
                <Col span={24} >
                    <Link to="/">
                        <B2>{i18n.t("pageNotFound.homeButton")}</B2>
                    </Link>
                </Col>
            </Row>
        </PageContainer>
    )
}

export default PageNotFound;
import { Col } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router";
import Paragraph from "../../../../base/text/paragraph";
import { OmniType } from "../../../../../model/omni/OmniType";
import Button from "../../../../base/button-v2";
import Section from "../core";
import styles from './index.module.css';
import i18n from "../../../../../language/i18n";

export type ItemProps = {
    items: any[]
    total: number
    query: string,
    defaultSize: number
}

function PartnershipSection({ total, items, query, defaultSize }: ItemProps) {
    const history = useHistory()

    const displayNoResultsMessage = total <= 5
    return (
        <Section
            type={OmniType.PARTNERSHIPS}
            footer={(
                <>
                    {total === 0 ?
                        <Button className={styles.button} onClick={() => history.push(`/plus-plmj`)}>
                            {i18n.t("omnisearch.results.text.goToPlus")}
                        </Button> :
                        <Button className={styles.button} onClick={() => history.push(`/plus-plmj?texto=${query}`)}>
                            {total <= defaultSize ?
                                i18n.t("omnisearch.results.text.seeAllResultsPlus") :
                                `${i18n.t("omnisearch.results.text.seeMoreBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inPlusPage")}`}
                        </Button>
                    }
                </>
            )}
            items={(
                <>
                    {items.map((item: any) => {
                        const hasCategory = item.categories && item.categories[0] && item.categories[0].name
                        return (
                            <Col className={styles.partnershipItem} onClick={() => history.push(`/plus-plmj?texto=${item.title}`)}>
                                <Highlighter
                                    className={styles.partnershipItemTitle}
                                    highlightClassName={styles.highlight}
                                    searchWords={[query]}
                                    autoEscape={true}
                                    textToHighlight={item.title}
                                />
                                {hasCategory &&
                                    <Highlighter
                                        className={styles.partnershipItemCategory}
                                        highlightClassName={styles.highlight}
                                        searchWords={[query]}
                                        autoEscape={true}
                                        textToHighlight={item.categories[0].name}
                                    />
                                }
                            </Col>
                        )
                    })}
                    {displayNoResultsMessage &&
                        <Paragraph className={styles.noResultsMessage}>{total === 0 ?
                            `${i18n.t("omnisearch.results.text.noResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inPlus")}` :
                            `${i18n.t("omnisearch.results.text.noMoreResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inPlus")}`}
                        </Paragraph>
                    }
                </>
            )}
        />
    )
}

export default PartnershipSection;
import React from 'react';
import { ReactComponent as Tag } from '../../../../assets/images/svg/tag.svg';
import styles from './index.module.css';

export interface IconProps {
    onClick?: () => any;
    className?: string;
}

function TagIcon({ onClick, className }: IconProps) {
    return (
        <Tag onClick={onClick} className={[styles.tagIcon, className].join(' ')} />
    )
}

export default TagIcon;

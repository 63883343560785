import { useState } from "react";
import { useAsync } from "react-async-hook";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import useConstant from "use-constant";

export const useDebouncedSearch = (searchFunction: any, initialSize: number = 8, initialQuery: string = "", ...args: any) => {
    const [query, setQuery] = useState<string>(initialQuery);
    const [from, setFrom] = useState<number | string>(0);
    const [size, setSize] = useState<number | undefined>(initialSize);

    const debouncedSearchFunction = useConstant(() =>
        AwesomeDebouncePromise(searchFunction, 300)
    );

    const searchResults = useAsync(
        async () => { return debouncedSearchFunction(query, from, size, ...args); },
        [debouncedSearchFunction, query, from, size, ...args]
    );

    return {
        size,
        setSize,
        from,
        setFrom,
        query,
        setQuery,
        searchResults,
    };
};
import React, { useEffect, useState } from 'react'
import FullCalendar, { DatesSetArg, EventContentArg } from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import './calendar.css'
import styles from './index.module.css';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import calendarSVG from '../../../../assets/images/svg/calendar.svg';
import { CalendarData } from '../../../../model/fullCalendarData/fullCalendarData';
import MouseFollowTooltip from '../../../base/mouseFollowTooltip';
import moment from 'moment';

interface CalendarWeekProps {
    calendarData: CalendarData[] | null;
    handleWeekChange: (range: DatesSetArg) => Promise<void>;
    isLoading: boolean;
}

function CalendarWeek({ calendarData, handleWeekChange, isLoading }: CalendarWeekProps) {
    const { isPhone } = useWindowSize();
    const [lockScroll, setLockScroll] = useState<string>("");

    useEffect(() => {
        if (!isPhone) {
            const titleNode = document.querySelector('.fc-toolbar-title');
            const parentNode = titleNode?.parentNode;
            if (parentNode && parentNode?.childElementCount < 2) {
                const calendarIcon = document.createElement('img');
                calendarIcon.src = calendarSVG;

                parentNode?.prepend(calendarIcon)
            }
        }

    }, [isPhone])

    useEffect(() => {
        let layer: Element;
        if (isLoading) {
            const calendarNode = document.querySelector('.viewWeek');
            layer = document.createElement('div');
            layer.className = "loading";
            calendarNode && calendarNode.appendChild(layer);
        } else {
            const layerNode = document.querySelector(".loading");
            layerNode?.remove()
        }
    }, [isLoading])

    function handleMouseEnter() {
        setLockScroll("lockScroll");
    }

    function handleMouseLeave() {
        setLockScroll("");
    }

    function renderEventContent(eventContent: EventContentArg) {
        let className = '';
        let category = '';
        switch (eventContent.event.groupId.toLowerCase()) {
            case '#8ad6af':
                className = 'internalEvent';
                category = styles.internalEvent
                break;
            case '#fac362':
                className = 'externalEvent';
                category = styles.externalEvent
                break;
            case '#aea2cd':
                className = 'training';
                category = styles.training
                break;
        }

        const eventStartedBeforeWeek = moment(eventContent.event.start).isBefore(moment(eventContent.view.activeStart));

        return (
            <div className={[className, styles.event, eventStartedBeforeWeek && styles.hasOpacity].join(" ")} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <MouseFollowTooltip
                    className={styles.tooltipLayer}
                    content={eventContent.event.title}
                    placement={isPhone ? "top-center" : "left-center"}
                >
                    <div className={[category, styles.identifier].join(" ")} />
                    <span>
                        {eventContent.event.title}
                    </span>
                </MouseFollowTooltip>
            </div>
        )
    }

    return (
        <FullCalendar
            viewClassNames={["viewWeek", lockScroll].join(" ")}
            headerToolbar={isPhone ? { start: 'prev', center: 'title', end: 'next' } : { start: 'prev, next', center: 'title', end: '' }}
            plugins={[timeGridPlugin]}
            initialView='timeGridWeek'
            dayHeaderFormat={{ weekday: 'narrow' }}
            locale="pt-pt"
            events={calendarData || []}
            eventContent={renderEventContent}
            contentHeight={525}
            fixedWeekCount={true}
            allDaySlot={true}
            nowIndicator={true}
            nowIndicatorClassNames={styles.nowIndicator}
            scrollTime={'08:00:00'}
            datesSet={handleWeekChange}
        />
    )
}

export default CalendarWeek

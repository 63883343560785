import { Col, Row } from "antd";
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { prependHttp } from "../../../../../helpers/prependHttp";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import styles from './index.module.css';

export interface ImageTextProps {
  data?: Array<any>;
}

export default function ImageText(props: ImageTextProps) {
  const { isTablet } = useWindowSize();

  if (props.data === undefined || props.data.length === 0) {
    return null;
  }

  const data = props.data;

  return (
    data[0].src && data[1].body && (
      <Row className={styles.wrapper}>
        <Row justify="space-between" gutter={isTablet ? 0 : 30}>
          <Col span={isTablet ? 24 : 12} style={{marginBottom: isTablet ? 18 : 0}}>
            { data[0].link ?
              <a href={prependHttp(data[0].link)} target="_blank"rel="noopener noreferrer"><img alt={""} src={data[0].src}/></a>
              :
              <img alt={""} src={data[0].src}/>
            }
            </Col>
          <Col span={isTablet ? 24 : 12}>{ ReactHtmlParser(data[1].body || "") }</Col>
        </Row>
      </Row>
    )
  );
}

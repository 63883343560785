import { Col } from "antd";
import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import Paragraph from "../../../../base/text/paragraph";
import { OmniType } from "../../../../../model/omni/OmniType";
import Button from "../../../../base/button-v2";
import Section from "../core";
import styles from './index.module.css';
import i18n from "../../../../../language/i18n";

export type ItemProps = {
    items: any[]
    total: number
    query: string,
    defaultSize: number,
    loadNextPage: () => Promise<void>
}

function ApplicationSection({ total, items, query, loadNextPage }: ItemProps) {
    const [loading, setLoading] = useState(false)

    async function onNextPageClick() {
        setLoading(true)

        try {
            await loadNextPage()
        } catch (error) {
            console.log('error ', error)
        }

        setLoading(false)
    }

    const displayNoResultsMessage = total <= 5
    
    return (
        <Section
            type={OmniType.APPLICATIONS}
            footer={(
                <>
                    {items.length < total &&
                        <Button className={styles.button} onClick={onNextPageClick} loading={loading}>
                            {i18n.t("omnisearch.results.text.seeMoreResults")}
                        </Button>
                    }
                </>
            )}
            items={(
                <>
                    {items.map((item: any) => {
                        return (
                            <Col className={styles.applicationItem} onClick={() => window.open(item.link)}>
                                <Highlighter
                                    className={styles.applicationItemTitle}
                                    highlightClassName={styles.highlight}
                                    searchWords={[query]}
                                    autoEscape={true}
                                    textToHighlight={item.title}
                                />
                            </Col>
                        )
                    })}
                    {displayNoResultsMessage &&
                        <Paragraph className={styles.noResultsMessage}>{total === 0 ?
                            `${i18n.t("omnisearch.results.text.noResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inApplications")}` :
                            `${i18n.t("omnisearch.results.text.noMoreResultsBeforeQuery")} “${query}” ${i18n.t("omnisearch.results.text.inApplications")}`}
                        </Paragraph>
                    }
                </>
            )}
        />
    )
}

export default ApplicationSection;
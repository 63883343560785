import { Dropdown, Space, Checkbox, Divider } from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory, useLocation } from "react-router";
import i18n from "../../../../../language/i18n";
import { Tag } from "../../../../../model/tag/tag";
import ChevronDown from "../../../../base/icon/chevronDown";
import B1 from "../../../../base/text/b1";
import styles from './index.module.css';

export interface FiltersDesktopProps {
    checkedList: Tag[];
    eventTypes: Tag[];
    setCheckedList: Dispatch<SetStateAction<Tag[]>>;
    setIndeterminate: Dispatch<SetStateAction<boolean>>;
    indeterminate: boolean;
    checkAll: boolean;
    setCheckAll: Dispatch<SetStateAction<boolean>>;
    setActiveFilters: Dispatch<SetStateAction<string[]>>
    loading?: boolean;
}

function FiltersDesktop(props: FiltersDesktopProps) {
    const { checkedList,
        setCheckedList,
        indeterminate,
        setIndeterminate,
        checkAll,
        setCheckAll,
        eventTypes,
        setActiveFilters,
        loading
    } = props;

    const history = useHistory()
    const location = useLocation()
    const [visibleFilter, setVisibleFilter] = useState<boolean>(false);


    const onChange = (list: any) => {
        let queries = location.search.split("&")
        const queryText = queries.find((item) => item.includes("query"))?.split("?").join("") || ""
        const activeFilters = list.map((item: Tag) => item.id)

        if (location.pathname === "/agenda/resultados")
            history.push({
                pathname: "/agenda/resultados",
                search: activeFilters.length ? `filtros=${activeFilters.join(",")}&${queryText}` : queryText
            })

        setActiveFilters(activeFilters)
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < eventTypes.length);
        setCheckAll(list.length === eventTypes.length);
    };

    const onCheckAllChange = (e: any) => {
        let queries = location.search.split("&")
        const queryText = queries.find((item) => item.includes("query"))?.split("?").join("") || ""
        if (location.pathname === "/agenda/resultados" && queryText) {
            history.push({
                pathname: "/agenda/resultados",
                search: e.target.checked ? `filtros=${eventTypes.map((item) => item.id).join(",")}&${queryText}` : queryText
            })
        }

        if (location.pathname === "/agenda/resultados" && !queryText) {
            history.push({
                pathname: "/agenda/resultados",
                search: e.target.checked ? `filtros=${eventTypes.map((item) => item.id).join(",")}` : ""
            })
        }

        setCheckedList(e.target.checked ? eventTypes : []);
        setActiveFilters(eventTypes!.map((tag: Tag) => tag.id))
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const clearCheckAll = () => {
        let queries = location.search.split("&")
        const queryText = queries.find((item) => item.includes("query"))?.split("?").join("") || ""
        history.push({
            pathname: location.pathname,
            search: queryText
        })
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);
        setActiveFilters([])
    }

    const onVisibleFilterChange = (flag: boolean) => {
        setVisibleFilter(flag);
    }

    const getSelectedLabel = () => {
        if (checkedList.length > 1) {
            return `${i18n.t("plus.section.filters.several")} (${checkedList.length})`;
        }

        if (checkedList.length === 1) {
            const id = checkedList[0].id
            const option = eventTypes.find((o: Tag) => o.id === id)

            return option?.name;
        }

        return i18n.t("plus.section.filters.select");
    }

    const menuFilter = (
        <Space direction="vertical" className={styles.menuFilterContainer}>
            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                className={styles.selectAll}
            >
                {i18n.t("plus.section.filters.all")}
            </Checkbox>
            <Checkbox.Group
                onChange={onChange}
                className={styles.selectGroup}
                value={checkedList as any}
                disabled={loading}
            >
                {eventTypes.map((item: Tag) => {
                    return <Checkbox className={styles.selectOption} value={item} key={item.id}>{item.name}</Checkbox>
                })}
            </Checkbox.Group>
            <span className={styles.clearButton} onClick={clearCheckAll}> {i18n.t("plus.section.filters.clear")} </span>
        </Space>
    );

    return (
        <div className={styles.filterContainer}>
            <Dropdown
                overlay={menuFilter}
                trigger={['click']}
                onVisibleChange={onVisibleFilterChange}
                visible={visibleFilter}
            >
                <div className={styles.dropdown}>
                    <Divider type="vertical" className={styles.divider} />
                    <span className={styles.pointer} onClick={e => e.preventDefault()}>
                        <B1>
                            {getSelectedLabel()} <ChevronDown className={styles.chevron} />
                        </B1>
                    </span>
                </div>
            </Dropdown>
        </div>
    )
}
export default FiltersDesktop;
import React from 'react';
import { ReactComponent as Search } from '../../../../assets/images/svg/homepage/search.svg';
import { IconProps } from '../interface';

function SearchIcon({ onClick, className }: IconProps) {
    return (
        <Search onClick={onClick} className={className} />
    )
}

export default SearchIcon;
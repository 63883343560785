import React from "react";
import H2 from "../text/h2";
import styles from './index.module.css';

export type PageTitleProps = {
    className?: string;
    children?: any;
}

const PageTitle: React.FC<PageTitleProps> = (props: PageTitleProps) => {
    return (
        <H2 className={[styles.title, props.className].join(" ")}>
            {props.children}
        </H2>
    );
}

export default PageTitle;
import { Row, Col } from "antd";
import React from "react";

import H3 from "../../../base/text/h3";
import styles from './index.module.css';

interface UserDetailData {
    id?: string,
    name: string,
    jobTitle?: string,
    department?: string,
    mobilePhone?: string,
    businessPhones?: Array<string>,
    ext?: string,
    email?: string,
    officeLocation?: string,
}


export interface UserDetailProps {
    data?: UserDetailData,
    renderData: (data: any) => JSX.Element
}

function UserDetail({ data, renderData }: UserDetailProps) {
    return (
        <Row className={styles.container}>
            <Col>
                <H3 className={styles.title}> {data?.name} </H3>
                {renderData(data)}
            </Col>
        </Row>
    );
}

export default UserDetail;
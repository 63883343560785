import { AuthUser } from '../model/auth/AuthUser';
import { firestore, storage } from './persistence';

const ProfilePictureService = {

    getProfilePicture: async function (user: AuthUser) {
        try {
            const userSnapshot = await firestore.collection("users").doc(user.claims?.user_id).get()
            const userData = userSnapshot.data()
            const ref = storage.ref("profilePictures").child((userData!.mailNickname as string).toUpperCase() + ".jpg")
            const url = await ref.getDownloadURL()
            return url
        } catch (error) {
        }
    },

}

export default ProfilePictureService;
import { Col, Row } from "antd";
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import Paragraph from "../../../base/text/paragraph";
import styles from './index.module.css';

export type TwoTextColumnsProps = {
    id: string;
    textCol1?: string;
    textCol2?: string;
    consistentStyle?: boolean
}

const TwoTextColumns: React.FC<TwoTextColumnsProps> = (props: TwoTextColumnsProps) => {

    return (
        <Row className={styles.descriptionsContainer} style={{ marginTop: 70 }}>
            <Col id={props.id + "col1"} lg={11} sm={24} className={styles.description}>
                <Paragraph>{ReactHtmlParser(props.textCol1 || "")}</Paragraph>
            </Col>
            <Col id={props.id + "col2"} lg={{ span: 11, offset: 2 }} sm={24} className={props.consistentStyle ? styles.description : styles.description2}>
                <Paragraph>{ReactHtmlParser(props.textCol2 || "")}</Paragraph>
            </Col>
        </Row>
    );
}

export default TwoTextColumns;

import { Col, Row } from "antd";
import React from "react";
import i18n from "../../../../language/i18n";
import PageTitle from "../../../base/pageTitle";
import Paragraph from "../../../base/text/paragraph";
import styles from "./index.module.css";

interface IntroProps {
  title: string | undefined;
  subTitle: string | undefined;
  background: string | undefined;
  loading: boolean
}

function Intro({ title, subTitle, background, loading }: IntroProps) {
  return (
    <Row
      className={[styles.container, styles.background, loading? styles.loading: ""].join(" ")}
      style={
        background === undefined
          ? {}
          : {
              backgroundImage: `url(${background})`,
            }
      }
    >
      <Col>
        <Row>
          <Col>
            {title === undefined ? (
              <PageTitle>{i18n.t("plus.section.intro.title")}</PageTitle>
            ) : (
              <PageTitle>{title}</PageTitle>
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            {subTitle === undefined ? (
              <Paragraph className={styles.description}>
                {i18n.t("plus.section.intro.description")}
              </Paragraph>
            ) : (
              <Paragraph className={styles.description}>{subTitle}</Paragraph>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Intro;

export const Schemas = {
    USERS: {
        collection: 'users',
        collectionFormat: 'users/{id}'
    },
    USER_SUBSCRIPTIONS: {
        collection: 'users/{parentId}/subscriptions',
        collectionFormat: 'users/{parentId}/subscriptions/{id}',
        collectionId: 'subscriptions'
    },
    TAGS: {
        collection: 'tags',
        collectionFormat: 'tags/{id}'
    },
    REQUEST: {
        collection: 'request',
        collectionFormat: 'request/{id}'
    },
    LOGIN_FAILED_EVENT: {
        collection: 'login_failed_event',
        collectionFormat: 'login_failed_event/{id}'
    }
}
import React from "react";
import styles from "./index.module.css";
import cutoutLeft from "../../../../assets/images/svg/backgrounds/cutouts/left5.svg";
import cutoutRight from "../../../../assets/images/svg/backgrounds/cutouts/right4.svg";

export interface BannerProps {
  className?: string;
  background: string | undefined;
  loading: boolean
}

function BackgroundBanner({ className, background, loading }: BannerProps) {
  return (
    <div
      className={[className, styles.background, loading ? styles.loading: ""].join(" ")}
      style={
        background === undefined
          ? {}
          : {
              backgroundImage: `url(${background})`,
              backgroundPosition: "center",
            }
      }
    >
      <img className={styles.cutoutLeft} src={cutoutLeft} alt="cutout" />
      <img className={styles.cutoutRight} src={cutoutRight} alt="cutout" />
    </div>
  );
}

export default BackgroundBanner;

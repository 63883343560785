import { useCallback, useEffect, useState } from "react";
import { OmniSearchResult } from "../model/omni/OmniSearchResult";
import { OmniType } from "../model/omni/OmniType";
import OmniService from "../services/OmniService";
import { useDebouncedSearch } from "./useDebouncedSearch";

const defaultSize = 5;
export function useOmnisearch(initialSize?: number | undefined, initialQuery?: string | null, initialType?: OmniType | null) {
    const [data, setData] = useState<OmniSearchResult>(new OmniSearchResult());
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState<OmniType>(initialType || OmniType.ALL);

    const getAll = useCallback(async (query: string = "", _: number = 0, size: number = defaultSize, type?: string) => {

        if (query === "" || query.length < 3) {
            return new OmniSearchResult();
        }

        const criteria = {
            ...(query !== "" ? { query } : {}),
            ...(type !== "" ? { type } : {}),
            size
        }

        try {
            const response = await OmniService.getAll(criteria);
            return response.data;
        } catch (e) {
            return { response: [] };
        }
    }, []);


    const useGetAll = () => useDebouncedSearch(getAll, initialSize || defaultSize, initialQuery || undefined, type);

    const {
        setSize,
        query,
        setQuery,
        searchResults
    } = useGetAll();

    useEffect(() => {
        setLoading(searchResults.loading)

        if (searchResults.result === undefined) {
            return
        }

        setData(searchResults.result)
    }, [searchResults])

    return {
        loading,
        data,
        query,
        type,
        setSize,
        setType,
        setQuery,
        searchResults
    }
}
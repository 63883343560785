import { Checkbox, Dropdown, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react'
import { GetLabel } from '../../../../../../helpers/getLabel';
import i18n from '../../../../../../language/i18n';
import { Tag } from '../../../../../../model/tag/tag';
import ChevronDown from '../../../../../base/icon/chevronDown';
import B1 from '../../../../../base/text/b1';
import styles from './index.module.css'

interface DropdownFiltersMobileProps {
    checkedList: Tag[]
    optionsList: Tag[],
    indeterminate: boolean,
    allChecked: boolean,
    loading: boolean,
    // list must be 'any' to avoid conflicts with AntD
    handleFilterChange: (list: any) => void,
    handleClearAll: () => void,
    handleCheckAll: (event: CheckboxChangeEvent) => void,
    handleVisibleChange: (flag: boolean) => void,
    visible: boolean,
    className?: string
}

function DropdownFiltersMobile({
    indeterminate,
    handleCheckAll,
    allChecked,
    loading,
    checkedList,
    handleFilterChange,
    handleClearAll,
    handleVisibleChange,
    visible,
    optionsList,
    className
}: DropdownFiltersMobileProps) {

    const getLabel = new GetLabel();

    const menuFilter = (
        <Space direction="vertical" className={styles.menuFilterContainer}>
            <Checkbox
                indeterminate={indeterminate}
                onChange={handleCheckAll}
                checked={allChecked}
                className={styles.selectAll}
                disabled={loading}
            >
                {i18n.t("plus.section.filters.all")}
            </Checkbox>
            <Checkbox.Group
                value={checkedList as any}
                onChange={handleFilterChange}
                className={styles.selectGroup}
                disabled={loading}
            >
                {optionsList.map((item) => {
                    return <Checkbox className={styles.checkbox} value={item} key={item.id}>{item.name}</Checkbox>
                })}
            </Checkbox.Group>
            <span
                className={[styles.clearButton, loading ? styles.clearAllDisabled : ""].join(" ")}
                onClick={loading ? undefined : handleClearAll}
            >
                {i18n.t("plus.section.filters.clear")}
            </span>
        </Space>
    );

    return (
        <Dropdown
            className={className}
            overlay={menuFilter}
            trigger={['click']}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            overlayClassName={styles.dropdownOverlay}
        >
            <div className={styles.dropdown}>
                <span className={[styles.pointer, styles.filterLabel].join(" ")}>
                    <B1 className={styles.label}>
                        {getLabel.getDropdownCheckboxLabel(checkedList, optionsList)} <ChevronDown className={styles.chevron} />
                    </B1>
                </span>
            </div>
        </Dropdown>
    )
}

export default DropdownFiltersMobile;

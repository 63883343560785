import React, { ReactNode } from 'react'
import { useHover, useLayer, useMousePositionAsTrigger } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';
import styles from './index.module.css';

interface MouseFollowTooltipProps {
    children?: ReactNode;
    className?: string;
    content: string;
    placement?: PlacementType;
    possiblePlacements?: PlacementType[]
}

function MouseFollowTooltip({ children, className, content, placement, possiblePlacements }: MouseFollowTooltipProps) {
    const [isOver, hoverProps] = useHover();

    const { handleMouseEvent, trigger, parentRef } = useMousePositionAsTrigger({
        enabled: isOver
    });

    const { renderLayer, layerProps } = useLayer({
        isOpen: isOver,
        overflowContainer: true,
        auto: false,
        snap: true,
        placement: placement || "top-center",
        triggerOffset: 8,
        containerOffset: 16,
        trigger,
        possiblePlacements: possiblePlacements
    });
    return (
        <div ref={parentRef} className={styles.box}>
            <div {...hoverProps} onMouseMove={handleMouseEvent} >
                <span>{content}</span>
            </div>
            {isOver &&
                renderLayer(
                    <div className={className} {...layerProps}>
                        {children}
                    </div>
                )}
        </div>
    );
}

export default MouseFollowTooltip;
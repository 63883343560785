import React from 'react'
import styles from './index.module.css';
import cutout from '../../../../assets/images/svg/backgrounds/cutouts/left5.svg';
import { useWindowSize } from '../../../../hooks/useWindowSize';

export interface BannerProps {
    className?: string;
    background?: string;
    loading: boolean
}

function BackgroundBanner({ className, background, loading }: BannerProps) {
    const { isTablet } = useWindowSize();

    if (isTablet) {
        return <div className={[className, styles.background, loading ? styles.loading: "" ].join(' ')}
            style={background === undefined ? {} : { backgroundImage: `url(${background})`, backgroundSize: "cover" }}
        />
    }
    return (
        <div className={[className, styles.background, loading ? styles.loading: ""].join(' ')}
            style={background === undefined ? {} : { backgroundImage: `url(${background})`, backgroundSize: "cover" }}
        >
            <img className={styles.cutoutLeft} src={cutout} alt="cutout" />
        </div>
    )
}

export default BackgroundBanner;
import { Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import styles from './index.module.css';

type VideoAlignProps = 'flex-start' | 'center' | 'flex-end'

export interface VideoSectionProps {
    data: { type: string, video: { align: VideoAlignProps, link: string, provider: string, size: string }};
}

export default function VideoSection(props: VideoSectionProps) {
    const [currentHeight, setCurrentHeight] = useState<number>(0)
    const divRef = useRef<HTMLDivElement | null>(null);
    const { width } = useWindowSize();

    useEffect(() => {
        setCurrentHeight(divRef.current ? (divRef.current.offsetWidth / 16) * 9  : 0);
    }, [width])

    if (props.data === undefined) {
        return null;
    }

    const { video } = props.data;
    
    let alignment = 'unset';
    switch (video.align) {
        case 'center': 
            alignment = 'auto';
            break;
        case 'flex-end': 
            alignment = '0 0 0 auto';
            break;
    }

    let classVideoContainer = 'videoContainerFull';
    switch (video.size) {
        case '100': 
            classVideoContainer = 'videoContainerFull';
            break;
        case '50': 
            classVideoContainer = 'videoContainerHalf';
            break;
    }

    const renderVideo = () => {
        return (
            <iframe
                src={video.link}
                style={{ border: "none", height: currentHeight, width: '100%' }}
                title={video.link}
                allowFullScreen
            />
        );
    }

    return (
        <Row>
            <Col ref={divRef} className={styles[classVideoContainer]} style={{ margin: alignment }}>
                {renderVideo()}    
            </Col>
        </Row>
    );
}

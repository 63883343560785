import { departmentsManagementService, departmentsPracticeService } from './departmentsService';
import { firestore } from './persistence';

function mapCollections(snapshot: any) {
    return snapshot.docs.map((doc: any) => (
        {
            id: doc.id,
            data: doc.data()
        })
    )
}

export const UserFiltersService = {
    getFilters: async function () {
        const getCities = firestore.collection("cities").get().then((snapshot) => {
            return mapCollections(snapshot)
        })
        const getJobTitles = firestore.collection("job_titles").get().then((snapshot) => {
            return mapCollections(snapshot)
        })


        let filters;

        try {

            const [cities, jobTitles, departmentsManagement, departmentsPractice] = await Promise.all([getCities, getJobTitles, departmentsManagementService.getAll(), departmentsPracticeService.getAll()]);

            const departments: any = departmentsManagement.concat(departmentsPractice);

            filters = {
                cities,
                jobTitles,
                departments
            }
        } catch (error) {
            filters = {};
            console.log("error", error)
        }

        return filters;
    },
}

export default UserFiltersService;
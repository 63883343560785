import React, { useState } from 'react'
import { Radio, Space, Dropdown, Badge, Checkbox, Divider } from 'antd';
import B2 from '../../../base/text/b2';
import styles from './index.module.css';
import ControlIcon from '../../../base/icon/control';
import i18n from '../../../../language/i18n';
import B1 from '../../../base/text/b1';
import ChevronDown from '../../../base/icon/chevronDown';
import { Tag } from '../../../../model/tag/tag';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { GetLabel } from '../../../../helpers/getLabel';

interface FilterDesktopProps {
    checkedList: Tag[],
    optionsList: Tag[],
    indeterminate: boolean,
    allChecked: boolean,
    loading: boolean
    onSortSelect: (sort: Array<{ field: string, order: string } | undefined>) => void,
    // list must be 'any' to avoid conflicts with AntD
    handleFilterChange: (list: any) => void,
    handleClearAll: () => void,
    handleCheckAll: (event: CheckboxChangeEvent) => void,
}

function FilterDesktop({
    onSortSelect,
    optionsList,
    checkedList,
    handleFilterChange,
    indeterminate,
    allChecked,
    handleClearAll,
    handleCheckAll,
    loading
}: FilterDesktopProps) {
    const [order, setOrder] = React.useState<number | undefined>(undefined);
    const [visibleOrder, setVisibleOrder] = useState<boolean>(false);
    const [orderSelected, setOrderSelected] = useState<boolean>(false);
    const [categoriesVisible, setCategoriesVisible] = useState<boolean>(false);
    const getLabel = new GetLabel();

    const onChangeRadio = (e: any) => {
        setOrder(e.target.value);
        setOrderSelected(true);

        let sort = undefined;
        switch (e.target.value) {
            case 1: sort = { field: "title", order: "asc" }; break;
            case 2: sort = { field: "title", order: "desc" }; break;
            case 3: sort = { field: "updatedDate", order: "desc" }; break;
            case 4: sort = { field: "updatedDate", order: "asc" }; break;
        }

        onSortSelect([sort]);
    };

    const onVisibleChange = (flag: boolean) => {
        setVisibleOrder(flag);
    }

    const handleVisibleCategoriesChange = (flag: boolean) => {
        setCategoriesVisible(flag);
    }

    const menuOrder = (
        <Space direction="vertical" className={styles.menuOrderContainer}>
            <B2>{i18n.t("plus.section.filters.sortTitle")}</B2>
            <Radio.Group onChange={onChangeRadio} value={order} className={styles.radioGroup}>
                <Space direction="vertical">
                    <Radio value={1}>{i18n.t("plus.section.filters.sortAsc")}</Radio>
                    <Radio value={2}>{i18n.t("plus.section.filters.sortDesc")}</Radio>
                    <Radio value={3}>{i18n.t("broadcast.section.filters.dateDesc")}</Radio>
                    <Radio value={4}>{i18n.t("broadcast.section.filters.dateAsc")}</Radio>
                </Space>
            </Radio.Group>
        </Space>
    )

    const menuFilter = (
        <Space direction="vertical" className={styles.menuFilterContainer}>
            <Checkbox
                indeterminate={indeterminate}
                onChange={handleCheckAll}
                checked={allChecked}
                className={styles.selectAll}
                disabled={loading}
            >
                {i18n.t("plus.section.filters.all")}
            </Checkbox>
            <Checkbox.Group
                value={checkedList as any}
                onChange={handleFilterChange}
                className={styles.selectGroup}
                disabled={loading}
            >
                {optionsList.map((item: Tag) => {
                    return <Checkbox className={styles.checkbox} value={item} key={item.id}>{item.name}</Checkbox>
                })}
            </Checkbox.Group>
            <span
                className={styles.clearButton}
                onClick={loading ? undefined : handleClearAll}

            >
                {i18n.t("plus.section.filters.clear")}
            </span>
        </Space>
    );

    return (
        <div className={styles.filterContainer}>
            <Dropdown
                overlay={menuOrder}
                trigger={['click']}
                onVisibleChange={onVisibleChange}
                visible={visibleOrder}
                placement="bottomRight"
                overlayClassName={styles.filtersDropdown}
            >
                <div className={styles.control}>
                    <Badge dot color={!orderSelected || (order === 3) ? "white" : "#000"}>
                        <ControlIcon className={[styles.pointer].join(" ")} />
                    </Badge>
                </div>
            </Dropdown>
            <Dropdown
                overlay={menuFilter}
                trigger={['click']}
                onVisibleChange={handleVisibleCategoriesChange}
                visible={categoriesVisible}
            >
                <div className={styles.dropdown}>
                    <Divider type="vertical" className={styles.divider} />
                    <span className={[styles.pointer, styles.filterLabel].join(" ")} >
                        <B1>
                            {getLabel.getDropdownCheckboxLabel(checkedList, optionsList)} <ChevronDown className={styles.chevron} />
                        </B1>
                    </span>
                </div>
            </Dropdown>
        </div>
    )
}

export default FilterDesktop;
import React, { useContext, useEffect, useState } from 'react';
import AuthenticationContext from '../../components/auth/authenticationContext';
import PageContainer from '../../components/base/pageContainer';
import EventModal from '../../components/sections/agenda/eventModal';
import HighlightedEvents from '../../components/sections/agenda/highlightedEvents';

import { Event } from '../../model/event/event';
import Intro from '../../components/sections/agenda/intro';
import UpcomingEvents from '../../components/sections/agenda/upcomingEvents';
import AllEvents from '../../components/sections/agenda/allEvents';
import { RadioChangeEvent } from 'antd';
import { groupByDay } from '../../helpers/agenda/groupByDay';
import { Dictionary } from 'underscore';
import EventService from '../../services/eventService';
import TagService from '../../services/tagService';
import { Tag } from '../../model/tag/tag';
import moment from 'moment';
import { useWindowSize } from '../../hooks/useWindowSize';
import SearchBarFilters from '../../components/sections/agenda/searchBarFilters';
import { HighlightedEvent } from '../../model/highlightedEvent/highlightedEvent';

import { filterEvents } from '../../helpers/agenda/filterEvents';
import AnalyticsService from '../../services/analytics';
import AgendaService from '../../services/agendaService';

function Agenda() {
    const { isPhone } = useWindowSize();
    const [activeFilter, setActiveFilter] = useState<Tag | null>(null);
    const [eventTags, setEventTags] = useState<Tag[]>([]);
    const [upcomingEvents, setUpcomingEvents] = useState<null | Event[]>(null)
    const [highlightedEvents, setHighlightedEvents] = useState<null | HighlightedEvent[]>(null)
    const [eventsByDay, setEventsByDay] = useState<null | Dictionary<Event[]>>(null);
    const [modalData, setModalData] = useState<Event | HighlightedEvent | null>(null);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [subscribeSuccess, setSubscribeSuccess] = useState<boolean>(false);
    const [totalQueried, setTotalQueried] = useState<number>(0);
    const [size, setSize] = useState<number>(isPhone ? 8 : 20,);
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [allEventsLoading, setAllEventsLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>(moment().startOf("month").toISOString());
    const [endDate, setEndDate] = useState<string>(moment(startDate).endOf("month").add("7", "day").toISOString());
    const [startDateCalendar] = useState<string>(moment().startOf("day").toISOString());
    const [title, setTitle] = useState<string|undefined>(undefined)
    const [subTitle, setSubTitle] = useState<string|undefined>(undefined)
    const [backgroundImage, setBackgroundImage] = useState<string|undefined>(undefined)
    const context = useContext(AuthenticationContext);
    const userId = context?.claims?.user_id;

    useEffect(() => {
        setLoading(true);

        getPageData();

        const getTags = TagService.getAll({ types: ['event'], sort: [{ field: "name", order: "asc" }] })

        //For upcoming events
        const getUpcomingEvents = EventService.getAll({
            size: 12,
            sort: [{
                field: 'startDate',
                order: 'asc'
            }],
            range: [{
                field: 'startDate',
                range: "gte",
                value: moment().toISOString()
            }]
        })

        //For highlightedEvents
        const getHighlightedEvents = EventService.getHighlightedEvents()

        Promise.all([getTags, getUpcomingEvents, getHighlightedEvents]).then((values) => {
            const [tagsRes, eventsRes, highlightedEventRes] = values;
            const tags = tagsRes.data.response;
            const events = eventsRes.data.response
            const highlights = highlightedEventRes.data.response

            setEventTags(tags)
            setUpcomingEvents(events);
            setHighlightedEvents(highlights);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.error(err)
        })

    }, []);

    useEffect(() => {
        //For all events
        const getByStartDate = EventService.getAll({
            size: size,
            sort: [
                {
                    field: 'startDate',
                    order: 'asc',
                }],
            tags: activeFilter ? [activeFilter.id] : [],
            range: [

                {
                    field: 'endDate',
                    range: 'gte',
                    value: moment(startDateCalendar).startOf("day").toISOString(),
                }
            ]
        })



        Promise.all([getByStartDate])
            .then((res) => {
                const [startDateRes] = res;
                const eventsByStartDate = startDateRes.data.response;

                const total = startDateRes.data.total;
                const groupedByDay = groupByDay(eventsByStartDate);

                setTotalQueried(total);
                setEventsByDay(groupedByDay);
                setAllEventsLoading(false);
            }).catch((err) => {
                console.log(err);
            });

    }, [activeFilter, size, isPhone, startDateCalendar]);

    async function getPageData(){
        try {
            const data = await AgendaService.getAgenda()
            setTitle(data?.title)
            setSubTitle(data?.subtitle)
            setBackgroundImage(data?.background)
        } catch (error) {
            console.log("error", error)
        }
    }

    async function refreshHighlightedEvents() {
        const HighlightedEvents = await EventService.getHighlightedEvents();
        setHighlightedEvents(HighlightedEvents.data.response)
        return HighlightedEvents.data.response
    }

    async function refreshGroupByDayEvents() {

        const getByStartDate = EventService.getAll({
            size: size,
            sort: [
                {
                    field: 'startDate',
                    order: 'asc',
                }],
            tags: activeFilter ? [activeFilter.id] : [],
            range: [
                {
                    field: 'startDate',
                    range: "gte",
                    value: startDate,
                },
                {
                    field: 'startDate',
                    range: 'lte',
                    value: endDate,
                },
                {
                    field: 'endDate',
                    range: 'gte',
                    value: startDate,
                }
            ]
        })

        const getByEndDate = EventService.getAll({
            size: size,
            sort: [
                {
                    field: 'startDate',
                    order: 'asc',
                }],
            tags: activeFilter ? [activeFilter.id] : [],
            range: [
                {
                    field: 'endDate',
                    range: "gte",
                    //this ensures that it gets the 7 days prior to the start of the month
                    //because the startDate can be the last few days of the previous month

                    value: moment(startDate).add("10", "day").startOf("month").subtract("7", "day").toISOString(),
                },
                {
                    field: 'endDate',
                    range: 'lte',
                    value: moment(startDate).add("10", "day").startOf("month").toISOString(),
                }
            ]
        });

        Promise.all([getByStartDate, getByEndDate])
            .then((res) => {
                const [startDateRes, endDateRes] = res;

                const eventsByStartDate = startDateRes.data.response;
                const eventsByEndDate = endDateRes.data.response;
                const filteredEvents = filterEvents(eventsByStartDate, eventsByEndDate);
                const total = filteredEvents.length;
                const groupedByDay = groupByDay(filteredEvents);

                setTotalQueried(total);
                setEventsByDay(groupedByDay);
                setAllEventsLoading(false);
            }).catch((err) => {
                console.log(err);
            });
    }

    async function refreshUpcomingEvents() {
        const events = await EventService.getAll({
            size: 12,
            sort: [
                {
                    field: 'startDate',
                    order: 'asc',
                }],
            range: [{
                field: 'startDate',
                range: "gte",
                value: moment().toISOString()
            }]
        })
        setUpcomingEvents(events.data.response)
        return events.data.response
    }

    async function refreshEvents() {
        await refreshUpcomingEvents();
        await refreshGroupByDayEvents();
        await refreshHighlightedEvents();
    }

    function handleEventClick(event: Event) {
        setModalIsOpen(true);
        AnalyticsService.event(AnalyticsService.agendaEvent, { id: event.id, title: event.title });
        setModalData(event);
    }

    function handleModalVisibleChange() {
        if (!modalIsOpen) setModalData(null);
    }

    function handleModalClose() {
        setModalIsOpen(false)
        if (subscribeSuccess) setSubscribeSuccess(false);
    }

    function isSubscribed(event: Event | null) {
        if (!userId) return null;
        const subscription = event?.attendees?.includes(userId);
        return subscription;
    }

    function handleFilterClick(e: RadioChangeEvent) {
        const { value } = e.target
        if (!value || !value.id) {
            setActiveFilter(null)
        } else {
            setActiveFilter(value)
        }
        setSize(10);
        setDropdownVisible(false);
    }

    function getColor(event: Event) {
        const tag = eventTags?.find((item) => item.name.toLowerCase() === (event.tags && event?.tags[0]?.name.toLowerCase()));
        return tag?.color || "";
    }

    function handleSeeMore() {
        setAllEventsLoading(true);
        setSize((current) => current + (isPhone ? 8 : 20));
    }

    function handleVisibleChange(flag: boolean) {
        setDropdownVisible(flag);
    }

    async function handleMonthChange(start: string, end: string) {
        // parameter 'start' is the calendar month view starting date
        // parameter 'end' is the calendar month view ending date
        setAllEventsLoading(true);
        setStartDate(moment(start).toISOString());
        setEndDate(moment(end).toISOString());
        EventService.getAll({
            size: size,
            sort: [
                {
                    field: 'startDate',
                    order: 'asc',
                }],
            tags: activeFilter ? [activeFilter.id] : [],
            range: [

                {
                    field: 'endDate',
                    range: 'gte',
                    value: moment(startDateCalendar).startOf("day").toISOString(),
                }
            ]
        }).then((res) => {
            const groupedByDay = groupByDay(res.data.response);
            setEventsByDay(groupedByDay);
            setAllEventsLoading(false);
        }).catch((err) => {
            console.log("err", err);
        });
    }

    function handleViewChange(view: "week" | "month") {
        setAllEventsLoading(true);
        if (view === "week") {
            EventService.getAll({
                size: size,
                sort: [
                    {
                        field: 'startDate',
                        order: 'asc',
                    }],
                tags: activeFilter ? [activeFilter.id] : [],
                range: [
                    {
                        field: 'startDate',
                        range: "gte",
                        value: moment().startOf("week").subtract("1", "day").toISOString(),
                    },
                    {
                        field: 'startDate',
                        range: 'lte',
                        value: moment().endOf("week").subtract("1", "day").toISOString(),
                    }
                ]
            }).then((res) => {
                const groupedByDay = groupByDay(res.data.response);
                setEventsByDay(groupedByDay);
                setAllEventsLoading(false);
            }).catch((err) => {
                console.log("err", err);
            });
        }
    }


    return (
        <PageContainer>
            <Intro title={title} subTitle={subTitle} background={backgroundImage} loading={loading} />
            <SearchBarFilters eventTags={eventTags} />
            {highlightedEvents && highlightedEvents.length > 0 && 
                <HighlightedEvents
                    data={highlightedEvents}
                    onEventClick={handleEventClick}
                    isSubscribed={isSubscribed}
                    getColor={getColor}
                    loading={loading}
                    refreshEvents={refreshEvents}
                />
            }
            <UpcomingEvents
                data={upcomingEvents}
                onEventClick={handleEventClick}
                isSubscribed={isSubscribed}
                getColor={getColor}
                loading={loading}
            />
            <EventModal
                modalData={modalData}
                onClose={handleModalClose}
                afterVisibleChange={handleModalVisibleChange}
                isVisible={modalIsOpen}
                isSubscribed={isSubscribed}
                getColor={getColor}
                refreshEvents={refreshEvents}
                subscribeSuccess={subscribeSuccess}
                setSubscribeSuccess={setSubscribeSuccess}
            />
            <AllEvents
                isSubscribed={isSubscribed}
                onEventClick={handleEventClick}
                handleFilterClick={handleFilterClick}
                activeFilter={activeFilter}
                data={eventsByDay}
                filterOptions={eventTags}
                handleSeeMore={handleSeeMore}
                totalQueried={totalQueried}
                size={size}
                handleVisibleChange={handleVisibleChange}
                dropdownVisible={dropdownVisible}
                seeMoreLoading={allEventsLoading}
                loading={loading}
                onMonthChange={handleMonthChange}
                onViewChange={handleViewChange}
                eventsByDaySetter={setEventsByDay}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDateCalendar={startDateCalendar}
                setTotalQueried={setTotalQueried}
                startDate={startDate}
                endDate={endDate}
            />
        </PageContainer>
    );
}

export default Agenda;
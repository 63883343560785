import { firestore } from './persistence';

const AgendaService = {
    
    getAgenda: async function () {
        const querySnapshot = await firestore.collection("pages_custom").doc("agenda").get()
        return querySnapshot.data()
    },

}

export default AgendaService;
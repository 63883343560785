import { categoryInterface } from "../event/event"

export interface HighlightedEvent {
    "id": string,
    "eventId": string,
    "type": "event",
    "title": string,
    "subTitle": string,
    "tags": Array<categoryInterface>,
    "lead": string,
    "body": string,
    "images": Array<string>,
    "startDate": Date,
    "endDate"?: Date,
    "location": {
        "address": string,
        "name": string,
    },
    "phone": string,
    "usefulLinks": Array<string>,
    "email": string,
    "organizers": Array<
        {
            "name": string,
            "phone": string,
            "email": string,
            "photo": string,
            "id": string
        }
    >,
    "attendees": Array<string>,
    isPrivate: boolean;
    showOrganizers: boolean;
    relatedPosts: { id: string }[];
}

export function isHighlightedEvent(arg: any): arg is HighlightedEvent {
    return arg?.eventId !== undefined
}
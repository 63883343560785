import { Col, Dropdown, Input, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ReactComponent as Close } from "../../assets/images/svg/close_omni.svg";
import ChevronDown from '../../components/base/icon/chevronDown';
import SearchIcon from '../../components/base/icon/search';
import PageContainer from '../../components/base/pageContainer';
import Spinner from '../../components/base/spinner';
import Paragraph from "../../components/base/text/paragraph";
import Intro from '../../components/sections/omniResults/intro';
import ApplicationSection from '../../components/sections/omniResults/section/application';
import BroadcastSection from '../../components/sections/omniResults/section/broadcast';
import CategorySection from '../../components/sections/omniResults/section/category';
import EventSection from '../../components/sections/omniResults/section/event';
import NewsSection from '../../components/sections/omniResults/section/news';
import OthersSection from '../../components/sections/omniResults/section/other';
import PageSection from '../../components/sections/omniResults/section/page';
import PartnershipSection from '../../components/sections/omniResults/section/partnership';
import TeamSection from '../../components/sections/omniResults/section/team';
import UserSection from '../../components/sections/omniResults/section/user';
import { useQueryString } from '../../hooks/useQueryString';
import { useWindowSize } from '../../hooks/useWindowSize';
import i18n from '../../language/i18n';
import { OmniSearchResult } from '../../model/omni/OmniSearchResult';
import { OmniType } from '../../model/omni/OmniType';
import OmniService from '../../services/OmniService';
import styles from './index.module.css';

const defaultSize = 5
function OmniResults() {
    const [showSearchbarOptions, setShowSearchbarOptions] = useState<boolean>(false)
    const { isPhone } = useWindowSize()
    const { search } = useLocation()
    const [resultsSectionOrder] = useState<OmniType[]>([
        OmniType.APPLICATIONS, 
        OmniType.CATEGORIES,
        OmniType.EVENTS,
        OmniType.TEAMS,
        OmniType.NEWS,
        OmniType.PAGES,
        OmniType.PARTNERSHIPS,
        OmniType.USERS,
        OmniType.BROADCASTS,
        OmniType.OTHERS
    ])
    const [searchbarOptions] = useState<any[]>([
        { name: i18n.t("omnisearch.results.options.all"), id: OmniType.ALL },
        { name: i18n.t("omnisearch.results.options.applications"), id: OmniType.APPLICATIONS },
        { name: i18n.t("omnisearch.results.options.categories"), id: OmniType.CATEGORIES },
        { name: i18n.t("omnisearch.results.options.events"), id: OmniType.EVENTS },
        { name: i18n.t("omnisearch.results.options.teams"), id: OmniType.TEAMS },
        { name: i18n.t("omnisearch.results.options.news"), id: OmniType.NEWS },
        { name: i18n.t("omnisearch.results.options.pages"), id: OmniType.PAGES },
        { name: i18n.t("omnisearch.results.options.partnerships"), id: OmniType.PARTNERSHIPS },
        { name: i18n.t("omnisearch.results.options.users"), id: OmniType.USERS },
        { name: i18n.t("omnisearch.results.options.broadcasts"), id: OmniType.BROADCASTS },
    ])
    const [pageableElements, setPageableElements] = useState({
        "teams": { items: [], size: defaultSize },
        "pages": { items: [], size: defaultSize },
        "applications": { items: [], size: defaultSize },
        "others": { items: [], size: defaultSize }
    })
    const initialQueryString = new URLSearchParams(search);
    const [data, setData] = useState<OmniSearchResult>(new OmniSearchResult());
    const [type, setType] = useState<OmniType>(initialQueryString.get("tipo") as OmniType);
    const [query, setQuery] = useState<string>(initialQueryString.get("texto") || "");
    const [searchedQuery, setSearchedQuery] = useState<string>(query);
    const [loading, setLoading] = useState<boolean>(false);
    useQueryString(
        { name: "texto", query: query },
        { name: "tipo", query: type }
    );

    useEffect(() => {
        setLoading(true)

        if (query.length < 3) {
            setLoading(false)
            setSearchedQuery(query)
            return
        }

        OmniService.getAll({ type: type, size: defaultSize, query: query }).then(result => {
            setData(result.data)
            setLoading(false)
            setSearchedQuery(query)
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const res = Object.keys(data.response).reduce(
            (acc, curr) => {

                if (![OmniType.TEAMS, OmniType.PAGES, OmniType.OTHERS, OmniType.APPLICATIONS].some(type => type === curr)) {
                    return acc
                }

                acc[curr as OmniType].items = data.response[curr]!.response
                return acc
            },
            {
                "teams": { items: [], size: defaultSize },
                "pages": { items: [], size: defaultSize },
                "applications": { items: [], size: defaultSize },
                "others": { items: [], size: defaultSize }
            } as any
        )

        setPageableElements(res)
    }, [data])

    async function onClickSearch() {
        setLoading(true)

        if (query.length < 3) {
            setSearchedQuery(query)
            setLoading(false)
            return
        }

        const newElements = await OmniService.getAll({ type: type, size: defaultSize, query: query })

        setData(newElements.data)
        setSearchedQuery(query)
        setLoading(false)
    }

    const translateType = (type?: string) => {
        return i18n.t(`post.type.${type}`);
    }

    function onSearchbarChange(e: any) {
        const { value } = e.target;
        setQuery(value)
    }

    function onSearchbarClear() {
        setQuery("")
    }

    function onSearchbarOptionClick(props: any) {
        setShowSearchbarOptions(false)
        setType(props.key)
    }

    async function loadNextPage(type: OmniType.TEAMS | OmniType.PAGES | OmniType.OTHERS | OmniType.APPLICATIONS) {
        const newSize = pageableElements[type].size + defaultSize
        const newElements = await OmniService.getAll({ type: type, size: newSize, query: query })

        setPageableElements({
            ...pageableElements,
            [type]: {
                items: newElements.data.response[type].response,
                size: newSize
            }
        })
    }

    function renderSearchbarOptions() {
        return (
            <Menu
                className={styles.menu}
                onClick={onSearchbarOptionClick}
                defaultSelectedKeys={[OmniType.ALL]}
                selectedKeys={[type]}
            >
                {searchbarOptions.map(option => ((
                    <Menu.Item key={option.id}> {option.name} </Menu.Item>
                )))}
            </Menu>
        )
    }

    function renderSearchbar() {

        const displayClearButton = typeof query === 'string' && query.length > 0

        if (isPhone) {
            return (
                <Col>
                    <div className={styles.mobileSearchbarGrid}>
                        <div className={styles.searchBar}>
                            <Input
                                id="webSearchbar"
                                prefix={<SearchIcon />}
                                placeholder={i18n.t("omnisearch.placeholder")}
                                value={query}
                                onChange={onSearchbarChange}
                                onPressEnter={onClickSearch}
                            />
                        </div>
                        <Close className={[styles.close, displayClearButton && styles.displayCloseButton].join(' ')} onClick={onSearchbarClear} />
                    </div>
                    <Dropdown
                        overlay={() => renderSearchbarOptions()}
                        trigger={['click']}
                        visible={showSearchbarOptions}
                        onVisibleChange={(visible: boolean) => setShowSearchbarOptions(visible)}
                        placement="bottomCenter"
                        overlayClassName={styles.searchbarDropdown}
                    >
                        <Row justify="center" align="middle" className={styles.searchbarDropdownRow}>
                            <Paragraph className={styles.searchbarDropdownTitle}>
                                {searchbarOptions.find(opt => opt.id === type).name}
                            </Paragraph>
                            <ChevronDown />
                        </Row>
                    </Dropdown>
                </Col>
            )
        } else {
            const renderSearchButton = query !== "" && query.length >= 3
            return (
                <div className={[styles.searchbarGrid, type === OmniType.BROADCASTS && styles.searchbarGridMaxWidth].join(' ')}>
                    <Dropdown
                        overlay={() => renderSearchbarOptions()}
                        trigger={['click']}
                        visible={showSearchbarOptions}
                        onVisibleChange={(visible: boolean) => setShowSearchbarOptions(visible)}
                        placement="bottomCenter"
                        overlayClassName={styles.searchbarDropdown}
                    >
                        <Row justify="center" align="middle" className={styles.searchbarDropdownRow}>
                            <Paragraph className={styles.searchbarDropdownTitle}>
                                {searchbarOptions.find(opt => opt.id === type).name}
                            </Paragraph>
                            <ChevronDown />
                        </Row>
                    </Dropdown>
                    <div className={styles.searchBar}>
                        <Input
                            id="webSearchbar"
                            prefix={<SearchIcon />}
                            placeholder={i18n.t("omnisearch.placeholder")}
                            value={query}
                            onChange={onSearchbarChange}
                            onPressEnter={onClickSearch}
                        />
                    </div>
                    <Paragraph className={[styles.searchButton, renderSearchButton && styles.displaySearchButton].join(' ')} onClick={onClickSearch}> {i18n.t("omnisearch.results.text.search")} </Paragraph>
                    <Close className={[styles.close, displayClearButton && styles.displayCloseButton].join(' ')} onClick={onSearchbarClear} />
                </div>
            )
        }
    }

    function renderResultSection(type: string, index: number) {
        if (data.response[type] === undefined) {
            return null
        }

        const { response, total } = data.response[type]!;
        if (response.length === 0) return null;

        const backgroundColor = index % 2 === 0 ? "#FFFFFF" : "#F1F1F1"

        let sectionContent
        switch (type) {
            case OmniType.USERS:
                sectionContent = (
                    <UserSection
                        items={response}
                        total={total}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                    />
                );
                break;
            case OmniType.CATEGORIES:
                sectionContent = (
                    <CategorySection
                        items={response}
                        total={total}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                    />
                );
                break;
            case OmniType.NEWS:
                sectionContent = (
                    <NewsSection
                        items={response}
                        total={total}
                        translateType={translateType}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                    />
                );
                break;
            case OmniType.EVENTS:
                sectionContent = (
                    <EventSection
                        items={response}
                        total={total}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                    />
                );
                break;
            case OmniType.BROADCASTS:
                sectionContent = (
                    <BroadcastSection
                        items={response}
                        total={total}
                        translateType={translateType}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                    />
                );
                break;
            case OmniType.PAGES:
                sectionContent = (
                    <PageSection
                        items={pageableElements.pages.items}
                        total={total}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                        loadNextPage={() => loadNextPage(OmniType.PAGES)}
                    />
                );
                break;
            case OmniType.TEAMS:
                sectionContent = (
                    <TeamSection
                        items={pageableElements.teams.items}
                        total={total}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                    />
                );
                break;
            case OmniType.PARTNERSHIPS:
                sectionContent = (
                    <PartnershipSection
                        items={response}
                        total={total}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                    />
                );
                break;
            case OmniType.APPLICATIONS:
                sectionContent = (
                    <ApplicationSection
                        items={pageableElements.applications.items}
                        total={total}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                        loadNextPage={() => loadNextPage(OmniType.APPLICATIONS)}
                    />
                );
                break;
            case OmniType.OTHERS:
                sectionContent = (
                    <OthersSection
                        items={pageableElements.others.items}
                        total={total}
                        query={searchedQuery}
                        defaultSize={defaultSize}
                        translateType={translateType}
                        loadNextPage={() => loadNextPage(OmniType.OTHERS)}
                    />
                );
                break;
            default:
                sectionContent = null
        }

        return (
            <Col className={styles.resultsSectionContainer} style={{ backgroundColor: backgroundColor }}>
                {sectionContent}
            </Col>
        )
    }

    function renderNoResultsSection() {
        return (
            <Col className={styles.noResultsContainer}>
                <div >
                    <p>{i18n.t("omnisearch.results.error.noResults")}</p>
                </div>
            </Col>
        )
    }

    function renderResults() {
        if (searchedQuery.length < 3) {
            return (
                <div>
                    <Paragraph className={styles.shortQueryMessage}>
                        {i18n.t("omnisearch.results.error.notEnoughCharacters")}
                    </Paragraph>
                </div>
            )
        }

        const keys = type === OmniType.ALL ? resultsSectionOrder : [type];

        const categoriesWithResults = keys.filter((type) => {
            const response = data.response[type] && data.response[type]?.response;
            return response ? response.length > 0 : false;
        });

        const renderResults = categoriesWithResults.length ?
            categoriesWithResults.map(renderResultSection) :
            renderNoResultsSection()

        return (
            <div className={styles.resultsContainer}>
                {loading === true ?
                    <div className={styles.centerSpinner}> <Spinner /></div> :
                    renderResults
                }
            </div>
        )
    }

    return (
        <PageContainer>
            <Intro>
                {renderSearchbar()}
            </Intro>
            {renderResults()}
        </PageContainer>
    );
}

export default OmniResults;
import React from 'react';
import { ReactComponent as Notification } from '../../../../assets/images/svg/homepage/notification.svg';
import { ReactComponent as NSelected } from '../../../../assets/images/svg/homepage/notification_selected.svg';
import { IconProps } from '../interface';

function NotificationIcon({ onClick, className, selected }: IconProps) {
    if (selected === true) {
        return <NSelected onClick={onClick}  className={className} />
    }

    return (
        <Notification onClick={onClick} className={className} />
    )
}

export default NotificationIcon;
import { Button, Col, Row } from "antd";
import React, { useContext, useState } from "react";
import i18n from "../../../../../language/i18n";
import AuthenticationContext from "../../../../auth/authenticationContext";
import HighlightCard from "../../highlightCard";
import styles from './index.module.css';
import { Tag } from "../../../../../model/tag/tag";
import { Event } from "../../../../../model/event/event";
import EventModal from "../../eventModal";

interface EventListProps {
    eventTags: Tag[] | null,
    setSize: React.Dispatch<React.SetStateAction<number>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    totalEvents: number,
    events: Event[] | null,
    refreshEvents: () => Promise<Event[]>,
}

function EventList({ eventTags, setSize, loading, setLoading, totalEvents, events, refreshEvents }: EventListProps) {
    const context = useContext(AuthenticationContext);
    const userId = context?.claims?.user_id
    const [modalData, setModalData] = useState<Event | null>(null);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    function handleSeeMore() {
        setLoading(true)
        setSize(current => current + 4)
    }

    function handleModalClose() {
        setModalIsOpen(false)
    }

    function handleEventClick(event: Event) {
        setModalIsOpen(true);
        setModalData(event);
    }

    function handleModalVisibleChange() {
        if (!modalIsOpen) setModalData(null);
    }

    function isSubscribed(event: Event | null) {
        if (!userId) return null;
        const subscription = event?.attendees?.includes(userId);
        return subscription;
    }

    function getColor(event: Event) {
        const tag = eventTags?.find((item) => item.name.toLowerCase() === (event.tags && event?.tags[0]?.name.toLowerCase()));
        return tag?.color || "";
    }

    function renderCard(event: Event) {
        return (
            <HighlightCard
                refreshEvents={refreshEvents}
                onEventClick={handleEventClick}
                event={event}
                isSubscribed={isSubscribed(event)}
                isPrivate={event.isPrivate}
            />
        )
    }


    function cardList() {
        return (events && events.map((event: Event, index: number) => {
            return (
                <Col xs={24} sm={11} md={7} lg={5} className={[styles.cardContainer, loading ? styles.containerLoading : ""].join(" ")}>
                    {renderCard(event)}
                </Col>
            )
        }))
    }

    function renderButton() {
        return (
            events && events.length < totalEvents &&
            <Col className={styles.seeMore} span={24}>
                <Button onClick={handleSeeMore}>{i18n.t("agenda.section.highlighted.seeMore")}</Button>
            </Col>
        )
    }

    async function refreshEvent() {
        const events = await refreshEvents()
        const newEvent = events.find((event) => event.id === modalData?.id)
        setModalData(newEvent ? newEvent : null)
    }


    return (
        <Row className={styles.renderRow} gutter={[0, 24]}>
            <>
                {cardList()}
                {renderButton()}
            </>
            <EventModal
                refreshEvents={refreshEvent}
                modalData={modalData}
                onClose={handleModalClose}
                afterVisibleChange={handleModalVisibleChange}
                isVisible={modalIsOpen}
                isSubscribed={isSubscribed}
                getColor={getColor}
            />
        </Row>
    )
}

export default EventList;
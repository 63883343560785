import { Col, Row } from 'antd';
import React from 'react'
import TickIcon from '../../../base/icon/tick';
import B2 from '../../../base/text/b2';
import H5 from '../../../base/text/h5';
import Overline from '../../../base/text/overline';
import { Event } from '../../../../model/event/event';

import styles from './index.module.css';
import { formatDate } from '../../../../helpers/agenda/formatEventDate';

export interface HighlightCardProps {
    event: Event;
    isSubscribed: boolean | undefined | null;
    isPrivate: boolean;
    onEventClick?: (event: Event) => void;
    getColor: (event: Event) => string;
}

function UpcomingCard({ event, isSubscribed, onEventClick, getColor }: HighlightCardProps) {
    function renderTag() {
        return event.tags && event.tags.length ? event.tags[0].name : ""
    }

    const canRenderImage = event.images && event.images.length > 0
    return (
        <Row className={styles.card} onClick={() => onEventClick && onEventClick(event)}>
            <Col className={styles.imageContainer}>
                {
                    canRenderImage &&
                    <img src={event.images[0]} alt={event.title} />
                }
                {
                    isSubscribed &&
                    <div className={styles.tick}>
                        <TickIcon />
                    </div>
                }
            </Col>
            <Col className={styles.infoContainer} >
                <Row>
                    <Col className={styles.category}>
                        <div style={{ backgroundColor: getColor(event) }}></div>
                        <Overline>{renderTag()}</Overline>
                    </Col>
                </Row>
                <H5 className={styles.title}>
                    {event.title}
                </H5>
                <B2 className={styles.date}>
                    {formatDate(event)}
                </B2>
            </Col>
        </Row>
    )
}

export default UpcomingCard;
import React from 'react'
import { Col, Row } from 'antd';
import styles from './index.module.css'

export type PageContainerProps = {
    className?: string;
    children?: React.ReactNode
}

const PageContainer: React.FC<PageContainerProps> = (props: PageContainerProps) => {
    return (
        <Row className={[styles.pageContainer, props.className].join(" ")}>
            <Col>
                {props.children}
            </Col>
        </Row>
    )
}

export default PageContainer;
import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/base/pageContainer';
import Intro from '../../components/sections/broadcast/intro';
import List from '../../components/sections/broadcast/list';
import FilterIcon from '../../components/base/icon/filter';
import { useBroadcast } from '../../hooks/useBroadcast';
import styles from './index.module.css';
import { useInitialState } from '../../hooks/useInitialState';
import FiltersMobile from '../../components/sections/broadcast/filterMobile';
import { useLocation } from 'react-router';
import { useQueryString } from '../../hooks/useQueryString';
import TagService from '../../services/tagService';
import { Tag } from '../../model/tag/tag';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import BroadcastService from '../../services/broadcastService';

function Broadcast() {
    const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false);
    const [orderValue, setOrderValue] = useState<number>(3);
    const { search } = useLocation();
    const initialState = useInitialState({ orderValue });
    const initialQueryString = new URLSearchParams(search);
    const initialTags = initialQueryString.get('categorias') ? initialQueryString.get('categorias')!.split(',') : undefined
    const broadcastData = useBroadcast(initialQueryString.get('texto'), initialTags);
    const [checkedList, setCheckedList] = useState<Tag[]>([]);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const [options, setOptions] = useState<Tag[]>([]);
    const [title, setTitle] = useState<string | undefined>(undefined)
    const [subtitle, setSubtitle] = useState<string | undefined>(undefined)
    const [backgroundImage, setBackgroundImage] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        setLoading(true);
        getPageData();
        TagService.getAll({ types: ['podcast', 'video'], size: 25, sort: [{ field: "name", order: "asc" }] })
            .then((res: any) => {
                const { response } = res.data
                const mappedChekedList = response.filter((tag: Tag) => initialTags && initialTags.some(id => id === tag.id))

                setOptions(response)
                setCheckedList(mappedChekedList)
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    useQueryString(
        { name: "texto", query: broadcastData.query },
        { name: "categorias", query: broadcastData.tags ? broadcastData.tags.join(',') : undefined }
    );

    async function getPageData() {
        try {
            const data = await BroadcastService.getBroadcast()
            setTitle(data?.title)
            setSubtitle(data?.subtitle)
            setBackgroundImage(data?.background)
        } catch (error) {
            console.log("error", error)
        }
    }

    function showActiveFiltersCircle(): boolean {
        return orderValue !== 3;
    }

    function openFilters() {
        if (!broadcastData.loading) {
            setMobileFilterOpen(true);
        }
    }

    function handleFilterChange(list: Tag[]) {
        setCheckedList(list)
        const mappedList = list.map(item => item.id);
        broadcastData.setTags(mappedList)
        list.length === options.length ? setAllChecked(true) : setAllChecked(false);
        list.length && list.length !== options.length ?
            setIndeterminate(true) :
            setIndeterminate(false)
    }

    function handleClearAll() {
        setCheckedList([]);
        setIndeterminate(false);
        setAllChecked(false)
        broadcastData.setTags(undefined)
    }

    function handleCheckAll(event: CheckboxChangeEvent) {
        setAllChecked(event.target.checked)
        setIndeterminate(false);
        if (event.target.checked) {
            setCheckedList(options);
            broadcastData.setTags(options.map(item => item.id))
        } else {
            setCheckedList([]);
            broadcastData.setTags(undefined)
        }
    }

    return (
        <PageContainer>
            <Intro title={title} subtitle={subtitle} background={backgroundImage} loading={loading}/>
            <List
                brodcastData={broadcastData}
                checkedList={checkedList}
                optionsList={options}
                handleFilterChange={handleFilterChange}
                indeterminate={indeterminate}
                allChecked={allChecked}
                handleClearAll={handleClearAll}
                handleCheckAll={handleCheckAll}
            />
            <div className={styles.filterIconMobile} onClick={openFilters} style={broadcastData.loading ? { backgroundColor: '#525252' } : {}}>
                {showActiveFiltersCircle() &&
                    <div className={styles.activeFilters} />}
                <FilterIcon />
            </div>
            <FiltersMobile
                isOpen={mobileFilterOpen}
                setIsOpen={setMobileFilterOpen}
                stateOnMount={initialState}
                data={broadcastData}
                orderValue={orderValue}
                setOrderValue={setOrderValue}
            />
        </PageContainer>
    );
}

export default Broadcast;
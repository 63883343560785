import { Row } from "antd";
import React from "react";
import H2 from "../../../../base/text/h2";
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.css';

export interface SubtitleProps {
    data?: Array<any>;
}

export default function Subtitle(props: SubtitleProps) {
    if (props.data === undefined || props.data.length === 0) {
        return null;
    }

    const data = props.data[0];

    return (
        data.body && (
            <Row className={styles.wrapper}>
                <H2>{ReactHtmlParser(data.body || "")}</H2>
            </Row>
        )
    );
}

import React, { Fragment, useContext, useEffect, useState } from 'react';
import AuthenticationContext from '../../components/auth/authenticationContext';
import Paragraph from '../../components/base/text/paragraph';
import i18n from '../../language/i18n';
import PageTitle from '../../components/base/pageTitle';
import Kiosk from '../../components/sections/standalone/kiosk';
import PageContainer from '../../components/base/pageContainer';
import PlmjApps from '../../components/sections/standalone/plmjApps';
import styles from './index.module.css';
import SpotlightSection from '../../components/sections/homepage/spotlight';
import Welcome from '../../components/sections/homepage/welcome';
import rightCutout from '../../assets/images/svg/homepage/containerBottomRight.svg'
import bannerImage from '../../assets/images/jpeg/homepage/back.jpg';
import Knowledge from '../../components/sections/standalone/knowledge';
import LatestNews from '../../components/sections/homepage/latestNews';
import { Col, Row, Skeleton } from 'antd';
import HomepageService from '../../services/homepageService';
import { Moment } from 'moment';
import { KioskItem } from '../../model/kioskItem/KioskItem';
import { App } from '../../model/app/App';
import PostsService from '../../services/postsService';
import { Post } from '../../model/post/Post';
const moment = require('moment');


type HomepageConfig = {
    menuLink?: string,
    startDate?: Moment,
    endDate?: Moment,
    songName?: string,
    songArtist?: string,
    songSuggestedBy?: string,
    songLink?: string,
    background?: string,
    autoRotate?: boolean
}

type HomepageProps = {
    isPageLoaded: boolean,
    config?: HomepageConfig,
    apps?: Array<App>,
    kioskItems?: Array<KioskItem>,
    knowledgeContent: Array<object>,
}

export default function HomePage(props: HomepageProps) {
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const [config, setConfig] = useState<HomepageConfig | undefined>(undefined);
    const [apps, setApps] = useState<Array<App> | undefined>(undefined);
    const [knowledgeContent, setKnowledgeContent] = useState<Array<object>>([]);
    const [kioskItems, setKioskItems] = useState<Array<KioskItem> | undefined>(undefined);
    const [spotlightPosts, setSpotlightPosts] = useState<Array<Post>>([])
    const context = useContext(AuthenticationContext);

    useEffect(() => {
        let mainSection = document.getElementById("mainSection");
        if (mainSection) {
            mainSection.style.marginBottom = "0px";
        }
        getConfig()
        getSpotlightPosts()
        setIsPageLoaded(true);
    }, []);

    const getSpotlightPosts = async () => {
        const posts = await PostsService.getAll({ types: ["not_defined", "news", "blog", "note"], sort: [{ field: "creationDate", order: "desc" }] })

        setSpotlightPosts(posts.data.response);
    }

    const getConfig = async () => {
        try {
            const homepageConfig = await HomepageService.getHomepage();

            const welcomeConfig = {
                menuLink: homepageConfig?.menu,
                startDate: moment(homepageConfig?.startDate.seconds * 1000),
                endDate: moment(homepageConfig?.endDate.seconds * 1000),
                songName: homepageConfig?.songName,
                songArtist: homepageConfig?.songArtist,
                songSuggestedBy: homepageConfig?.songSuggestedBy,
                songLink: homepageConfig?.songLink,
                background: homepageConfig?.background,
                autoRotate: homepageConfig?.autoRotate
            }
            const knowledgeContent = homepageConfig?.knowledgeSection === undefined ? [] : homepageConfig.knowledgeSection;

            setConfig(welcomeConfig);
            setApps(homepageConfig?.homepageApps);
            setKnowledgeContent(knowledgeContent);
            setKioskItems(homepageConfig?.kioskItems);

        } catch (e) {
            console.log("Error on loading homepage config", e);
        }
    }

    const renderContent = () => {
        const user = context?.auth;

        if (user) {
            return <PageTitle></PageTitle>
        }

        return (
            <Fragment>
                <Paragraph> {i18n.t('homePage.initMessage')} </Paragraph>
                <br />
                <a href="login"> {i18n.t('homePage.goToLogin')} </a>
            </Fragment>
        );
    }

    const loading = context !== null && context.loading;

    if (loading === true || isPageLoaded === false || config === undefined) {
        return <div style={{ marginTop: 80, marginBottom: 80 }}><Skeleton title paragraph /></div>
    }

    const backgroundImage = config.background !== undefined ? config.background : bannerImage;

    return (
        <Fragment>
            <div className={styles.overlay} id="overlayLoad" style={{ opacity: isPageLoaded ? 0 : 1 }} />
            {isPageLoaded ?
                <>
                    <PageContainer className={styles.container}>
                        <div className={styles.banner}>
                            <Row className={styles.bannerInner} style={{ backgroundImage: 'url("' + backgroundImage + '")', transform: isPageLoaded ? "scale(1)" : "scale(1.3)" }}>
                                <Col sm={24}>
                                    <img src={rightCutout} alt="backimage" />
                                </Col>
                            </Row>
                        </div>
                        {renderContent()}
                        <Welcome user={context?.auth!.displayName!} config={config} />                        
                        <SpotlightSection data={spotlightPosts} autoRotate={config.autoRotate} />
                    </PageContainer>
                    <PageContainer>
                        <LatestNews />
                    </PageContainer>
                    {knowledgeContent.length > 0 ?
                        (<PageContainer className={styles.knowledgeSection} >
                            <Knowledge content={knowledgeContent} />
                        </PageContainer>)
                        : null
                    }
                    <PageContainer>
                        <Kiosk kioskItems={kioskItems} />
                    </PageContainer>
                    <PageContainer className={styles["home-apps-section"]}>
                        <PlmjApps apps={apps} />
                    </PageContainer>
                </>
                : <Skeleton title paragraph />
            }
        </Fragment>
    );
}
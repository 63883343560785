import { Row } from "antd";
import React from 'react'
import { Tag } from "../../../../model/tag/tag";
import Overline from "../../../base/text/overline";
import styles from './index.module.css';

interface LegendProps {
    eventTags: Tag[]
}

export default function Legend({ eventTags }: LegendProps) {

    function renderTag(tag: Tag) {
        return (
            <div className={styles.category}>
                <div style={{ backgroundColor: tag.color }}></div>
                <Overline>{tag.name}</Overline>
            </div>
        )
    }

    return (
        <Row justify="end" >
            {
                eventTags.map((tag) => renderTag(tag))
            }
        </Row>
    )
}
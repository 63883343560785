import { Row } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import PageContainer from "../../components/base/pageContainer";
import SectionTitle from "../../components/base/sectionTitle";
import Spinner from "../../components/base/spinner";
import BackgroundBanner from '../../components/sections/teams/backgroundBanner'
import DepartmentsList from "../../components/sections/teams/departmentsList";
import Intro from "../../components/sections/teams/intro";
import TeamsDescription from "../../components/sections/teams/teamsDescription";
import TwoTabs from "../../components/sections/teams/twoTabs";
import TwoTextColumns from "../../components/sections/teams/twoTextColumns";
import UserList from "../../components/sections/teams/userList";
import { useQueryString } from "../../hooks/useQueryString";
import { useUsers } from "../../hooks/useUsers";
import i18n from "../../language/i18n";
import { Department } from "../../model/department/Department";
import { departmentsManagementService, departmentsPracticeService, departmentsGovernanceService } from "../../services/departmentsService";
import TeamsService from "../../services/teamsService";
import styles from './index.module.css';

const defaultTeamSize = 100;

type TeamTab = "practice" | "management" | "governance" | undefined

export default function Teams() {
    const { search } = useLocation()
    const initialQueryString = new URLSearchParams(search);
    const [departmentsLoading, setDepartmentsLoading] = useState<boolean>(true);
    const [managementDepartmentsData, setManagementDepartmentsData] = useState<Department[]>([]);
    const [practiceDepartmentsData, setPracticeDepartmentsData] = useState<Department[]>([]);
    const [governanceDepartmentsData, setGovernanceDepartmentsData] = useState<Department[]>([]);
    const [selectedDepartmentDetailsTab, setSelectedDepartmentDetailsTab] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState<TeamTab>(undefined);
    const [title, setTitle] = useState<string|undefined>(undefined)
    const [subTitle, setSubTitle] = useState<string|undefined>(undefined)
    const [description, setDescription] = useState<string|undefined>(undefined)
    const [details, setDetails] = useState<string|undefined>(undefined)
    const [backgroundImage, setBackgroundImage] = useState<string|undefined>(undefined)
    const [tabLeft, setTabLeft] = useState<string|undefined>(undefined)
    const [tabCenter, setTabCenter] = useState<string|undefined>(undefined)
    const [tabRight, setTabRight] = useState<string|undefined>(undefined)
    const [descriptionLeft, setDescriptionLeft] = useState<string|undefined>(undefined)
    const [descriptionCenter, setDescriptionCenter] = useState<string|undefined>(undefined)
    const [descriptionRight, setDescriptionRight] = useState<string|undefined>(undefined)
    const { loading, data, setDepartment, department } = useUsers(null, defaultTeamSize, initialQueryString.get('departamento'));
    useQueryString(
        { name: "departamento", query: department }
    );

    useEffect(() => {
        Promise.all([departmentsManagementService.getAll(), departmentsPracticeService.getAll(), departmentsGovernanceService.getAll()]).then(values => {
            const [managementDeps, practiceDeps, governanceDeps] = values;

            const sortFunction = function (a: { data: { name: string; }; }, b: { data: { name: string; }; }) {
                var nameA = a.data.name.toLowerCase(), nameB = b.data.name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0; //default return value (no sorting)
            }
            managementDeps.sort(sortFunction);
            practiceDeps.sort(sortFunction);
            governanceDeps.sort(sortFunction);

            setManagementDepartmentsData(managementDeps);
            setPracticeDepartmentsData(practiceDeps);
            setGovernanceDepartmentsData(governanceDeps);

            setDepartmentsLoading(false);
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const selectedDepartment = new URLSearchParams(search).get('departamento');

        if (practiceDepartmentsData.length === 0 || managementDepartmentsData.length === 0 || governanceDepartmentsData.length === 0) {
            return
        }

        if (selectedDepartment === null) {
            setSelectedTab("practice");
            setDepartment(decodeURIComponent(practiceDepartmentsData[0].data.name));
            setDescription(practiceDepartmentsData[0].data.description);
            setDetails(practiceDepartmentsData[0].data.details);
            
            return;
        }

        const isManagement = managementDepartmentsData.some(dep => dep.data.name === decodeURIComponent(selectedDepartment)) === true
        const isPractice = practiceDepartmentsData.some(dep => dep.data.name === decodeURIComponent(selectedDepartment)) === true
        const isGovernance = governanceDepartmentsData.some(dep => dep.data.name === decodeURIComponent(selectedDepartment)) === true

        const getSelectedTab = () => {
            if (isGovernance) {
                return "governance";
            }

            if (isManagement) {
                return "management";
            }

            return "practice";
        }

        if (selectedTab === undefined) {
            setSelectedTab(getSelectedTab());
        }

        const setDepartmentValues = (currentTabSelected: boolean, selectedDepartment: string, selectedTab: string) => {
            if (currentTabSelected) {
                setDepartment(decodeURIComponent(selectedDepartment));

                switch (selectedTab) {
                    case "practice": {
                        const dep = practiceDepartmentsData.find((department: Department) => department.data.name === selectedDepartment);
                        setDescription(dep?.data.description);
                        setDetails(dep?.data.details);
                        break;
                    }
                    case "management": {
                        const dep = managementDepartmentsData.find((department: Department) => department.data.name === selectedDepartment);
                        setDescription(dep?.data.description);
                        setDetails(dep?.data.details);
                        break;
                    }
                    case "governance": {
                        const dep = governanceDepartmentsData.find((department: Department) => department.data.name === selectedDepartment);
                        setDescription(dep?.data.description);
                        setDetails(dep?.data.details);
                        break;
                    }
                }

                return;
            } 

            switch (selectedTab) {
                case "practice": {
                    setDepartment(practiceDepartmentsData[0].data.name)
                    setDescription(practiceDepartmentsData[0].data.description)
                    setDetails(practiceDepartmentsData[0].data.details)
                    break;
                }
                case "management": {
                    setDepartment(managementDepartmentsData[0].data.name)
                    setDescription(managementDepartmentsData[0].data.description)
                    setDetails(managementDepartmentsData[0].data.details)
                    break;
                }
                case "governance": {
                    setDepartment(governanceDepartmentsData[0].data.name)
                    setDescription(governanceDepartmentsData[0].data.description)
                    setDetails(governanceDepartmentsData[0].data.details)
                    break;
                }
            }
        }

        switch (selectedTab) {
            case "practice": {
                setDepartmentValues(isPractice, selectedDepartment, selectedTab);
                break;
            }
            case "management": {
                setDepartmentValues(isManagement, selectedDepartment, selectedTab);
                break;
            }
            case "governance": {
                setDepartmentValues(isGovernance, selectedDepartment, selectedTab);
                break;
            }
        }
        getPageData()
    }, [search, setDepartment, managementDepartmentsData, practiceDepartmentsData, governanceDepartmentsData, selectedTab])

    const getDepartmentData = () => {
        if (selectedTab === "governance") {
            return governanceDepartmentsData;
        }

        if (selectedTab === "management") {
            return managementDepartmentsData;
        }

        return practiceDepartmentsData;
    }

    async function getPageData(){
        try {
            const data = await TeamsService.getTeams()
            setTitle(data?.title)
            setSubTitle(data?.subtitle)
            setBackgroundImage(data?.background)
            setTabLeft(data?.tabLeft)
            setTabCenter(data?.tabCenter)
            setTabRight(data?.tabRight)
            setDescriptionLeft(data?.descriptionLeft)
            setDescriptionCenter(data?.descriptionCenter)
            setDescriptionRight(data?.descriptionRight)
        } catch (error) {
            console.log("error", error)
        }
    }


    return (
        <PageContainer className={styles.container}>
            <BackgroundBanner className={styles.banner} background={backgroundImage} loading={loading} />
            <Intro title={title} subTitle={subTitle}/>

            <TwoTabs
                topTab
                marginTop={213}
                Tab1Label={tabLeft!}
                Tab1IsSelected={loading === false && selectedTab === "practice"}
                Tab1OnClick={() => { setSelectedTab("practice"); }}
                Tab2Label={tabCenter!}
                Tab2IsSelected={loading === false && selectedTab === "management"}
                Tab2OnClick={() => { setSelectedTab("management");}}
                Tab3Label={tabRight}
                Tab3IsSelected={loading === false && selectedTab === "governance" }
                Tab3OnClick={() => { setSelectedTab("governance");}}
            />

            <TeamsDescription
                textCol1={departmentsLoading === false && selectedTab === "practice" ? descriptionLeft : ""}
                textCol2={departmentsLoading === false && selectedTab === "management" ? descriptionCenter : ""}
                textCol3={departmentsLoading === false && selectedTab === "governance" ? descriptionRight : ""}
                id="managementAreaDescription"
                consistentStyle
            />

            {departmentsLoading === false && 
                <DepartmentsList 
                    loading={loading} 
                    departmentsData={getDepartmentData()} 
                    departmentSelected={department} 
                    setDepartment={setDepartment} 
                />
            }  

            <SectionTitle marginTop={100}>{department}</SectionTitle>

            <TwoTabs
                marginTop={62}
                Tab1Label={i18n.t("teams.section.selectedDepartment.descriptionTitle")}
                Tab1IsSelected={selectedDepartmentDetailsTab}
                Tab1OnClick={() => setSelectedDepartmentDetailsTab(true)}
                Tab2Label={i18n.t("teams.section.selectedDepartment.peopleTitle")}
                Tab2IsSelected={!selectedDepartmentDetailsTab}
                Tab2OnClick={() => setSelectedDepartmentDetailsTab(false)}
            />

            {selectedDepartmentDetailsTab ?
                <TwoTextColumns 
                    textCol1={description} 
                    textCol2={details} 
                    id="selectedDepartmentDescription" 
                />
                :
                loading ?
                    <Row justify="center">
                        <Spinner className={styles.spinner} />
                    </Row>
                    :
                    <UserList users={data} loading={loading} />
            }
        </PageContainer>
    )
}

import { Schemas } from "../core/Schemas";
import { firestore as FirestoreType } from "firebase";
import { NotificationAction, NOTIFICATION_KIND } from "../model/notification/NotificationAction";
import { firestore } from "./persistence";

const NotificationsService = {
    getSubscriptions: async function (userId: string): Promise<string[]> {
        const subscriptionsSnapshot = await firestore.collection(Schemas.USERS.collection).doc(userId).collection(Schemas.USER_SUBSCRIPTIONS.collectionId).get();

        return subscriptionsSnapshot.docs
            .map(snap => snap.data())
            .filter(subs => subs.tagRef !== undefined)
            .map(subs => subs.tagRef.id)
    },

    subscribeAction: async function (userId: string, tagId: string, action: NotificationAction) {
        let unsubscribeSnapshot: () => void | undefined;
        let timeoutId: NodeJS.Timeout | undefined;
        return new Promise<void>(async (resolve, reject) => {
            let requestRef: FirestoreType.DocumentReference;
            const request = {
                action: action,
                kind: NOTIFICATION_KIND,
                creationDate: new Date(),
                status: "pending",
                createdByRef: firestore.collection(Schemas.USERS.collection).doc(userId),
                data: {
                    userRef: firestore.collection(Schemas.USERS.collection).doc(userId),
                    tagRef: firestore.collection(Schemas.TAGS.collection).doc(tagId)
                }
            }

            try {
                requestRef = await firestore.collection(Schemas.REQUEST.collection).add(request);
            } catch (err) {
                console.error(err);
                return reject({ status: "error.unknown-error" });
            }

            // Listen to status change
            unsubscribeSnapshot = requestRef.onSnapshot(
                snap => {
                    const requestData: any = snap.data();

                    switch (requestData.status) {
                        case "success":
                            return resolve();
                        case "failure":
                            return reject({ status: requestData.errorCode || "error.unknown-error", message: requestData.errorMessage });
                    }
                },
                err => {
                    console.error(err);
                    return reject({ status: "error.unknown-error" });
                }
            );

            // Set a timeout in case no state changes happen
            timeoutId = setTimeout(
                reject => {
                    reject({ status: "error.timeout" });
                },
                15000,
                reject
            );
        }).finally(() => {
            // Clear listener and timeout on promise reject/resolve
            if (unsubscribeSnapshot) unsubscribeSnapshot();
            if (timeoutId) clearTimeout(timeoutId);
        });
    },

    updateNotifications: async function (userId: string, cb: (queryDocumentSnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void) {
        firestore.collection(`users/${userId}/notifications`)
            .orderBy("postPublishDate", "desc").limit(10).onSnapshot(cb)
    }
}

export default NotificationsService

import { analytics } from './persistence';

const AnalyticsService = {
    postEvent: "post_page_view",
    broadcastEvent: "broadcast_page_view",
    agendaEvent: "agenda_modal_view",
    templateEvent: "template_page_view",

    /**
     * @param event The name of the event to use
     * @param data The data to include
     */
    event: function (event: string, data?: object) {
        analytics.logEvent(event, data);
    },
    setCurrentScreen: function(path: string) {
        analytics.setCurrentScreen(path);
    }
}

export default AnalyticsService;
import { Skeleton } from 'antd';
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router';
import PageContainer from '../../components/base/pageContainer';
import ButtonWithLinks from '../../components/sections/template/links/button';
import Intro from '../../components/sections/template/intro';
import MenuWithLinks from '../../components/sections/template/links/menu';
import Title from '../../components/sections/template/typography/title';
import Subtitle from '../../components/sections/template/typography/subtitle';
import Paragraph from '../../components/sections/template/typography/paragraph';
import Quote from '../../components/sections/template/typography/quote';
import Highlight from '../../components/sections/template/typography/highlight';
import Text from '../../components/sections/template/typography/text';
import Image from '../../components/sections/template/structure/image';
import TextText from '../../components/sections/template/structure/textText';
import ImageText from '../../components/sections/template/structure/imageText';
import TextImage from '../../components/sections/template/structure/textImage';
import ImageImage from '../../components/sections/template/structure/imageImage';
import Separator from '../../components/sections/template/structure/separator';
import Spacing from '../../components/sections/template/structure/spacing';
import { Page } from '../../model/page/Page';
import pageService from '../../services/pageService';
import styles from './index.module.css';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import PostsGrid from '../../components/sections/template/grid/postsGrid';
import AnalyticsService from '../../services/analytics';
import VideoSection from '../../components/sections/template/video';

export default function PageTemplate() {
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<Page | undefined>(undefined);
    const { link }: any = useParams();
    const history = useHistory();
    useDocumentTitle(page?.title)

    const getPage = useCallback(async (link: string) => {
        pageService.get(link)
            .then((page: Page) => {
                if (page.status === "draft") {
                    history.push("/404");
                }

                AnalyticsService.event(AnalyticsService.templateEvent, { link: page.link });
                setPage(page);
                setLoading(false);
            }).catch((error) => {
                if (error.message === "Link não encontrado") {
                    history.push("/404");
                }
            });
    }, [history])

    useEffect(() => {
        getPage(link)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (page?.link !== link && page?.link !== undefined) {
            setLoading(true);
            getPage(link)
        }
    }, [page, link, getPage])

    if (loading === true) {
        return (
            <PageContainer className={styles.container}>
                <Skeleton />
            </PageContainer>
        );
    }

    const getSections = (sections?: Array<any>) => {
        if (sections === undefined || sections.length === 0) {
            return undefined;
        }

        return sections.map((section: any) => {
            // TYPOGRAPHY
            if (section.type === "typography_title") {
                return <Title data={section.title} />
            }

            if (section.type === "typography_subtitle") {
                return <Subtitle data={section.subtitle} />
            }

            if (section.type === "typography_paragraph") {
                return <Paragraph data={section.paragraph} />
            }

            if (section.type === "typography_quote") {
                return <Quote data={section.quote} />
            }

            if (section.type === "typography_highlight") {
                return <Highlight data={section.highlight} />
            }

            if (section.type === "typography_free_text") {
                return <Text data={section.text} />
            }

            // STRUCTURE
            if (section.type === "structure_image") {
                return <Image data={section.image} />
            }

            if (section.type === "structure_text_text") {
                return <TextText data={section.textText} />
            }

            if (section.type === "structure_image_text") {
                return <ImageText data={section.imageText} />
            }

            if (section.type === "structure_text_image") {
                return <TextImage data={section.textImage} />
            }

            if (section.type === "structure_image_image") {
                return <ImageImage data={section.imageImage} />
            }

            if (section.type === "structure_separator") {
                return <Separator data={section.separator} />
            }

            if (section.type === "structure_spacing") {
                return <Spacing data={section.spacing} />
            }

            // LINKS
            if (section.type === "menu_with_link") {
                return <MenuWithLinks data={section.menu} />
            }

            if (section.type === "button_with_link") {
                return <ButtonWithLinks data={section.button} />
            }

            // HTML
            if (section.type === "html") {
                return <Text data={section.html} />
            }

            // GRID
            if (section.type === "grid_4x4") {
                return <PostsGrid data={section} />
            }

            if (section.type === "video") {
                return <VideoSection data={section} />
            }

            return undefined;
        });
    }

    return (
        <PageContainer className={styles.templateContainer}>
            <Intro title={page!.title} subtitle={page?.subtitle} backgroundImage={page?.headerBackgroundImage} textColor={page?.headerTextColor} />
            <div className={styles.contentContainer}>
                {getSections(page?.sections)}
            </div>
        </PageContainer>
    );
}
import React from "react";
import { PageTitleProps } from "../pageTitle";
import styles from './index.module.css';

const CategoryTitle: React.FC<PageTitleProps> = (props: PageTitleProps) => {
    return (
        <div className={[styles.title, props.className].join(" ")}>
            {props.children}
        </div>
    );
}

export default CategoryTitle;
import React from 'react';
import { ReactComponent as Tick } from '../../../../assets/images/svg/tick.svg';
import styles from './index.module.css';

export interface IconProps {
    onClick?: () => void;
    className?: string;
}

function TickIcon({ onClick, className }: IconProps) {
    return (
        <Tick onClick={onClick} className={[styles.tagIcon, className].join(' ')} />
    )
}

export default TickIcon;

import { useEffect } from "react"

export function useDocumentTitle(title?: string) {
    useEffect(() => {

        if (!title) {
            document.title = "InTouch PLMJ"
            return
        }

        const doc = new DOMParser().parseFromString(title, 'text/html');
        document.title = `InTouch PLMJ | ${doc.body.textContent}`

    }, [title])
}
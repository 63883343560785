import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/base/pageContainer'
import Intro from '../../components/sections/plus-plmj/intro'
import CardsList from '../../components/sections/plus-plmj/cardList'
import AuthenticationContext from '../../components/auth/authenticationContext'
import BackgroundBanner from '../../components/sections/plus-plmj/backgroundBanner'
import Modal from '../../components/base/modal'
import styles from './index.module.css';
import ModalBody from '../../components/sections/plus-plmj/modalBody'
import ModalFooter from '../../components/sections/plus-plmj/modalFooter'
import FiltersMobile from '../../components/sections/plus-plmj/filtersMobile'
import FilterIcon from '../../components/base/icon/filter'
import PartnershipCategoryService from '../../services/partnershipCategoryService'
import { useInitialState } from '../../hooks/useInitialState'
import { usePartnerships } from '../../hooks/usePartnerships'
import { useLocation } from 'react-router'
import { useQueryString } from '../../hooks/useQueryString'
import Spinner from '../../components/base/spinner'
import { useWindowSize } from '../../hooks/useWindowSize'
import PLUSPLMJService from '../../services/plmjplusService'

export default function PlusPlmj() {
    const { search } = useLocation()
    const initialQueryString = new URLSearchParams(search);
    const initialCategories = initialQueryString.get('categorias') ? initialQueryString.get('categorias')!.split(',') : undefined
    const [data, setData] = useState<any>(null);
    const [partnershipCategories, setParnershipCategories] = useState<any[]>([]);
    const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
    const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const [checkedList, setCheckedList] = useState<string[]>(initialCategories || []);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [orderValue, setOrderValue] = useState<number>(1);
    const [sliderValue, setSliderValue] = useState<[number, number]>([0, 100]);
    const [title, setTitle] = useState<string|undefined>(undefined)
    const [subTitle, setSubTitle] = useState<string|undefined>(undefined)
    const [backgroundImage, setBackgroundImage] = useState<string|undefined>(undefined)
    const initialState = useInitialState({ checkAll, checkedList, orderValue, sliderValue });
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const partnerships = usePartnerships(initialQueryString.get('texto'), initialCategories);
    const { isTablet } = useWindowSize();
    const [loading, setLoading] = useState<boolean>(false);

    useQueryString(
        { name: "texto", query: partnerships.query },
        { name: "categorias", query: partnerships.categories ? partnerships.categories.join(',') : undefined }
    );

    useEffect(() => {
        setLoading(true)
        getPageData()
        PartnershipCategoryService.getAll()
            .then((response: any) => {
                setParnershipCategories(response);
                setCategoriesLoading(false)
                setLoading(false)
            }).catch(err => {
                setParnershipCategories([]);
                setCategoriesLoading(false)
                setLoading(false)
            });
    }, []);

    async function getPageData(){
        try {
            const data = await PLUSPLMJService.getPLUSPLMJ()
            setTitle(data?.title)
            setSubTitle(data?.subtitle)
            setBackgroundImage(data?.background)
        } catch (error) {
            console.log("error", error)
        }
    }

    function openModal(data: { description: string, discount: number, location: string, link: string, title: string, image: string, instructions: string, email: string, email_body: string, categories: [any] }) {
        setData(data);
        setModalIsVisible(true);
    }

    function closeModal() {
        setModalIsVisible(false);
    }

    function openFilters() {
        if (!partnerships.loading) {
            setMobileFilterOpen(true)
        }
    }

    function onCheckboxChange(list: any[]) {
        setCheckedList(list);
        setCheckAll(list.length === partnershipCategories.length);
        setIndeterminate(!!list.length && list.length < partnershipCategories.length);
    }

    function onCheckAllChange(e: any) {
        if (e.target.checked) {
            setCheckedList(partnershipCategories.map(o => o.value));
        } else {
            setCheckedList([]);
        }
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    function clearAll() {
        setCheckedList([]);
        setCheckAll(false);
        setIndeterminate(false);
    }

    function showActiveFiltersCircle(): boolean {
        if (checkedList.length || sliderValue[0] !== 0 || sliderValue[1] !== 100 || orderValue !== 1) {
            return true;
        } else {
            return false;
        }
    }

    function renderContent() {

        if (categoriesLoading) {
            return (<Spinner />)
        }

        return (
            <>
                <CardsList
                    setModalIsOpen={openModal}
                    checkAll={checkAll}
                    checkedList={checkedList}
                    onCheckAllChange={onCheckAllChange}
                    onCheckboxChange={onCheckboxChange}
                    categories={partnershipCategories}
                    clearAll={clearAll}
                    setCheckedAll={setCheckAll}
                    setCheckedList={setCheckedList}
                    initialState={initialState}
                    partnerships={partnerships}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                />
                {
                    data !== null &&
                    (<Modal
                        title={data.categories[0] ? data.categories[0].name : " "}
                        visible={modalIsVisible}
                        className={[styles.cardModal, data.instructions ? styles.hasInstructions : ""].join(" ")}
                        onCancel={closeModal}
                        footer={
                            <div style={{ height: "unset", backgroundColor: "white", width: "100%", paddingLeft: "24px", zIndex: 0 }}>
                                <ModalFooter instructions={data.instructions} closeModal={closeModal} email={data.email} email_body={data.email_body} />
                            </div>
                        }
                        wrapClassName={styles.plusWrapper}
                        destroyOnClose={true}
                    >
                        <ModalBody
                            description={data.description}
                            discount={data.discount}
                            location={data.location}
                            link={data.link}
                            title={data.title}
                            cover={data.image}
                            phone={data.phone}
                        />
                    </Modal>)
                }
                <div className={styles.filterIconMobile} onClick={openFilters} style={partnerships.loading ? { backgroundColor: '#525252' } : {}}>
                    {showActiveFiltersCircle() &&
                        <div className={styles.activeFilters} />}
                    <FilterIcon />
                </div>
                <FiltersMobile
                    isOpen={mobileFilterOpen}
                    setIsOpen={setMobileFilterOpen}
                    categories={partnershipCategories}
                    checkedList={checkedList}
                    checkedAll={checkAll}
                    checkedListSetter={setCheckedList}
                    checkedAllSetter={setCheckAll}
                    onCheckboxChange={onCheckboxChange}
                    onCheckAllChange={onCheckAllChange}
                    stateOnMount={initialState}
                    partnerships={partnerships}
                    indeterminate={indeterminate}
                    orderValue={orderValue}
                    setOrderValue={setOrderValue}
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                />
            </>
        )
    }

    return (
        <PageContainer className={styles.container}> 
            { !isTablet && <BackgroundBanner background={backgroundImage} className={styles.banner} loading={loading} /> }
            <Intro title={title} subTitle={subTitle} background={backgroundImage} loading={loading}/>
            {renderContent()}
        </PageContainer>
    )
}

PlusPlmj.contextType = AuthenticationContext
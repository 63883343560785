import { Event } from '../../model/event/event';
import _ from 'underscore';
import moment from 'moment';
import { orderByDate } from './orderByDate';

export function groupByDay(events: Event[]) {
    const group = _.groupBy(events, (event: Event) => {
        return moment(event.startDate).startOf('day').format();
    })

    for (const date in group) {
        group[date] = orderByDate(group[date])
    }

    return group;
}